import { omit } from 'lodash';

import {
  isCombinePipeline,
  isPipelineChildOf,
  isPipelineParentOf,
  isPipelineWithParent,
} from '@/core/pipeline';
import { isCellWithPipeline, isCellWithTitle } from '@/core/cell';

import {
  Exploration,
  DereferencedPipeline,
  DereferencedPipelineOperation,
  Pipeline,
  PipelineOperation,
  FunnelOperation,
  DereferencedFunnelOperation,
  Fields,
  BasePipeline,
  Field,
  JoinPipelineOperation,
} from '../types';
import { getCellByPipelineId, getPipelineById } from '../exploration/utils';
import { getDereferencedStepPipeline, isEmptyStep } from '../edit-funnel/utils';
import { InvalidPipelineReferenceError } from '../utils';

export { generatePipelineId } from '@/core/pipeline';
export * from './format';

export interface FieldGroup {
  name?: string;
  key?: string;
  fields: Fields;
}

export const isField = (fieldOrGroup: Field | FieldGroup): fieldOrGroup is Field =>
  !isFieldGroup(fieldOrGroup) && 'type' in fieldOrGroup;

export const isFieldGroup = (fieldOrGroup: Field | FieldGroup): fieldOrGroup is FieldGroup =>
  'fields' in fieldOrGroup && 'name' in fieldOrGroup;

export const getPipelineId = (pipeline: Pipeline) => {
  if (pipeline.pipelineId === undefined) {
    throw new Error(`Pipeline does not have pipelineId`);
  }
  return pipeline.pipelineId;
};

export const getBaseModelId = (pipeline: Pipeline, exploration: Exploration) => {
  const flattenedPipeline = flattenPipeline(pipeline, exploration);
  return flattenedPipeline.baseModelId;
};

export function flattenPipeline(pipeline: Pipeline, exploration: Exploration): BasePipeline {
  if (isPipelineWithParent(pipeline)) {
    const parentPipeline = getPipelineById(pipeline.parentId, exploration);

    return flattenPipeline(
      {
        pipelineId: pipeline.pipelineId,
        operations: [...parentPipeline.operations, ...pipeline.operations],
        ...(isPipelineWithParent(parentPipeline)
          ? { parentId: parentPipeline.parentId }
          : { baseModelId: parentPipeline.baseModelId }),
      },
      exploration,
    );
  }

  return pipeline;
}

export const ensureOperationOrder = (operations: PipelineOperation[]): PipelineOperation[] => {
  // A hack to avoid "unflattenable pipelines" on a type-level
  const joinPipelineOperationIndex = operations.findIndex(
    (operation) => operation.operation === 'joinPipeline',
  );
  return operations.slice(joinPipelineOperationIndex === -1 ? 0 : joinPipelineOperationIndex);
};

export const canFlattenChildPipelines = (pipeline: Pipeline, exploration: Exploration) =>
  pipeline.operations.length === 0 ||
  !(
    exploration.view.cells.some(
      (cell) =>
        isCellWithPipeline(cell) &&
        isPipelineChildOf(cell.pipeline, pipeline) &&
        isCombinePipeline(cell.pipeline),
    ) ||
    exploration.view.cells.some(
      (cell) =>
        isCellWithPipeline(cell) &&
        cell.pipeline.operations.some(
          (operation) =>
            operation.operation === 'joinPipeline' &&
            isPipelineChildOf(operation.parameters.pipeline, pipeline),
        ),
    )
  );

// Makes sure that pipeline does not reference parentPipeline by replacing references to parentPipeline
// either from parentId or any of pipeline operations.
export const removeReferenceToPipeline = (
  pipeline: Pipeline,
  parentPipeline: Pipeline,
): Pipeline => {
  const operations = pipeline.operations.map((operation) => {
    if (operation.operation !== 'joinPipeline' || parentPipeline.operations.length > 0) {
      return operation;
    }

    return removeJoinPipelineOperationReferenceToPipeline(operation, parentPipeline);
  });

  if (isPipelineChildOf(pipeline, parentPipeline)) {
    return {
      pipelineId: pipeline.pipelineId,
      ...(isPipelineWithParent(parentPipeline)
        ? { parentId: parentPipeline.parentId }
        : { baseModelId: parentPipeline.baseModelId }),
      operations: [...parentPipeline.operations, ...operations],
    };
  }

  return { ...pipeline, operations: ensureOperationOrder(operations) };
};

const removeJoinPipelineOperationReferenceToPipeline = (
  operation: JoinPipelineOperation,
  parentPipeline: Pipeline,
): JoinPipelineOperation => {
  if (!isPipelineChildOf(operation.parameters.pipeline, parentPipeline)) {
    return operation;
  }

  return {
    ...operation,
    parameters: {
      ...operation.parameters,
      pipeline: removeReferenceToPipeline(operation.parameters.pipeline, parentPipeline),
    },
  };
};

/**
 * Flatten nested parents and convert references to nested pipelines
 */
export function dereferencePipeline(
  pipeline: Pipeline,
  exploration: Exploration,
): DereferencedPipeline {
  const flattenedPipeline = flattenPipeline(pipeline, exploration);

  return {
    ...flattenedPipeline,
    operations: dereferenceOperations(flattenedPipeline.operations, exploration),
  };
}

export const dereferenceFunnelOperation = (
  operation: FunnelOperation,
  exploration: Exploration,
): DereferencedFunnelOperation => ({
  ...operation,
  parameters: {
    ...operation.parameters,
    steps: operation.parameters.steps
      .filter((step) => !isEmptyStep(step))
      .map((step) => {
        const pipeline = getDereferencedStepPipeline(step, exploration);
        return {
          sortKey: step.sortKey ?? null,
          fields: step.fields ?? [],
          modelId: pipeline.baseModelId,
          operations: pipeline.operations,
        };
      }),
  },
});

export const dereferenceOperation = (
  operation: PipelineOperation,
  exploration: Exploration,
): DereferencedPipelineOperation => {
  switch (operation.operation) {
    case 'addRelatedColumn': {
      if (operation.parameters.pipelineId === undefined) {
        return operation;
      }
      const cell = getCellByPipelineId(operation.parameters.pipelineId, exploration);
      if (cell === undefined || !('pipeline' in cell)) {
        throw new InvalidPipelineReferenceError(
          `Could not find pipeline with id ${operation.parameters.pipelineId}`,
        );
      }
      return {
        ...operation,
        parameters: {
          ...omit(operation.parameters, ['pipelineId']),
          pipeline: dereferencePipeline(cell.pipeline, exploration),
        },
      } as DereferencedPipelineOperation;
    }
    case 'relationAggregate': {
      if (operation.parameters.pipelineId !== undefined) {
        const { pipelineId, ...restParameters } = operation.parameters;
        return {
          ...operation,
          parameters: {
            ...restParameters,
            pipeline: dereferencePipeline(getPipelineById(pipelineId, exploration), exploration),
          },
        };
      }

      if ('joinStrategy' in operation.parameters) {
        const { pipelineId, ...restParameters } = operation.parameters;
        return {
          ...operation,
          parameters: {
            ...restParameters,
            pipeline: dereferencePipeline(getPipelineById(pipelineId, exploration), exploration),
          },
        };
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { pipelineId, ...restParameters } = operation.parameters;
      return {
        ...operation,
        parameters: {
          ...restParameters,
        },
      };
    }
    case 'funnel':
      return dereferenceFunnelOperation(operation, exploration);
    case 'cohort':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          pipeline: dereferencePipeline(operation.parameters.pipeline, exploration),
        },
      };
    case 'joinPipeline':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          pipeline: dereferencePipeline(operation.parameters.pipeline, exploration),
        },
      };
    default:
      return operation;
  }
};

export const dereferenceOperations = (
  operations: PipelineOperation[],
  exploration: Exploration,
): DereferencedPipelineOperation[] => {
  return operations.map((operation) => dereferenceOperation(operation, exploration));
};

export const getParentPipelineCell = (exploration: Exploration, pipeline: Pipeline) => {
  if (!isPipelineWithParent(pipeline)) {
    return null;
  }

  return (
    exploration.view.cells.find(
      (cell) => isCellWithPipeline(cell) && isPipelineParentOf(cell.pipeline, pipeline),
    ) ?? null
  );
};

export const getParentPipelineTitle = (exploration: Exploration, pipeline: Pipeline) => {
  if (!isPipelineWithParent(pipeline)) {
    return null;
  }

  const cell = getParentPipelineCell(exploration, pipeline);
  return cell && isCellWithTitle(cell) ? (cell.title ?? null) : null;
};

const isAnyPipelineOperationChildOf = (pipeline: Pipeline, parentPipeline: Pipeline) =>
  pipeline.operations.some((operation) => isOperationPipelineChildOf(operation, parentPipeline));

const isOperationPipelineChildOf = (operation: PipelineOperation, parentPipeline: Pipeline) =>
  isJoinPipelineChildOf(operation, parentPipeline) ||
  isRelationAggregateChildOf(operation, parentPipeline) ||
  isAddRelatedColumnChildOf(operation, parentPipeline) ||
  isFunnelChildOf(operation, parentPipeline) ||
  isCohortChildOf(operation, parentPipeline);

const isJoinPipelineChildOf = (operation: PipelineOperation, pipeline: Pipeline) =>
  operation.operation === 'joinPipeline' &&
  isPipelineChildOf(operation.parameters.pipeline, pipeline);

const isRelationAggregateChildOf = (operation: PipelineOperation, parentPipeline: Pipeline) =>
  operation.operation === 'relationAggregate' &&
  operation.parameters.pipelineId === parentPipeline.pipelineId;

const isAddRelatedColumnChildOf = (operation: PipelineOperation, parentPipeline: Pipeline) =>
  operation.operation === 'addRelatedColumn' &&
  operation.parameters.pipelineId === parentPipeline.pipelineId;

const isFunnelChildOf = (operation: PipelineOperation, parentPipeline: Pipeline) =>
  operation.operation === 'funnel' &&
  operation.parameters.steps.some((step) => isPipelineChildOf(step.pipeline, parentPipeline));

const isCohortChildOf = (operation: PipelineOperation, parentPipeline: Pipeline) =>
  operation.operation === 'cohort' &&
  isPipelineChildOf(operation.parameters.pipeline, parentPipeline);

export const getChildPipelineCells = (exploration: Exploration, pipeline: Pipeline) =>
  exploration.view.cells.filter(
    (cell) =>
      isCellWithPipeline(cell) &&
      (isPipelineChildOf(cell.pipeline, pipeline) ||
        isAnyPipelineOperationChildOf(cell.pipeline, pipeline)),
  );

export const getNumberOfChildPipelines = (exploration: Exploration, pipeline: Pipeline): number =>
  getChildPipelineCells(exploration, pipeline).length;

export const countOperations = (exploration: Exploration) =>
  exploration.view.cells.reduce((count, cell) => {
    if (isCellWithPipeline(cell)) {
      return count + cell.pipeline.operations.length;
    }
    return count;
  }, 0);

const generateUniqueFieldName = (name: string, fields: Fields, suffix: string, i = 0): string => {
  const newName = `${name} (${suffix})${i > 0 ? ` (${i})` : ''}`;
  if (fields.some((f) => f.name === newName)) {
    return generateUniqueFieldName(name, fields, suffix, i + 1);
  }
  return newName;
};

export const ensureUniqueFieldNames = (
  constantFields: Fields,
  renamableFields: Fields,
  suffix: string,
): Fields => {
  const allFields = constantFields.concat(renamableFields);
  const collisionIndex = renamableFields.findIndex((field, i) => {
    return (
      constantFields.some((f) => f.name.toLocaleLowerCase() === field.name.toLocaleLowerCase()) ||
      renamableFields.some(
        (f, j) => j < i && f.name.toLocaleLowerCase() === field.name.toLocaleLowerCase(),
      )
    );
  });
  if (collisionIndex === -1) {
    return renamableFields;
  }
  const field = renamableFields[collisionIndex];
  const name = generateUniqueFieldName(field.name, allFields, suffix);
  return ensureUniqueFieldNames(
    constantFields,
    [
      ...renamableFields.slice(0, collisionIndex),
      { ...field, name },
      ...renamableFields.slice(collisionIndex + 1),
    ],
    suffix,
  );
};
