export const PauseIcon = ({
  size = 16,
  color = 'currentColor',
  ...rest
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}>
    <rect x="5" y="3" width="2" height="10" rx="1" fill={color} />
    <rect x="10" y="3" width="2" height="10" rx="1" fill={color} />
  </svg>
);
