import { useRef } from 'react';

import { useKeyPress } from '@/lib/hooks/use-key-press';

interface FormProps extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  children: React.ReactNode;
  onSubmit(event?: React.FormEvent): void;
  disabled?: boolean;
}

export const Form = (props: FormProps) => {
  const { children, onSubmit, disabled = false, ...attributes } = props;
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (formRef.current?.checkValidity() === true && !disabled) {
      onSubmit(event);
    } else {
      formRef.current?.reportValidity();
    }
  };

  useKeyPress(['Enter', 'meta', 'ctrl'], () => handleSubmit(), { includeInputs: true });

  return (
    <form ref={formRef} onSubmit={handleSubmit} {...attributes}>
      {children}
    </form>
  );
};
