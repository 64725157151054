import { Fragment, MouseEventHandler, ReactNode, useRef } from 'react';
import { Text } from '@visx/text';
import { GridLines } from '@visx/grid/lib/types';

import { common, model } from '@gosupersimple/types';

import { getFormattedDateForPeriod } from '@/lib/date';
import { isNil } from '@/lib/utils';
import { TooltipBase } from '@/components/tooltip';
import { formatPropertyValue } from '@/explore/utils/format';

import { TimeAggregationPeriod } from '../../types';

import styles, { lineColor } from './charts.module.scss';

export type BarTooltipData = {
  label: string | null;
  value: number | null;
  color?: string;
  type: model.PropertyType | null;
  format?: common.PropertyValueFormat;
};

type TimeTooltipData = {
  label: Date;
  timezone: string;
  value: number | null;
  aggPeriod: TimeAggregationPeriod;
  type: model.PropertyType | null;
  format?: common.PropertyValueFormat;
};
export type TooltipData = BarTooltipData | TimeTooltipData;

const isTimeTooltip = (tooltipData: TooltipData): tooltipData is TimeTooltipData =>
  tooltipData.label instanceof Date;

interface LabelProps {
  x: number;
  textAnchor?: 'middle';
  verticalAnchor?: 'middle';
  y: number;
  hideTooltip: () => void;
  label: string;
  value: number | null;
  width?: number;
  onMouseOver?: MouseEventHandler;
  onClick?: MouseEventHandler;
}

export const Label = ({
  x,
  y,
  textAnchor,
  verticalAnchor,
  hideTooltip,
  label,
  width = 100,
  onMouseOver,
  onClick,
}: LabelProps) => (
  <Text
    verticalAnchor={verticalAnchor}
    x={x}
    textAnchor={textAnchor}
    y={y}
    className={styles.label}
    width={width}
    onMouseMove={onMouseOver}
    onMouseOut={hideTooltip}
    onClick={onClick}
    scaleToFit="shrink-only"
    cursor={onClick ? 'pointer' : 'default'}>
    {label ?? '-'}
  </Text>
);

interface ChartTooltipProps {
  top: number;
  left: number;
  offsetX?: number;
  offsetY?: number;
  title?: React.ReactNode;
  children?: ReactNode;
}

/**
 * Makes sure that the tooltip is always visible in parents bounding box.
 * NB! The parent element should have position: relative.
 */
export const ChartTooltip = ({
  top,
  left,
  offsetX = 10,
  offsetY = 0,
  title,
  children,
}: ChartTooltipProps) => {
  const ref = useRef<HTMLDivElement>(null);

  function calculateStyles() {
    if (ref.current === null || ref.current.parentElement === null) {
      return { opacity: 0, top: 0, left: 0 };
    }

    const width = ref.current.clientWidth;
    const height = ref.current.clientHeight;
    const parentWidth = ref.current.parentElement.clientWidth;
    const parentHeight = ref.current.parentElement.clientHeight;

    const measuredLeft =
      left + width + offsetX > parentWidth ? left - width - offsetX : left + offsetX;
    const adjustedLeft = measuredLeft < offsetX ? offsetX : measuredLeft;

    const measuredTop =
      top + height + offsetY > parentHeight ? parentHeight - height - offsetY : top + offsetY;

    return { top: measuredTop, left: adjustedLeft };
  }

  return (
    <div className={styles.tooltip} style={calculateStyles()} ref={ref}>
      <TooltipBase title={title}>{children}</TooltipBase>
    </div>
  );
};

interface ChartTooltipSingleValueProps {
  tooltipOpen: boolean;
  tooltipTop: number | undefined;
  tooltipLeft: number | undefined;
  tooltipData: TooltipData | undefined;
  categoryLabel: string;
  valueLabel: string;
  seriesColor?: string;
}

export const ChartTooltipSingleValue = ({
  tooltipOpen,
  tooltipTop = 0,
  tooltipLeft = 0,
  tooltipData,
  valueLabel,
  categoryLabel,
  seriesColor,
}: ChartTooltipSingleValueProps) =>
  tooltipOpen && tooltipData ? (
    <ChartTooltip
      top={tooltipTop}
      left={tooltipLeft}
      title={
        isTimeTooltip(tooltipData)
          ? getFormattedDateForPeriod(
              tooltipData.label,
              tooltipData.aggPeriod,
              tooltipData.timezone,
            )
          : categoryLabel +
            ': ' +
            (isNil(tooltipData.label) || tooltipData.label === '' ? '-' : tooltipData.label)
      }>
      <ul>
        <li>
          <span className={styles.seriesMarker} style={{ background: seriesColor ?? lineColor }} />
          {valueLabel}:{' '}
          <strong>
            {formatPropertyValue(tooltipData.value, {
              type: tooltipData.type,
              format: tooltipData.format,
            })}
          </strong>
        </li>
      </ul>
    </ChartTooltip>
  ) : null;

export const renderGridColumns = (
  { lines }: { lines: GridLines },
  options: { width: number; strokeColor: string },
) =>
  lines.map((line, i) =>
    line.from.x === 0 || line.from.x === options.width ? (
      <Fragment key={i} />
    ) : (
      <line
        key={i}
        x1={line.from.x}
        y1={line.from.y}
        x2={line.to.x}
        y2={line.to.y}
        fill="transparent"
        stroke={options.strokeColor}
        strokeWidth="1"
      />
    ),
  );

export type HighlightedData = {
  date: Date;
  group: string | undefined;
  axis: 'left' | 'right' | undefined;
  point: {
    x: number;
    y: number;
  };
};
