import * as dateFns from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { model } from '@gosupersimple/types';

import { createBasePipeline, createPipeline } from '@/core/pipeline';
import { generateCellId } from '@/core/cell';

import { getPipelineById, importExplorationCellsAfter } from '../exploration/utils';
import {
  Aggregation,
  Cell,
  DereferencedPipeline,
  DereferencedPipelineOperation,
  Exploration,
  FilterOperation,
  GroupAggregateOperation,
  Pipeline,
  PipelineOperation,
  RecordsCell,
  RelationAggregateOperation,
  TimeAggregationPeriod,
} from '../types';
import { flattenPipeline } from '../pipeline/utils';

type Property = {
  key: string;
  pk?: boolean;
  type: model.PropertyType | null;
  precision?: TimeAggregationPeriod;
};

type DrilldownAwareProperty<T extends Property> = T & { isAggregated: boolean };

const extractAggregationKeys = (aggregations: Aggregation[]) =>
  aggregations
    .filter((aggregation) => aggregation.type !== 'custom')
    .map((aggregation) => aggregation.property.key);

const getAggregatedPropertiesFromOperation = (operation: DereferencedPipelineOperation) =>
  operation.operation === 'groupAggregate' || operation.operation === 'relationAggregate'
    ? extractAggregationKeys(operation.parameters.aggregations)
    : [];

const getAggregatedPropertiesFromPipeline = (pipeline: DereferencedPipeline) => {
  return new Set(pipeline.operations.flatMap(getAggregatedPropertiesFromOperation));
};

export const detectAggregatedProperties = <T extends Property>(
  properties: T[],
  pipeline: DereferencedPipeline,
): DrilldownAwareProperty<T>[] => {
  const aggregatedProperties = getAggregatedPropertiesFromPipeline(pipeline);
  return properties.map((property) => ({
    isAggregated: aggregatedProperties.has(property.key),
    ...property,
  }));
};

export const addDrillDownCellToExploration = (input: {
  record: Record<string, unknown>;
  properties: Property[];
  property: Property;
  exploration: Exploration;
  cellId: string;
  timezone: string;
}): { exploration: Exploration; cell?: Cell } => {
  const { record, properties, property, exploration, cellId, timezone } = input;
  const cell = exploration.view.cells.find((cell) => cell.id === cellId);
  if (cell === undefined || cell.kind !== 'records') {
    return { exploration };
  }

  const operation = findOperationIntroducingAggregatingProperty(
    flattenPipeline(cell.pipeline, exploration),
    property.key,
  );
  if (operation === undefined) {
    return { exploration };
  }

  const startingPipeline = {
    ...cell.pipeline,
    operations: cell.pipeline.operations.slice(0, cell.pipeline.operations.indexOf(operation)),
  };

  const pipeline = drillDownFromAggregatingOperation(
    operation,
    record,
    properties,
    timezone,
    exploration,
    startingPipeline,
  );

  const drillDownCell = createDrilldownCell(cell.title ?? '', pipeline);

  const result = importExplorationCellsAfter(exploration, [drillDownCell], cellId);
  return { exploration: result.exploration, cell: result.cells.at(0) };
};

/**
 * Given the aggregation field key of a property, figure out which operation in the pipeline introduces the aggregation.
 */
function findOperationIntroducingAggregatingProperty(
  pipeline: Pipeline,
  key: string,
): GroupAggregateOperation | RelationAggregateOperation | undefined {
  const operation = pipeline.operations
    .filter(isAggregatingOperation)
    .findLast((operation) =>
      operation.parameters.aggregations.some((aggregation) => aggregation.property.key === key),
    );

  if (operation === undefined) {
    return;
  }

  return operation;
}

function isAggregatingOperation(
  operation: PipelineOperation,
): operation is GroupAggregateOperation | RelationAggregateOperation {
  return operation.operation === 'groupAggregate' || operation.operation === 'relationAggregate';
}

/**
 * Given aggregating operation, return its "reverse" operations that builds the query to drill down to the origins of
 * that aggregation.
 */
function drillDownFromAggregatingOperation(
  operation: GroupAggregateOperation | RelationAggregateOperation,
  record: Record<string, unknown>,
  properties: Property[],
  timezone: string,
  exploration: Exploration,
  startingPipeline: Pipeline,
): Pipeline {
  if (operation.operation === 'groupAggregate') {
    return drillDownFromGroupAggregate(operation, record, properties, timezone, startingPipeline);
  }

  if (operation.operation === 'relationAggregate') {
    return drillDownFromRelationAggregate(
      operation,
      record,
      properties,
      exploration,
      startingPipeline,
    );
  }

  throw new Error(`Expected groupAggregate or relationAggregate operation`);
}

function filterOperationFromDateRange(
  startDate: Date,
  endDate: Date,
  key: string,
): FilterOperation {
  return {
    operation: 'filter',
    parameters: {
      operator: 'and',
      operands: [
        { key, operator: '>=', value: startDate.toISOString() },
        { key, operator: '<=', value: endDate.toISOString() },
      ],
    },
  };
}

export function dateRangeFromPrecision(date: Date, precision: TimeAggregationPeriod) {
  switch (precision) {
    case 'hour':
      return { startDate: dateFns.startOfHour(date), endDate: dateFns.endOfHour(date) };
    case 'day':
      return { startDate: dateFns.startOfDay(date), endDate: dateFns.endOfDay(date) };
    case 'week':
      return { startDate: dateFns.startOfWeek(date), endDate: dateFns.endOfWeek(date) };
    case 'month':
      return { startDate: dateFns.startOfMonth(date), endDate: dateFns.endOfMonth(date) };
    case 'quarter':
      return { startDate: dateFns.startOfQuarter(date), endDate: dateFns.endOfQuarter(date) };
    case 'year':
      return { startDate: dateFns.startOfYear(date), endDate: dateFns.endOfYear(date) };
    default:
      throw new Error(`Unsupported precision: ${precision}`);
  }
}

function drillDownFromGroupAggregate(
  operation: GroupAggregateOperation,
  record: Record<string, unknown>,
  properties: Property[],
  timezone: string,
  startingPipeline: Pipeline,
): Pipeline {
  const operations: PipelineOperation[] = operation.parameters.groups.map((group) => {
    const value = record[group.key];
    const property = properties.find((p) => p.key === group.key);
    if (property?.type === 'Date' && property.precision !== undefined) {
      const date = new Date(String(value));
      const { startDate, endDate } = dateRangeFromPrecision(date, property.precision);
      return filterOperationFromDateRange(
        fromZonedTime(startDate, timezone),
        fromZonedTime(endDate, timezone),
        group.key,
      );
    }

    if (value === null) {
      return { operation: 'filter', parameters: { key: group.key, operator: 'isnull' } };
    }

    return {
      operation: 'filter',
      parameters: { key: group.key, operator: '==', value: String(value) },
    };
  });

  return { ...startingPipeline, operations: [...startingPipeline.operations, ...operations] };
}

function drillDownFromRelationAggregate(
  operation: RelationAggregateOperation,
  record: Record<string, unknown>,
  properties: Property[],
  exploration: Exploration,
  startingPipeline: Pipeline,
): Pipeline {
  const relatedPipelineId = operation.parameters.pipelineId;
  const relatedPipelineOperations =
    relatedPipelineId !== undefined
      ? getPipelineById(relatedPipelineId, exploration).operations
      : [];

  const filtersFromProperties: PipelineOperation[] = properties
    .filter((p) => p.pk)
    .map(({ key }) => ({
      operation: 'filter',
      parameters: { key, operator: '==', value: record[key] as string },
    }));

  const filtersFromParameters: PipelineOperation[] =
    operation.parameters.filter !== undefined
      ? [{ operation: 'filter', parameters: operation.parameters.filter }]
      : [];

  if ('relation' in operation.parameters) {
    const operations: PipelineOperation[] = [
      ...filtersFromProperties,
      { operation: 'switchToRelation', parameters: { relation: operation.parameters.relation } },
      ...relatedPipelineOperations,
      ...filtersFromParameters,
    ];

    return { ...startingPipeline, operations: [...startingPipeline.operations, ...operations] };
  } else if ('pipelineId' in operation.parameters) {
    const operations: PipelineOperation[] = [...filtersFromParameters, ...filtersFromProperties];

    const parentPipeline = getPipelineById(operation.parameters.pipelineId, exploration);
    if (!('baseModelId' in parentPipeline)) {
      return parentPipeline;
    }

    return createBasePipeline({
      baseModelId: parentPipeline.baseModelId,
      operations: [...parentPipeline.operations, ...operations],
    });
  }

  return startingPipeline;
}

const createDrilldownCell = (title: string, pipeline: Pipeline): RecordsCell => ({
  id: generateCellId(),
  kind: 'records',
  title: 'Drilldown from ' + title,
  pipeline: createPipeline(pipeline),
});
