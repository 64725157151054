import { useState } from 'react';

import { ChatUserMessage } from '@/explore/types';

import { UserMessageInput } from './user-message-input';

import styles from './initial-message.module.scss';
import commonStyles from './chat-cell.module.scss';

interface InitialMessageProps {
  message: ChatUserMessage;
  onChange: (message: ChatUserMessage) => void;
}

export const InitialMessage = (props: InitialMessageProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = (value: string) => {
    props.onChange({ ...props.message, message: value });
    setIsEditing(false);
  };

  const label = props.message.type === 'initial_user_prompt' ? 'Question' : 'Follow-up';

  return (
    <div className={(commonStyles.questionRow, styles.initialMessage)}>
      <div className={styles.label}>{label}:</div>
      <div className={styles.message}>
        {isEditing ? (
          <UserMessageInput
            initialValue={props.message.message}
            placeholder="Edit answer"
            actions={(value) => [
              {
                label: 'Send',
                disabled: value === props.message.message,
                onClick: () => handleSubmit(value),
              },
              {
                label: 'Cancel (Esc)',
                onClick: () => setIsEditing(false),
                variant: 'secondary',
                type: 'cancel',
              },
            ]}
            autoFocus
          />
        ) : (
          <div className={commonStyles.response} onClick={() => setIsEditing(true)}>
            {props.message.message}
          </div>
        )}
      </div>
    </div>
  );
};
