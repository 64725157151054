import { useEffect, useState, forwardRef } from 'react';
import classNames from 'classnames';

import { FormInputSize } from '../form/types';

import styles from './shortcut-indicator.module.scss';

interface ShortcutIndicatorProps {
  shortcut: string[];
  className?: string;
  size?: FormInputSize | 'tiny';
  inline?: boolean;
}

export const ShortcutIndicator = forwardRef<HTMLDivElement, ShortcutIndicatorProps>(
  function ShortcutIndicator(
    { shortcut, className = '', size = 'regular', inline = false }: ShortcutIndicatorProps,
    ref,
  ) {
    const [isMac, setIsMac] = useState(false);

    useEffect(() => {
      const checkIfMac = () => {
        // Not yet supported in all browsers
        if ('userAgentData' in navigator) {
          return (navigator as any).userAgentData.platform.toLowerCase().includes('mac');
        }

        if (navigator.userAgent.toLowerCase().includes('mac')) {
          return true;
        }

        return navigator.platform.toLowerCase().includes('mac');
      };

      setIsMac(checkIfMac());
    }, []);

    // TODO: Use icons instead
    const keyMap: Record<string, string> = {
      cmd: isMac ? '⌘' : 'Ctrl',
      ctrl: 'Ctrl',
      shift: '⇧',
      alt: isMac ? '⌥' : 'Alt',
      enter: '↵',
      backspace: '⌫',
      delete: '⌦',
      escape: 'Esc',
      up: '↑',
      down: '↓',
      left: '←',
      right: '→',
    };

    const formatKey = (key: string) => {
      const lowerKey = key.toLowerCase();
      return keyMap[lowerKey] || key.toUpperCase();
    };

    const isSymbol = (key: string) => {
      const lowerKey = key.toLowerCase();
      const exceptions = ['escape'];

      if (!isMac) {
        exceptions.push('alt', 'cmd');
      }

      return lowerKey in keyMap && !exceptions.includes(lowerKey);
    };

    return (
      <span
        ref={ref}
        className={classNames({
          [styles.wrapper]: true,
          [styles.large]: size === 'large',
          [styles.regular]: size === 'regular',
          [styles.small]: size === 'small',
          [styles.tiny]: size === 'tiny',
          [styles.inline]: inline,
          [className]: true,
        })}>
        {shortcut.map((key, index) => (
          <kbd
            key={index}
            className={classNames({
              [styles.shortcut]: true,
              [styles.symbol]: isSymbol(key),
            })}>
            {formatKey(key)}
          </kbd>
        ))}
      </span>
    );
  },
);
