import { forwardRef, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icon, IconName, IconProps } from '../icon';
import { FormInputSize } from '../form/types';
import { Loader } from '../loader';

import styles from './button.module.scss';

type InlineButtonProps = {
  children: string | React.ReactNode;
  onClick?: MouseEventHandler;
  title?: string;
  style?: React.CSSProperties;
  className?: classNames.Argument;
  disabled?: boolean;
  color?: 'white' | 'green';
  size?: FormInputSize;
  'aria-labelledby'?: string;
};

const getIconSize = (size?: FormInputSize) => (size === 'small' ? 12 : size === 'large' ? 24 : 16);

export const InlineButton = forwardRef<HTMLButtonElement, InlineButtonProps>(function InlineButton(
  {
    children,
    onClick,
    className,
    disabled,
    color,
    style,
    title,
    size = 'regular',
    'aria-labelledby': ariaLabelledby,
  },
  ref,
) {
  return (
    <button
      ref={ref}
      style={style}
      className={classNames(styles.inlineTextBtn, className, {
        [styles.white]: color === 'white',
        [styles.green]: color === 'green',
        [styles.sizeSmall]: size === 'small',
        [styles.sizeMedium]: size === 'regular',
      })}
      type="button"
      title={title}
      aria-label={title}
      onClick={onClick}
      disabled={disabled}
      aria-labelledby={ariaLabelledby}>
      {children}
    </button>
  );
});

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  icon?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'outlined' | 'gray';
  color?: 'default' | 'warning';
  size?: FormInputSize | 'compact';
  'aria-labelledby'?: string;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    children,
    icon,
    className,
    disabled = false,
    loading = false,
    variant = 'primary',
    size = 'regular',
    color = 'default',
    'aria-labelledby': ariaLabelledby,
    ...rest
  },
  ref,
) {
  return (
    <button
      ref={ref}
      {...rest}
      className={classNames(styles.button, className, {
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.outlined]: variant === 'outlined',
        [styles.styleGray]: variant === 'gray',
        [styles.sizeSmall]: size === 'small',
        [styles.sizeCompact]: size === 'compact',
        [styles.sizeMedium]: size === 'regular',
        [styles.sizeLarge]: size === 'large',
        [styles.warning]: color === 'warning',
        [styles.loading]: loading,
      })}
      disabled={disabled || loading}
      aria-label={rest.title}
      aria-labelledby={ariaLabelledby}>
      {icon}
      <span>{children}</span>
      {loading && <Loader size="small" className={styles.loader} />}
    </button>
  );
});

type IconButtonProps = {
  icon: IconProps['name'];
  title?: string;
  iconSize?: FormInputSize;
  type?: 'regular' | 'outlined' | 'gray';
  onClick?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  disabled?: boolean;
  className?: classNames.Argument;
  style?: React.CSSProperties;
  size?: FormInputSize;
  tabIndex?: number;
  'aria-labelledby'?: string;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  {
    icon,
    size = 'small',
    iconSize,
    type,
    onClick,
    onMouseLeave,
    disabled,
    className,
    style,
    title,
    tabIndex,
    'aria-labelledby': ariaLabelledby,
  },
  ref,
) {
  return (
    <button
      ref={ref}
      style={style}
      className={classNames(styles.iconButton, className, {
        [styles.styleOutlined]: type === 'outlined',
        [styles.styleGray]: type === 'gray',
        [styles.sizeSmall]: size === 'small',
        [styles.sizeMedium]: size === 'regular',
        [styles.sizeLarge]: size === 'large',
      })}
      type="button"
      title={title}
      aria-label={title}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      tabIndex={tabIndex}
      aria-labelledby={ariaLabelledby}>
      <Icon name={icon} size={getIconSize(iconSize)} />
    </button>
  );
});

type CloseButtonProps = {
  iconSize?: FormInputSize;
  className?: classNames.Argument;
  onClick?: MouseEventHandler;
  tabIndex?: number;
  'aria-labelledby'?: string;
};

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(function CloseButton(
  { iconSize, className, onClick, tabIndex, 'aria-labelledby': ariaLabelledby },
  ref,
) {
  return (
    <IconButton
      ref={ref}
      icon="X"
      title="Close"
      aria-label="Close"
      iconSize={iconSize}
      className={className}
      onClick={onClick}
      tabIndex={tabIndex}
      aria-labelledby={ariaLabelledby}
    />
  );
});

type ScrollToButtonProps = {
  label: string;
  direction: 'up' | 'down';
  className?: classNames.Argument;
  onClick: MouseEventHandler;
};

export const ScrollToButton = forwardRef<HTMLButtonElement, ScrollToButtonProps>(
  function ScrollToButton({ label, direction, className, onClick }, ref) {
    return (
      <button
        ref={ref}
        className={classNames(styles.scrollToButton, className)}
        type="button"
        onClick={onClick}>
        <Icon name={direction === 'up' ? 'ArrowUp' : 'ArrowDown'} size={16} />
        {label}
      </button>
    );
  },
);

interface DropDownButtonProps {
  children: string;
  icon?: IconName;
  size?: FormInputSize;
  iconSize?: number;
  className?: classNames.Argument;
  'aria-labelledby'?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const DropDownButton = forwardRef<HTMLButtonElement, DropDownButtonProps>(
  function DropDownButton(
    { size = 'small', onClick, icon, children, className, 'aria-labelledby': ariaLabelledby },
    ref,
  ) {
    const iconSize = getIconSize(size);
    const chevron = <Icon name="ChevronDownThin" size={size === 'small' ? 14 : 16} />;

    return (
      <button
        ref={ref}
        className={classNames(styles.dropDownButton, className, {
          [styles.sizeSmall]: size === 'small',
          [styles.sizeMedium]: size === 'regular',
          [styles.sizeLarge]: size === 'large',
        })}
        onClick={onClick}
        aria-labelledby={ariaLabelledby}>
        {icon !== undefined ? <Icon name={icon} size={iconSize} /> : ''}
        <span>{children}</span>
        {chevron}
      </button>
    );
  },
);

type ButtonGroupProps = {
  options: { label: string; value: string }[];
  value: string;
  size: FormInputSize;
  onChange: (value: string) => void;
  'aria-labelledby'?: string;
};

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(function ButtonGroup(
  { options, value, size = 'regular', onChange, 'aria-labelledby': ariaLabelledby },
  ref,
) {
  return (
    <div ref={ref} className={styles.buttonGroup}>
      {options.map((option) => (
        <button
          type="button"
          key={option.value}
          className={classNames({
            [styles.sizeSmall]: size === 'small',
            [styles.sizeMedium]: size === 'regular',
            [styles.sizeLarge]: size === 'large',
            [styles.active]: option.value === value,
          })}
          onClick={() => onChange(option.value)}
          aria-labelledby={ariaLabelledby}>
          {option.label}
        </button>
      ))}
    </div>
  );
});
