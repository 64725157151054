import { omit } from 'lodash';
import { useState, useMemo } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

import { useTrackEvent } from '@/lib/analytics';
import { useBuildAccountUrl } from '@/lib/accounts/context';
import { isCellWithPipeline } from '@/core/cell';
import { ConfirmationModal } from '@/components/confirmation-modal';
import { HideInEmbedded } from '@/components/layout/hide-in-embedded';
import { useScreenSize, Breakpoint } from '@/lib/hooks';

import { Exploration } from '../types';
import { Button, IconButton } from '../../components/button';
import { Dropdown, DropdownMenuItem } from '../../components/dropdown';
import { Icon } from '../../components/icon';
import { createBackNavigationToast, useToastContext } from '../../components/toast';
import { useExplorationCellContext } from './exploration-cell-context';
import { useExplorationContext } from './exploration-context';
import { isCellFirstInRow, isCellLastInRow } from './exploration-layout/utils';
import { canFlattenChildPipelines } from '../pipeline/utils';
import { buildExplorationUrl } from '../utils';

import styles from './exploration.module.scss';

export type CellOption = DropdownMenuItem & {
  sort: number;
};

interface CellControlsProps {
  exploration: Exploration;
  editButtonVisible?: boolean;
  canDelete?: boolean;
  canDuplicate?: boolean;
  options?: (defaultOptions: CellOption[]) => CellOption[];
  children?: React.ReactNode;
}

export const CellControls = (props: CellControlsProps) => {
  const {
    exploration,
    editButtonVisible = false,
    canDelete = true,
    canDuplicate = true,
    options = (defaultOptions) => defaultOptions,
    children,
  } = props;

  const {
    parameters,
    cellCount,
    isEditorOpen,
    canvasState,
    openEditor,
    closeEditor,
    duplicateCell,
    deleteCell,
    selectCell,
    deselectCell,
    scrollToCell,
    moveCellUp,
    moveCellDown,
  } = useExplorationContext();

  const { cell, cellIndex, isSelectedCell, isConversationCell, copyCell } =
    useExplorationCellContext();

  const buildAccountUrl = useBuildAccountUrl();
  const [, copyToClipboard] = useCopyToClipboard();
  const addToast = useToastContext();
  const trackEvent = useTrackEvent();
  const screenSize = useScreenSize();

  const isDesktopView = useMemo(
    () => screenSize.breakpoint > Breakpoint.md,
    [screenSize.breakpoint],
  );

  const handleDuplicateCell = () => {
    const { cell: duplicatedCell } = duplicateCell(cell.id);

    if (duplicatedCell !== undefined) {
      selectCell(duplicatedCell.id);
      scrollToCell(duplicatedCell.id);
    }

    trackEvent('Exploration Cell Duplicated', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
    });
  };

  const [showDeleteCombineParentModal, setShowDeleteCombineParentModal] = useState(false);

  const handleCopyBlockLink = () => {
    const explorationUrl = buildAccountUrl(
      buildExplorationUrl(exploration, parameters, { cell: cell.id }),
    );

    const url = new URL(explorationUrl, location.origin);
    copyToClipboard(url.toString());

    addToast({
      title: 'Link copied',
      content: () => 'The link to this block has been copied to clipboard.',
      kind: 'success',
    });
  };

  const handleDeleteCell = () => {
    deleteCell(cell.id);
    deselectCell();

    addToast(
      createBackNavigationToast(
        'Block Deleted',
        "If you didn't mean to delete it, you can use your browser back button or",
        'click undo',
      ),
    );

    trackEvent('Exploration Cell Deleted', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
      cellIndex,
    });
  };

  const handleClickDeleteCell = () => {
    if (isCellWithPipeline(cell) && !canFlattenChildPipelines(cell.pipeline, exploration)) {
      setShowDeleteCombineParentModal(true);
      return;
    }
    handleDeleteCell();
  };

  const handleConfirmDeleteCell = async () => {
    handleDeleteCell();
    setShowDeleteCombineParentModal(false);
  };

  const handleMoveUp = () => {
    moveCellUp(cellIndex);
    trackEvent('Exploration Cell Moved Up', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
      cellIndex,
    });
  };

  const handleMoveDown = () => {
    moveCellDown(cellIndex);
    trackEvent('Exploration Cell Moved Down', {
      explorationId: exploration.explorationId,
      name: exploration.name,
      cell,
      cellIndex,
    });
  };

  const isFirst = isCellFirstInRow(exploration.view.cells, cellIndex);
  const isLast = isCellLastInRow(exploration.view.cells, cellIndex);

  const items: DropdownMenuItem[] = options([
    {
      label: 'Duplicate block',
      icon: <Icon name="Duplicate" size={16} />,
      onClick: handleDuplicateCell,
      sort: 10,
      disabled: !canDuplicate,
    },
    {
      label: 'Copy block',
      icon: <Icon name="Clipboard" size={16} />,
      onClick: () => copyCell(),
      sort: 19,
    },
    { type: 'divider', sort: 20 },
    {
      label: 'Copy link to block',
      icon: <Icon name="Link" size={16} />,
      onClick: handleCopyBlockLink,
      sort: 22,
    },
    {
      type: 'divider' as const,
      sort: 90,
    },
    {
      label: 'Delete block',
      icon: <Icon name="Trash2" size={16} />,
      onClick: handleClickDeleteCell,
      sort: 95,
      disabled: !canDelete || cellCount === 1 || isConversationCell,
      tooltip: isConversationCell
        ? 'Cannot delete blocks that are part of a conversation'
        : cellCount === 1
          ? 'Cannot delete the last remaining block'
          : undefined,
    },
    ...(canvasState.isCanvasView || isConversationCell || isDesktopView
      ? []
      : [
          {
            type: 'divider' as const,
            sort: 95,
          },
          {
            label: isFirst ? 'Move up' : 'Move left',
            icon: <Icon name={isFirst ? 'ArrowUp' : 'ArrowLeft'} size={16} />,
            onClick: handleMoveUp,
            sort: 100,
            disabled: cellIndex === 0 && isLast,
          },
          {
            label: isLast ? 'Move down' : 'Move right',
            icon: <Icon name={isLast ? 'ArrowDown' : 'ArrowRight'} size={16} />,
            onClick: handleMoveDown,
            sort: 110,
            disabled: cellIndex === cellCount - 1 && isFirst,
          },
        ]),
  ])
    .sort((a, b) => a.sort - b.sort)
    .map((item) => omit(item, ['sort']) as DropdownMenuItem);

  return (
    <div className={styles.cellControls}>
      {children}
      <HideInEmbedded>
        <Dropdown
          align="right"
          trigger={(isOpen, setIsOpen) => (
            <IconButton
              icon="MoreHorizontal"
              size="small"
              title="More..."
              type="gray"
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
          items={items}
        />
      </HideInEmbedded>
      {editButtonVisible &&
        (isEditorOpen && isSelectedCell ? (
          <Button variant="secondary" size="small" onClick={closeEditor}>
            Done
          </Button>
        ) : (
          <Button size="small" onClick={() => openEditor(cell.id)}>
            Edit
          </Button>
        ))}
      {showDeleteCombineParentModal && (
        <ConfirmationModal
          title={'Are you sure?'}
          description=<>
            Deleting this block will invalidate a combined block in this exploration.
            <br />
            Are you sure you want to delete it?
          </>
          submitLabel="Delete"
          onSubmit={handleConfirmDeleteCell}
          onClose={() => setShowDeleteCombineParentModal(false)}
        />
      )}
    </div>
  );
};
