import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useTitle } from '@/lib/hooks/use-title';
import { useSignUpMutation } from '@/graphql';
import { useGAPageview } from '@/lib/ga/use-ga';

import { Cards } from '../../components/gradient-layout/cards';
import { GoogleSignupCard } from './google-signup-card';
import { EmailSignupCard } from './email-signup-card';
import { FullPageLoader } from '../../components/loader';
import { useToastContext } from '../../components/toast';
import { useAuth } from '../../auth/auth-context';

import commonStyles from '../public.module.scss';

export const SignupPage = () => {
  useTitle('Sign Up to Supersimple');

  const [signupMutation, { loading: signingUp }] = useSignUpMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const addToast = useToastContext();
  const { logOut, user } = useAuth();
  useGAPageview();

  const signUpHandler = async (email: string) => {
    try {
      await signupMutation({ variables: { email, consent: { subjects: [] } } });

      if (user?.emailVerified === true) {
        return navigate('/');
      } else if (location.pathname !== '/verify-email' && user?.emailVerified === false) {
        navigate('/verify-email');
      }
    } catch (error) {
      if ((error as any).message === 'Signup failed: User is already signed up') {
        return navigate('/');
      }

      await logOut();
      addToast({
        title: 'Signup Error',
        content: () => `Something went wrong. Please contact us if the issue persists.`,
        kind: 'error',
      });
      throw error;
    }
  };

  if (signingUp) {
    return <FullPageLoader />;
  }

  return (
    <div className={commonStyles.layout}>
      <h1>Get Started!</h1>
      <Cards>
        <GoogleSignupCard onStartSignup={signUpHandler} />
        <div className={commonStyles.separator}>or</div>
        <EmailSignupCard onStartSignup={signUpHandler} loading={signingUp} />
      </Cards>
      <p>
        Already have an account? <Link to="/login">Log in</Link>
      </p>
    </div>
  );
};
