import { cloneElement, isValidElement, ReactElement } from 'react';

import { useLayoutContext } from '@/components/layout/layout-context';

type DisableInEmbeddedProps = {
  children: ReactElement<{ disabled: boolean }>;
};

export const DisableInEmbedded = ({ children }: DisableInEmbeddedProps) => {
  const { isEmbeddedMode } = useLayoutContext();

  if (isEmbeddedMode && isValidElement(children)) {
    return cloneElement(children, { disabled: true });
  }

  return children;
};
