import { Fragment, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { sample, findLastIndex, findLast } from 'lodash';
import classNames from 'classnames';

import { useTrackEvent } from '@/lib/analytics';
import { IconButton } from '@/components/button';
import { Icon } from '@/components/icon';
import { Loader } from '@/components/loader';
import { Tooltip } from '@/components/tooltip';
import { useExplorationChatLazyQuery } from '@/graphql';
import { useSelectedAccount } from '@/lib/accounts/context';
import { generateUUID } from '@/lib/utils';
import { isTimeoutError } from '@/lib/error/utils/timeout';
import { convertChatMessage } from '@/explore/input';
import { exportExploration, exportChatMessage } from '@/explore/output';
import {
  isConversationStart,
  removeExplorationConversation,
  replaceCells,
} from '@/core/exploration';
import { removeConversationEditFlow } from '@/core/exploration/conversation';
import {
  convertConversationCellsToMessages,
  discardConversationAfterMessage,
  getConversationCells,
  isChatCell,
  isConversationCell,
  unlinkConversationCell,
  updateConversationMessage,
} from '@/core/cell';
import {
  Exploration,
  Cell,
  ChatCell,
  ChatMessage,
  ChatExploration,
  ChatAssistantMessage,
  ChatUserMessage,
} from '@/explore/types';

import { useExplorationContext } from '../exploration-context';
import { useExplorationCellContext } from '../exploration-cell-context';
import { discardClearsView } from './utils';
import { UserMessageInput } from './user-message-input';
import { InitialMessage } from './initial-message';
import { UserMessage } from './user-message';

import styles from './chat-cell.module.scss';

// TODO: Move to core/cell/conversation-cell
const isExplorationMessage = (msg: ChatMessage | ChatExploration): msg is ChatExploration =>
  msg.type === 'exploration';

const isLastConversationCell = (cellId: string, conversationId: string, cells: Cell[]) => {
  const lastCell = findLast(
    cells,
    (cell) => isConversationCell(cell) && cell.conversationId === conversationId,
  );
  return lastCell?.id === cellId;
};

const loadingMessages = [
  'Analyzing your question',
  'Searching through data',
  'Thinking of a great solution',
  'Retrieving relevant data',
  'Analyzing the information',
  'Ruminating on the question',
  'Conjuring up a response',
  'Tapping into the collective unconscious',
  'Channeling the wisdom of the ages',
  'Plotting the trajectory of insight',
  'Thinking really hard',
  'Accessing mainframe...',
  'Reticulating splines...',
];
// const longerLoadingMessages = [
//   'Finishing up',
//   'Coming up with something better',
//   'Unraveling the mysteries of the universe',
//   'Transcending the boundaries of knowledge',
//   'Embracing the ambiguity of existence',
// ];
// TODO: Display a second loading message from longerLoadingMessages once N time has passed
// const intervalSpeed = 4000;

interface ChatInputProps {
  messages: ChatMessage[];
  status?: 'loading' | 'error';
  statusMessage?: string;
  pendingFirstInput?: boolean;
  onSubmit(answer: string): void;
  onAccept: () => void;
  onDiscard: () => void;
  onMessageChange: (message: ChatUserMessage) => void;
  onRetry: () => void;
}

const ChatInput = ({
  messages,
  status,
  statusMessage,
  pendingFirstInput = false,
  onSubmit,
  onAccept,
  onDiscard,
  onMessageChange,
  onRetry,
}: ChatInputProps) => {
  const [lastMessage] = messages.slice(-1);

  const handleAnswer = (answer: string) => {
    if (!answer.length) {
      return onAccept();
    }

    onSubmit(answer);
  };

  if (!messages.length) {
    return (
      <div className={styles.form}>
        <UserMessageInput
          placeholder="Follow up or request edits"
          actions={(value) => [
            { label: value.length > 0 ? 'Ask' : 'Accept', onClick: () => handleAnswer(value) },
            {
              label: pendingFirstInput ? 'Cancel' : 'Discard last',
              variant: 'secondary',
              onClick: onDiscard,
            },
          ]}
          autoFocus
        />
      </div>
    );
  }

  const steps = messages.slice(1);

  const hasAnswer = (idx: number) =>
    steps.some((step, i) => i > idx && step.type === 'clarifying_answer');

  const [firstMessage] = messages;

  if (firstMessage.role !== 'user') {
    throw new Error('First message must be from the user');
  }

  return (
    <div className={styles.form}>
      <InitialMessage message={firstMessage} onChange={onMessageChange} />
      <div className={styles.steps}>
        {steps.length > 0 && (
          <>
            {steps.map((step, index) => {
              switch (step.type) {
                case 'clarifying_question':
                  return (
                    <Fragment key={index}>
                      {hasAnswer(index) ? null : (
                        <div className={classNames([styles.step, styles.clarifying])}>
                          <Icon name="PauseIcon" size={16} className={styles.pauseIcon} />
                          Clarifying user intent
                        </div>
                      )}
                      <div className={styles.questionContainer}>
                        <div className={styles.avatar}>
                          <Icon name="Zap" size={24} />
                        </div>
                        <div className={styles.content}>
                          <div className={styles.clarificationHeader}>Clarifying question</div>
                          <div className={styles.question}>{step.message}</div>
                        </div>
                      </div>
                    </Fragment>
                  );
                case 'clarifying_answer':
                  return <UserMessage key={step.id} message={step} onChange={onMessageChange} />;
                case 'followup_question':
                case 'initial_user_prompt':
                  throw new Error('Unhandled follow-up chat flow');
              }
            })}
          </>
        )}

        {status !== undefined && (
          <div className={styles.step}>
            {status === 'error' ? (
              <>
                <Icon name="X" size={16} />
                {statusMessage}
                <Tooltip content="Try again">
                  <IconButton icon="RotateCcw" className={styles.retryBtn} onClick={onRetry} />
                </Tooltip>
              </>
            ) : (
              <>
                <Loader size="small" type="spinner-dark" />
                {statusMessage}
              </>
            )}
          </div>
        )}

        {lastMessage.type === 'clarifying_question' && (
          <UserMessageInput
            placeholder="Reply to the clarifying question"
            actions={(value) =>
              value.length > 0 ? [{ label: 'Answer', onClick: () => handleAnswer(value) }] : []
            }
            hints={(value) => [{ label: 'Answer to continue', hidden: value.length > 0 }]}
            autoFocus
          />
        )}
      </div>
    </div>
  );
};

interface ChatCellViewProps {
  cell: ChatCell;
  exploration: Exploration;
}

export const ChatCellView = (props: ChatCellViewProps) => {
  const { cell, exploration: currentExploration } = props;
  const account = useSelectedAccount();
  const { setExploration, scrollToCell, setNewCellIndex, selectCell, selectedCell } =
    useExplorationContext();
  const { cellIndex, setCell } = useExplorationCellContext();
  const trackEvent = useTrackEvent();
  const [status, setStatus] = useState<'loading' | 'error' | undefined>();
  const [statusMessage, setStatusMessage] = useState<string>();
  const questionPendingMessageCount = useRef(0);

  const [queryNextMessage, queryState] = useExplorationChatLazyQuery({
    onCompleted(data) {
      const [msg] = data?.account?.ask.messages ?? [];
      if (msg === undefined) {
        return;
      }

      const message = convertChatMessage(msg);
      setStatus(undefined);
      setStatusMessage(undefined);

      // TTQ responded with 1 or more cells
      if (isExplorationMessage(message)) {
        return handleGeneratedCells(message.exploration.view.cells);
      }

      setCell({
        ...cell,
        messages: cell.messages.concat([message]),
      });
    },
    onError(error) {
      if (isTimeoutError(error)) {
        const timeoutMessage: ChatAssistantMessage = {
          id: generateUUID(),
          role: 'assistant',
          type: 'clarifying_question',
          message: `Unfortunately, I couldn't put together a great answer in time. I could just try again, or you could help me out by rephrasing the question or breaking it down into smaller parts. How would you like me to continue?`,
        };

        setCell({
          ...cell,
          messages: cell.messages.concat([timeoutMessage]),
        });
        setStatus(undefined);
        setStatusMessage(undefined);
        return;
      }

      setStatus('error');
    },
  });

  const handleInput = useCallback(
    (question: string, appendQuestionAsMessage = true, updateLoadingState = true) => {
      const correlationId = generateUUID();
      const conversationCells = getConversationCells(
        currentExploration.view.cells,
        cell.conversationId,
      );

      const updatedCell = {
        ...cell,
        messages: [
          ...cell.messages,
          ...(appendQuestionAsMessage
            ? [
                {
                  id: generateUUID(),
                  type: cell.messages.length
                    ? ('clarifying_answer' as const)
                    : ('followup_question' as const),
                  role: 'user' as const,
                  message: question,
                },
              ]
            : []),
        ],
      };

      setCell(updatedCell);

      const { messages: conversationMessages, selector } = convertConversationCellsToMessages([
        ...conversationCells,
        ...(appendQuestionAsMessage ? [updatedCell] : []),
      ]);

      if (updateLoadingState) {
        setStatusMessage(sample(loadingMessages));
        setStatus('loading');
      }

      const explorationWithoutConversations = {
        ...currentExploration,
        view: {
          ...currentExploration.view,
          cells: currentExploration.view.cells.filter(
            (c) =>
              !isConversationCell(c) ||
              (c.conversationId === cell.conversationId &&
                isConversationStart(c, currentExploration)),
          ),
        },
      };

      queryNextMessage({
        variables: {
          accountId: account.accountId,
          exploration: exportExploration(explorationWithoutConversations),
          correlationId,
          selectors: selector !== undefined ? [selector] : [],
          conversation: {
            conversationId: cell.conversationId,
            messages: conversationMessages.map(exportChatMessage),
          },
        },
        context: {
          // TODO: Read from import.meta.env.VITE_REQUEST_TIMEOUT_MS
          fetchOptions: { signal: AbortSignal.timeout(60 * 1000) },
        },
      });

      trackEvent('Exploration Chat Generation Started', {
        searchTerm: question,
        conversationId: cell.conversationId,
        // Other blocks exist besides this chat block
        hasCurrentExploration: currentExploration.view.cells.length > 1,
        correlationId,
      });
    },
    [account.accountId, cell, currentExploration, queryNextMessage, setCell, trackEvent],
  );

  const lastCellInConversation = useMemo(
    () => isLastConversationCell(cell.id, cell.conversationId, currentExploration.view.cells),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentExploration.view.cells.length],
  );

  useEffect(() => {
    if (lastCellInConversation) {
      questionPendingMessageCount.current = 0;
    }
  }, [lastCellInConversation]);

  useEffect(() => {
    if (!lastCellInConversation) {
      return;
    }

    if (!queryState.loading && status === 'loading') {
      setStatus(undefined);
    } else if (queryState.loading && status !== 'loading') {
      setStatus('loading');
    }

    const [lastMessage] = cell.messages.slice(-1);

    if (
      lastMessage?.role === 'user' &&
      !queryState.loading &&
      !queryState.error &&
      questionPendingMessageCount.current !== cell.messages.length
    ) {
      handleInput(lastMessage.message, false);
    }
    questionPendingMessageCount.current = cell.messages.length;
  }, [
    cell.messages,
    queryState.loading,
    queryState.error,
    status,
    handleInput,
    lastCellInConversation,
  ]);

  const pendingFirstInput = useMemo(() => {
    const cells = getConversationCells(currentExploration.view.cells, cell.conversationId);
    return cells.every((cell) => !isChatCell(cell) || !cell.messages.length);
  }, [currentExploration.view.cells, cell.conversationId]);

  const belongsToCurrentConversation = (c: Cell) =>
    isConversationCell(c) && c.conversationId === cell.conversationId;

  const handleGeneratedCells = (cells: Cell[]) => {
    const newCells = [
      ...cells.map((c) => ({ ...c, conversationId: cell.conversationId })),
      {
        id: generateUUID(),
        kind: 'chat' as const,
        conversationId: cell.conversationId,
        messages: [],
      },
    ];

    const newExploration = {
      ...currentExploration,
      view: {
        ...currentExploration.view,
        cells: currentExploration.view.cells.reduce<Cell[]>((result, c, idx) => {
          if (idx !== cellIndex) {
            result.push(c);
            return result;
          }

          result.push(...[cell, ...newCells]);
          return result;
        }, []),
      },
    };

    setExploration(newExploration);
    scrollToCell(newCells[0].id);
    if (selectedCell !== null) {
      selectCell(newCells[0].id);
    }
  };

  const handleAccept = () => {
    const cellsToKeep = getConversationCells(
      currentExploration.view.cells,
      cell.conversationId,
    ).filter((c) => !isChatCell(c) && belongsToCurrentConversation(c));

    const newExploration = {
      ...currentExploration,
      view: {
        ...currentExploration.view,
        cells: currentExploration.view.cells.reduce<Cell[]>((result, c) => {
          if (!isConversationCell(c) || !belongsToCurrentConversation(c)) {
            result.push(c);
            return result;
          }

          if (cellsToKeep.some((convCell) => convCell.id === c.id)) {
            result.push(unlinkConversationCell(c));
          }

          return result;
        }, []),
      },
    };

    setExploration(newExploration);
  };

  const handleRetry = () => {
    const lastUserMessage = findLast(cell.messages, (cell) => cell.role === 'user');
    if (lastUserMessage !== undefined) {
      setStatus('loading');
      handleInput(lastUserMessage.message, false, false);
    }
  };

  const handleDiscard = () => {
    const lastInputCellIndex = findLastIndex(
      currentExploration.view.cells,
      (c) =>
        belongsToCurrentConversation(c) &&
        isChatCell(c) &&
        c.messages.some((m) => m.role === 'user'),
    );

    // User requested edits & discards immediately
    if (pendingFirstInput) {
      const cellToEdit = getConversationCells(
        currentExploration.view.cells,
        cell.conversationId,
      ).at(0);

      return setExploration(removeConversationEditFlow(currentExploration, cell.id, cellToEdit));
    }

    const resetView = discardClearsView(currentExploration.view.cells, lastInputCellIndex);
    const newExploration = {
      ...currentExploration,
      view: {
        ...currentExploration.view,
        cells: resetView
          ? // If both lastInputCellIndex === 0 and lastUserMessageIndex === 0, return empty exploration
            []
          : // Otherwise slice conversation up until last user input
            currentExploration.view.cells
              .map((c, index) => {
                if (index === lastInputCellIndex && isChatCell(c)) {
                  const lastUserMessageIndex = findLastIndex(
                    c.messages,
                    (msg) => msg.role === 'user',
                  );
                  return {
                    ...c,
                    messages: c.messages.slice(0, lastUserMessageIndex),
                  };
                }

                return c;
              })
              .filter((c, index) => {
                if (!belongsToCurrentConversation(c)) {
                  return true;
                }

                return index <= lastInputCellIndex;
              }),
      },
    };

    const conversationCells = getConversationCells(newExploration.view.cells, cell.conversationId);
    if (
      conversationCells.length === 0 ||
      (conversationCells.length <= 1 &&
        isChatCell(conversationCells[0]) &&
        conversationCells[0].messages.length === 0)
    ) {
      const firstCellIndex = currentExploration.view.cells.findIndex(
        (c) => isConversationCell(c) && c.conversationId === cell.conversationId,
      );
      setNewCellIndex(firstCellIndex);
      setExploration(removeExplorationConversation(newExploration, cell.conversationId));
    } else {
      setExploration(newExploration);
    }
  };

  const handleMessageChange = (message: ChatUserMessage) => {
    setExploration(
      replaceCells(
        currentExploration,
        updateConversationMessage(
          cell.conversationId,
          discardConversationAfterMessage(
            cell.conversationId,
            currentExploration.view.cells,
            message.id,
          ),
          message,
        ),
      ),
    );
  };

  return (
    <ChatInput
      messages={cell.messages}
      status={status}
      statusMessage={statusMessage}
      pendingFirstInput={pendingFirstInput}
      onSubmit={handleInput}
      onAccept={handleAccept}
      onDiscard={handleDiscard}
      onRetry={handleRetry}
      onMessageChange={handleMessageChange}
    />
  );
};
