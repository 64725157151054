import { compact, indexOf } from 'lodash';

import { isPipelineWithParent } from '@/core/pipeline';
import { isCellWithPipeline } from '@/core/cell';

import { Exploration, Pipeline, TimeAggregationPeriod } from '../types';
import { getCell } from '../exploration/utils';

export const pipelineColor = (index: number | undefined, colors: string[]) =>
  index !== undefined ? colors[(index % colors.length) - 1] : undefined;

export const getParentPipelineColor = (exploration: Exploration, pipeline: Pipeline) => {
  if (!isPipelineWithParent(pipeline)) {
    return;
  }

  return getPipelineColorByPipelineId(exploration, pipeline.parentId);
};

export const getPipelineColor = (exploration: Exploration, pipeline: Pipeline) => {
  if (pipeline.pipelineId === undefined) {
    return;
  }

  return getPipelineColorByPipelineId(exploration, pipeline.pipelineId);
};

const getPipelineColorByPipelineId = (exploration: Exploration, pipelineId: string) => {
  const pipelineIds = compact(
    exploration.view.cells.map((cell) =>
      isCellWithPipeline(cell) ? cell.pipeline.pipelineId : undefined,
    ),
  );

  return indexOf(pipelineIds, pipelineId) + 1;
};

export const getPipelineColorByCellId = (exploration: Exploration, cellId: string) => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined || !isCellWithPipeline(cell)) {
    return;
  }

  return getPipelineColor(exploration, cell.pipeline);
};

export const formatRelationLabel = ({
  relationName,
  relatedModelName,
  baseModelName,
}: {
  relationName: string;
  relatedModelName: string;
  baseModelName: string;
}) => `${relationName} (${relatedModelName}) on ${baseModelName}`;

export const getTimeAggregationPeriodLabel = (precision: TimeAggregationPeriod) => {
  switch (precision) {
    case 'hour':
      return 'Hour';
    case 'day':
      return 'Day';
    case 'week':
      return 'Week';
    case 'day_of_week':
      return 'Day of Week';
    case 'month':
      return 'Month';
    case 'quarter':
      return 'Quarter';
    case 'year':
      return 'Year';
    default:
      return precision;
  }
};
