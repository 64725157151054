import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { useHover } from '@/lib/hooks/use-hover';

import styles from './exploration-layout.module.scss';

interface CellResizeAreaProps {
  isCellSelected?: boolean;
  isCellBordered?: boolean;
  onResizeStart: () => void;
  onResize: (height: number) => void;
  onResizeEnd: (height: number) => void;
  onResetSize: () => void;
}

export const CellResizeArea = (props: CellResizeAreaProps) => {
  const {
    isCellSelected,
    isCellBordered = false,
    onResizeStart,
    onResize,
    onResizeEnd,
    onResetSize,
  } = props;
  const resizeAreaRef = useRef<HTMLDivElement>(null);
  const showResizeHandle = useHover(resizeAreaRef);
  const [dragStartY, setDragStartY] = useState<number | null>(null);

  useEffect(() => {
    if (dragStartY === null) {
      return;
    }

    const handleMouseMove = (event: MouseEvent) => {
      if (dragStartY === null) {
        return;
      }
      const dy = event.clientY - dragStartY;
      onResize(dy);
    };

    const handleMouseUp = (event: MouseEvent) => {
      const dy = event.clientY - dragStartY;
      onResizeEnd(dy);
      setDragStartY(null);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragStartY, onResize, onResizeEnd]);

  const handleMouseDown = (event: React.MouseEvent) => {
    setDragStartY(event.clientY);
    onResizeStart();
  };

  return (
    <div
      className={classNames(styles.resizeArea, {
        [styles.showResizeHandle]: showResizeHandle,
        [styles.isCellSelected]: isCellSelected,
        [styles.isCellBordered]: isCellBordered,
      })}
      ref={resizeAreaRef}>
      <button
        className={styles.resizeHandle}
        onMouseDown={handleMouseDown}
        onDoubleClick={onResetSize}
        title="Resize block"
        aria-label="Resize block"
      />
    </div>
  );
};
