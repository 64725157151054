import classNames from 'classnames';
import { forwardRef } from 'react';

import { FormInputSize } from '../types';

import style from './textarea.module.scss';

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  highlighted?: boolean;
  size?: FormInputSize | 'extraLarge';
}

export const TextArea = forwardRef(function TextArea(
  { highlighted = false, size = 'small', ...props }: TextAreaProps,
  ref?: React.ForwardedRef<HTMLTextAreaElement>,
) {
  return (
    <textarea
      {...props}
      className={classNames(style.textArea, props.className, {
        [style.highlighted]: highlighted,
        [style.sizeSmall]: size === 'small',
        [style.sizeMedium]: size === 'regular',
        [style.sizeLarge]: size === 'large',
      })}
      ref={ref}
    />
  );
});
