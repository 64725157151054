import { Fragment, useState } from 'react';
import classNames from 'classnames';

import { set } from 'lodash';

import { IconButton, InlineButton } from '../../components/button';
import { Input } from '../../components/form/input';
import { Icon } from '../../components/icon';
import type { Aggregation, AggregationExtendedType, Field, Metric } from '../types';
import { AggregationForm } from './aggregation-form';
import { SortableItem } from '../components/sortable-item';
import { ensureUniqueKey, getPropertyKey } from './utils';
import { Dropdown, DropdownMenuItem } from '../../components/dropdown';
import {
  AggregationOptions,
  aggregationTypeToAggregationExtendedType,
  generateAggregationProperty,
  getAggregationOptionsForFields,
  getDefaultAggregation,
} from '../utils/aggregation';

import { useEnsureFieldsExist } from './hooks/use-ensure-fields-exist';
import {
  getAggregationKeys,
  getAggregationPropertyKeys,
  isKeyedAggregation,
} from '../pipeline/operation';

import form from '../../components/form/form.module.scss';

interface AggregationsEditorProps {
  aggregations: Aggregation[];
  fields: Field[];
  metrics: Metric[];
  relationName?: string;
  showNameInput?: boolean;
  maxAggregations?: number;
  setAggregations(aggregations: Aggregation[]): void;
  // If no empty state is provided, the editor will always show at least one aggregation
  empty?: (addAggregation: (aggregation: Aggregation) => void) => React.ReactNode;
  autoFocus?: boolean;
  excludeAggregationTypes?: AggregationExtendedType[];
  getMenuOptions?: (aggregation: Aggregation, idx: number) => DropdownMenuItem[];
}

export const AggregationsEditor = (props: AggregationsEditorProps) => {
  const {
    aggregations,
    metrics,
    excludeAggregationTypes = [],
    relationName,
    autoFocus = false,
    getMenuOptions,
  } = props;

  const fields = useEnsureFieldsExist(props.fields, getAggregationKeys(aggregations));
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  if (aggregations.length === 0 && props.empty === undefined) {
    const defaultAggregation = getDefaultAggregation(
      fields,
      metrics,
      relationName,
      getAggregationOptionsForFields(fields).filter(
        ({ value }) => !excludeAggregationTypes.includes(value),
      ),
    );
    aggregations.push(set(defaultAggregation, 'property.name', ''));
  }

  const handleSetAggregation = (idx: number, aggregation: Aggregation) =>
    props.setAggregations([
      ...aggregations.slice(0, idx),
      aggregation,
      ...aggregations.slice(idx + 1),
    ]);

  const handleAddAggregation = (aggregation: Aggregation) => {
    const existingKeys = getAggregationPropertyKeys(aggregations);
    props.setAggregations([
      ...aggregations,
      {
        ...aggregation,
        property: {
          ...aggregation.property,
          key: ensureUniqueKey(existingKeys, aggregation.property.key),
        },
      },
    ]);
  };

  const handleRemoveAggregation = (index: number) => {
    props.setAggregations([...aggregations.slice(0, index), ...aggregations.slice(index + 1)]);
  };

  const handleReorder = (fromIndex: number, toIndex: number) => {
    const tempAggregations = [
      ...aggregations.slice(0, fromIndex),
      ...aggregations.slice(fromIndex + 1),
    ];
    props.setAggregations([
      ...tempAggregations.slice(0, toIndex),
      aggregations[fromIndex],
      ...tempAggregations.slice(toIndex),
    ]);
  };

  const canAddAggregation =
    props.maxAggregations === undefined || aggregations.length < props.maxAggregations;

  if (aggregations.length === 0 && props.empty !== undefined) {
    return props.empty(handleAddAggregation);
  }

  const sortable = aggregations.length > 1;

  return (
    <div className={form.formHorizontal}>
      {aggregations.map((aggregation, i) => {
        return (
          <Fragment key={i}>
            {i > 0 && <hr className={form.dashed} />}
            <SortableItem
              scope={'aggregation'}
              index={i}
              draggedIndex={draggedIndex}
              setDraggedIndex={setDraggedIndex}
              onReorder={handleReorder}
              className={classNames(form.formHorizontal, {
                [form.sortableRow]: sortable,
              })}
              draggable={sortable}
              classNames={{
                dragBefore: form.dragBefore,
                dragAfter: form.dragAfter,
              }}>
              {sortable && <Icon name="DragHandle" size={10} className={form.sortHandle} />}
              <EditAggregation
                key={aggregation.property.key}
                aggregation={aggregation}
                fields={fields}
                metrics={metrics}
                autoFocus={autoFocus}
                isRemovable={aggregations.length > 1 || props.empty !== undefined}
                showNameInput={props.showNameInput}
                setAggregation={(aggregation) => handleSetAggregation(i, aggregation)}
                onRemove={() => handleRemoveAggregation(i)}
                relationName={props.relationName}
                excludeAggregationTypes={excludeAggregationTypes}
                menuOptions={getMenuOptions?.(aggregation, i)}
              />
            </SortableItem>
          </Fragment>
        );
      })}

      {canAddAggregation ? (
        <div>
          <InlineButton
            size="small"
            onClick={() => {
              const unusedAggregationOptions = AggregationOptions.filter(
                (option) =>
                  aggregations.every((agg) => {
                    const field = isKeyedAggregation(agg)
                      ? fields.find((f) => f.key === agg.key)
                      : undefined;
                    const type = aggregationTypeToAggregationExtendedType(agg.type, field);
                    return type !== option.value;
                  }) && !excludeAggregationTypes.includes(option.value),
              );
              handleAddAggregation(
                getDefaultAggregation(fields, metrics, relationName, unusedAggregationOptions),
              );
            }}>
            <Icon name="Plus" size={15} /> Summarization
          </InlineButton>
        </div>
      ) : null}
    </div>
  );
};

interface EditAggregationProps {
  aggregation: Aggregation;
  fields: Field[];
  metrics: Metric[];
  isRemovable: boolean;
  setAggregation(aggregation: Aggregation): void;
  showNameInput?: boolean;
  relationName?: string;
  onRemove?(): void;
  excludeAggregationTypes?: AggregationExtendedType[];
  autoFocus?: boolean;
  menuOptions?: DropdownMenuItem[];
}

const EditAggregation = (props: EditAggregationProps) => {
  const {
    aggregation,
    fields,
    metrics,
    isRemovable,
    showNameInput,
    relationName,
    excludeAggregationTypes = [],
    autoFocus = false,
    onRemove,
    setAggregation,
    menuOptions = [],
  } = props;

  const defaultProperty = generateAggregationProperty(aggregation, fields, metrics, relationName);
  const isDefaultProperty =
    aggregation.property.name === '' || aggregation.property.name === defaultProperty.name;

  const [name, setName] = useState(aggregation.property.name);

  const [manualPropertyEnabled, setManualPropertyEnabled] = useState(!isDefaultProperty);
  // Show default property as empty input unless it's been modified
  const [isPropertyDirty, setIsPropertyDirty] = useState(false);

  const shouldShowNameInput = showNameInput === true || manualPropertyEnabled || !isDefaultProperty;

  const menuItems: DropdownMenuItem[] = [...menuOptions];

  if (!shouldShowNameInput) {
    menuItems.push({
      label: 'Add custom name',
      onClick: () => setManualPropertyEnabled(true),
      icon: <Icon name="Edit3" size={16} />,
    });
  }

  if (isRemovable) {
    menuItems.push({
      label: 'Delete',
      onClick: onRemove,
      icon: <Icon name="Trash2" size={16} />,
    });
  }

  const onlyRemoveOptionPossible = showNameInput === true;

  const setAggregationPropertyName = () => {
    const propertyName = !name.length ? defaultProperty.name : name;

    setAggregation({
      ...aggregation,
      property: {
        key: getPropertyKey(aggregation.property, propertyName),
        name: propertyName,
      },
    });
  };

  return (
    <>
      <div className={classNames(form.formRow, form.alignTop)}>
        <AggregationForm
          aggregation={aggregation}
          fields={fields}
          metrics={metrics}
          autoFocus={autoFocus}
          relationName={relationName}
          setAggregation={(updatedAggregation) => {
            const newDefaultProperty = generateAggregationProperty(
              updatedAggregation,
              fields,
              metrics,
              relationName,
            );
            setAggregation({
              ...updatedAggregation,
              property:
                isDefaultProperty && !isPropertyDirty
                  ? {
                      name: newDefaultProperty.name,
                      key: aggregation.property.key ?? newDefaultProperty.key,
                    }
                  : updatedAggregation.property,
            });
          }}
          excludeAggregationTypes={excludeAggregationTypes}
        />
        {onlyRemoveOptionPossible && isRemovable ? (
          <IconButton icon="Trash2" title="Remove aggregation" size="small" onClick={onRemove} />
        ) : (
          menuItems.length > 0 && (
            <Dropdown
              align="right"
              trigger={(isOpen, setIsOpen) => (
                <IconButton
                  icon="MoreHorizontal"
                  size="small"
                  title="More..."
                  type="gray"
                  onClick={() => setIsOpen(!isOpen)}
                />
              )}
              items={menuItems}
            />
          )
        )}
      </div>
      {shouldShowNameInput && (
        <div className={classNames(form.formRow)}>
          <label className={form.formLabel}>Column name</label>
          <Input
            type="text"
            autoFocus={autoFocus}
            value={isDefaultProperty && !isPropertyDirty ? '' : name}
            placeholder={
              generateAggregationProperty(aggregation, fields, metrics, relationName).name
            }
            onChange={(e) => {
              setIsPropertyDirty(true);
              setName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setAggregationPropertyName();
              }
            }}
            onBlur={setAggregationPropertyName}
          />
        </div>
      )}
    </>
  );
};
