import { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../../auth/auth-context';

export const LogoutPage = () => {
  const { logOut } = useAuth();
  const logOutCalled = useRef(false);

  useEffect(() => {
    if (!logOutCalled.current) {
      logOut();
    }

    logOutCalled.current = true;
  }, [logOut]);

  return <Navigate to="/login" />;
};
