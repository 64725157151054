import { useMemo, useState, useRef } from 'react';
import classNames from 'classnames';

import { useTrackEvent } from '@/lib/analytics';
import { useKeyPress } from '@/lib/hooks/use-key-press';
import { Button } from '@/components/button';
import { Icon } from '@/components/icon';
import { Tooltip } from '@/components/tooltip';
import { getHashSearchTerm, setHashSearchTerm } from '@/explore/utils/url';
import { ShortcutIndicator } from '@/components/shortcut-indicator';

import { AutoResizeTextArea } from '@/components/form/text-area/auto-resize-text-area';

import { Exploration } from '../../types';
import { BuildType } from '../../utils/builder';
import { AddButton } from './buttons/add-button';
import { AddDropdown } from './buttons/add-dropdown';

import styles from './cell-input.module.scss';

export type ActionTypes = BuildType & 'data';

interface CellInputProps {
  emptyExploration?: boolean;
  onAsk: (question: string) => void;
  onClose: () => void;
  onShowCatalog: () => void;
  onClickExploration: (exploration: Exploration) => void;
}

export const CellInput = ({
  emptyExploration = false,
  onAsk,
  onClose,
  onShowCatalog,
  onClickExploration,
}: CellInputProps) => {
  const [searchTerm, setSearchTerm] = useState(getHashSearchTerm(window.location.hash) ?? '');
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const trackEvent = useTrackEvent();
  const inputRef = useRef(null);

  const trimmedSearchTerm = useMemo(() => searchTerm.trim(), [searchTerm]);

  useKeyPress(
    'Escape',
    (e) => {
      e.preventDefault();
      onClose();
      trackEvent('CellInput closed');
    },
    { targetRef: inputRef },
  );

  useKeyPress(
    ['o', 'meta'],
    (e) => {
      e.preventDefault();
      onShowCatalog();
    },
    { targetRef: inputRef },
  );

  const handleQuestion = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (trimmedSearchTerm.length) {
      onAsk(searchTerm);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleQuestion();
    }
  };

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    const hash = setHashSearchTerm(window.location.hash, value);
    setSearchTerm(value);
    window.history.replaceState(null, '', `${location.pathname}#${hash}`);
  };

  return (
    <>
      {emptyExploration && <div className={styles.title}>Let&apos;s dive in</div>}
      <div className={classNames(styles.form, emptyExploration && styles.revealForm)}>
        <div className={styles.toolsRow}>
          <Tooltip
            hasArrow={false}
            content={
              <span>
                Browse your explorations and models{' '}
                <ShortcutIndicator shortcut={['cmd', 'O']} size="small" />
              </span>
            }>
            <Button
              icon={<Icon name="Model" size={16} />}
              variant="secondary"
              size="small"
              onMouseUp={(e) => e.currentTarget.blur()}
              onClick={onShowCatalog}>
              Data catalog
            </Button>
          </Tooltip>
          <AddButton size="small" type="variable" onClick={onClickExploration} />
          <AddButton size="small" type="text" onClick={onClickExploration} />
          <AddDropdown type="code" onClick={onClickExploration} />
          <AddDropdown type="templates" onClick={onClickExploration} />
        </div>
        <form className={styles.inputRow} onSubmit={handleQuestion}>
          <AutoResizeTextArea
            value={searchTerm}
            onChange={handleSearchTermChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setInputHasFocus(true)}
            onBlur={() => setInputHasFocus(false)}
            placeholder="Type to ask AI a question"
            autoFocus
            rows={1}
            ref={inputRef}
          />

          <div
            className={classNames([
              styles.inputActions,
              styles.conditionalChildren,
              trimmedSearchTerm.length > 0 && styles.switchChildren,
            ])}>
            {trimmedSearchTerm.length > 0 && (
              <>
                <Button type="submit" variant="primary" size="compact">
                  Ask AI
                </Button>
                <button type="button" className={styles.hint} onClick={onShowCatalog}>
                  Search data catalog (
                  <ShortcutIndicator
                    shortcut={['cmd', 'o']}
                    size="tiny"
                    inline
                    className={styles.inlineShortcut}
                  />
                  )
                </button>
              </>
            )}
            {!emptyExploration ? (
              <button type="button" className={styles.hint} onClick={onClose}>
                {inputHasFocus ? 'Esc to close' : 'Click to close'}
              </button>
            ) : (
              <span className={styles.hint}>
                Open up an exploration or data model from your data catalog, or ask Al a question
              </span>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
