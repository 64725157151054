import { useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

import { Tooltip } from '@/components/tooltip';
import { convertModelTypes } from '@/explore/input';
import { useTrackEvent } from '@/lib/analytics';
import { IconButton } from '@/components/button';

import { useAccountContext } from '../lib/accounts/context';
import { Loader } from '../components/loader';

import { useAccountSettingsQuery } from '../graphql';
import { AccessTokensSection } from './access-tokens';
import { CliSection } from './cli/section';
import { UsersSection } from './users/section';
import { ConnectionsSection } from './connections/section';
import { NavigationSection } from './navigation/section';
import { AlertsSection } from './alerts';
import { AiSystemInstructionsSettings } from './ai-system-instructions';
import { useTitle } from '../lib/hooks/use-title';

import styles from './settings.module.scss';

export const AccountSettingsPage = () => {
  useTitle('Account Settings');
  const { account, hasPermission } = useAccountContext();
  const [accountIdCopied, setAccountIdCopied] = useState(false);
  const trackEvent = useTrackEvent();
  const [, copyToClipboard] = useCopyToClipboard();

  const { data, refetch, loading } = useAccountSettingsQuery({
    variables: { accountId: account.accountId },
  });

  const handleCopyAccountId = async () => {
    await copyToClipboard(account.accountId);

    setAccountIdCopied(true);
    trackEvent('Account ID Copied', { accountIdId: account.accountId });

    setTimeout(() => setAccountIdCopied(false), 3000);
  };

  return (
    <div className={styles.wrapper}>
      <h1>Account Settings</h1>
      <div className={styles.accountId}>
        Account ID: <pre>{account.accountId}</pre>
        <Tooltip content={accountIdCopied ? 'Copied!' : 'Copy Account ID'}>
          <IconButton
            icon="Copy"
            onClick={handleCopyAccountId}
            onMouseLeave={() => setTimeout(() => setAccountIdCopied(false), 500)}
          />
        </Tooltip>
      </div>
      <div className={styles.sections}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <UsersSection
              accountId={account.accountId}
              currentUserId={data?.account?.me.userId ?? ''}
              canEdit={hasPermission('MANAGE_USERS')}
              users={data?.account?.users ?? []}
            />
            <AccessTokensSection accessTokens={data?.accessTokens ?? []} refetch={refetch} />
            <CliSection />
            <NavigationSection
              navigation={data?.account?.navigation ?? []}
              explorations={data?.account?.explorations ?? []}
              canEdit={hasPermission('MANAGE_ACCOUNT')}
            />
            <ConnectionsSection connections={data?.account?.connections ?? []} />
            <AlertsSection
              accountId={account.accountId}
              alertConfigurations={data?.account?.alertConfigurations ?? []}
              models={convertModelTypes(data?.account?.models) ?? []}
              metrics={data?.account?.metrics ?? []}
              refetch={refetch}
              canEdit={hasPermission('MANAGE_ALERTS')}
            />
            {hasPermission('MANAGE_AI_SYSTEM_INSTRUCTIONS') && (
              <AiSystemInstructionsSettings
                aiSystemInstructions={data?.account?.aiSystemInstructions ?? ''}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
