import { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import { PageViewContextProvider, useTrackEvent } from '@/lib/analytics';
import { useBuildAccountUrl, useSelectedAccount } from '@/lib/accounts/context';
import { useKeyPress } from '@/lib/hooks/use-key-press';
import { FullPageLoader } from '@/components/loader';
import { useLayoutContext } from '@/components/layout/layout-context';
import { useExplorationsQuery } from '@/graphql';

import { ExplorationStorageProvider } from './state-store';
import { ExplorationContextProvider } from './exploration/exploration-context';
import { ExplorationView } from './exploration';
import {
  decodeExplorationParamsHash,
  buildExplorationUrl,
  getParameters,
  getExplorationFromUrl,
  modelSupportsDetailExploration,
  getModelDetailExploration,
  getVariableDefinitions,
  getModelExploration,
  getExplorationHashParams,
  getQueryVariablesFromParameters,
  getExplorationVariables,
} from './utils';
import { MetadataContextProvider, useMetadataContext } from './metadata-context';
import { useSetExploration, useGetExploration } from './state-store';
import { convertExplorationsArrayTypes, convertModelTypes } from './input';
import { useExplorationDelete, useExplorationUpsert } from './exploration/hooks';
import { ensureValidExploration } from './utils';
import { RedirectToDetailExploration } from './redirect-to-detail-exploration';
import { DirtyContextProvider } from './dirty-context';

import { buildEmptyExploration } from './utils/builder';

export const Explore = () => {
  const account = useSelectedAccount();

  const { data, loading, refetch } = useExplorationsQuery({
    variables: { accountId: account.accountId },
  });

  const { models, metrics, explorations } = useMemo(() => {
    const models = convertModelTypes(data?.account?.models) ?? [];
    const metrics = data?.account?.metrics ?? [];

    const savedDetailExplorations = convertExplorationsArrayTypes(
      data?.account?.parameterisedExplorations ?? [],
    );

    const modelDetailExplorations = models
      .filter(modelSupportsDetailExploration)
      .map(getModelDetailExploration)
      // Exclude detail explorations for models that have detail explorations customized and persisted
      .filter(({ explorationId }) =>
        savedDetailExplorations.every((e) => e.explorationId !== explorationId),
      );

    const explorations = [
      ...convertExplorationsArrayTypes(data?.account?.explorations ?? []),
      ...savedDetailExplorations,
      ...modelDetailExplorations,
      ...models.map((model) => getModelExploration(model)),
    ]
      .map((exploration) => {
        const variables = getExplorationVariables(exploration);
        return ensureValidExploration(exploration, models, metrics, variables);
      })
      .map((exploration) => ({
        ...exploration,
        options: { ...exploration.options, explorationSourceId: exploration.explorationId },
      }));

    return { models, metrics, explorations };
  }, [data?.account]);

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <MetadataContextProvider
      accountId={account.accountId}
      models={models}
      explorations={explorations}
      metrics={metrics}>
      <ExplorationStorageProvider explorations={explorations}>
        <Routes>
          <Route path="detail" element={<RedirectToDetailExploration />} />
          <Route path=":explorationId" element={<ExplorationPage refetch={refetch} />} />
          <Route path="*" element={<ExplorationsPage refetch={refetch} />} />
        </Routes>
      </ExplorationStorageProvider>
    </MetadataContextProvider>
  );
};

const ExplorationUrlLoader = () => {
  const buildAccountUrl = useBuildAccountUrl();
  const { models, explorations } = useMetadataContext();
  const setExploration = useSetExploration();
  const location = useLocation();

  const exploration = getExplorationFromUrl(location.hash, explorations, models);

  if (exploration !== null) {
    return (
      <Navigate to={buildAccountUrl(buildExplorationUrl(setExploration(exploration)))} replace />
    );
  }

  return <FullPageLoader />;
};

const ExplorationPage = ({ refetch }: { refetch: () => Promise<unknown> }) => {
  const trackEvent = useTrackEvent();

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const account = useSelectedAccount();
  const buildAccountUrl = useBuildAccountUrl();

  useEffect(() => trackEvent('Explore Page opened'), [trackEvent]);
  const { upsertExploration } = useExplorationUpsert();
  const { deleteExploration } = useExplorationDelete();

  const { models, metrics } = useMetadataContext();
  const setExploration = useSetExploration();
  const [loading, explorationState] = useGetExploration(params.explorationId);
  const { setActiveExplorationSourceId } = useLayoutContext();

  const exploration = explorationState ?? buildEmptyExploration('Explore');

  useKeyPress(['y', 'ctrl', 'meta'], () => {
    navigate(1);
  });

  useKeyPress(['z', 'ctrl', 'meta'], (event) => {
    event.preventDefault();

    if (!event.shiftKey && exploration?.options?.previousStateId === undefined) {
      return;
    }

    const forward = event.shiftKey;
    navigate(forward ? 1 : -1);
  });

  if (loading) {
    return <FullPageLoader />;
  }

  const variableDefinitions = getVariableDefinitions(exploration);
  const parameters = getParameters(
    decodeExplorationParamsHash(location.hash) ?? {},
    variableDefinitions,
  );
  const variables = getQueryVariablesFromParameters(variableDefinitions, parameters);

  return (
    <PageViewContextProvider>
      <DirtyContextProvider>
        <ExplorationContextProvider
          exploration={ensureValidExploration(exploration, models, metrics, variables)}
          models={models}
          metrics={metrics}
          setExploration={(exploration, parameters) => {
            const newExploration = setExploration(exploration);
            navigate(buildAccountUrl(buildExplorationUrl(newExploration, parameters)));
            setActiveExplorationSourceId(newExploration.options?.explorationSourceId ?? null);
            return newExploration;
          }}
          setParameters={(parameters) =>
            navigate(buildAccountUrl(buildExplorationUrl(exploration, parameters)))
          }
          upsertExploration={async (exploration, parameters) => {
            const nextExploration = await upsertExploration(exploration);
            await refetch();

            navigate(buildAccountUrl(buildExplorationUrl(nextExploration, parameters)));

            return nextExploration;
          }}
          deleteExploration={async (explorationId: string) => {
            await deleteExploration(explorationId);
            await refetch();

            navigate(buildAccountUrl('/explore'));
          }}
          resetExploration={() => {
            const explorationId = exploration.options?.explorationSourceId;
            if (explorationId !== undefined) {
              navigate(buildAccountUrl(buildExplorationUrl({ explorationId }, parameters)));
            } else {
              navigate(buildAccountUrl('/explore'));
            }
          }}
          parameters={parameters}>
          <ExplorationView accountId={account.accountId} />
        </ExplorationContextProvider>
      </DirtyContextProvider>
    </PageViewContextProvider>
  );
};

const ExplorationsPage = ({ refetch }: { refetch: () => Promise<unknown> }) => {
  const location = useLocation();

  if (getExplorationHashParams(location.hash) !== null) {
    return <ExplorationUrlLoader />;
  }

  return <ExplorationPage refetch={refetch} />;
};
