import { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icon } from '@/components/icon';

import style from './cell-input.module.scss';

interface AddSectionProps {
  compact?: boolean;
  showPaste?: boolean;
  onPaste?: () => void;
  onClick: MouseEventHandler;
}

export const CellInputTrigger = ({
  compact = true,
  showPaste = false,
  onClick,
  onPaste,
}: AddSectionProps) => (
  <div className={classNames(style.addSection, { [style.compact]: compact })}>
    <div className={style.line} />
    <button type="button" className={style.button} onClick={onClick}>
      <Icon name="Plus" size={12} color="currentColor" />
      <div className={style.text}>New block</div>
    </button>
    <div className={style.rightSection}>
      <div className={style.line} />
      {showPaste && (
        <button
          type="button"
          className={classNames(style.button, style.pasteBtn)}
          onClick={onPaste}>
          <Icon name="Clipboard" size={12} color="currentColor" />
          <div className={classNames(style.text, style.pasteBtn)}>Paste block</div>
        </button>
      )}
    </div>
  </div>
);
