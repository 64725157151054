import { LanguageSupport } from '@codemirror/language';
import { simplifySelection } from '@codemirror/commands';
import { Language, defineLanguageFacet } from '@codemirror/language';
import { styleTags, tags } from '@lezer/highlight';
import { acceptCompletion, autocompletion } from '@codemirror/autocomplete';
import { keymap } from '@codemirror/view';

import { penguinoParser } from './parser';
import { penguinoCompletions } from './completion';
import { penguinoLinter } from './linter';
import { completionIcon } from './completion-icon';
import { argumentHints } from './argument-hints';
import { tabIndentExtension } from './indentation';

const PenguinoHighlighting = styleTags({
  Identifier: tags.keyword,
  LeftParen: tags.bracket,
  RightParen: tags.bracket,
  Boolean: tags.bool,
  Comma: tags.punctuation,
  String: tags.string,
  Number: tags.number,
  Null: tags.null,
});

export function penguino(autocompletionParams?: Parameters<typeof autocompletion>[0]) {
  const defaultAutocompletionParams = {
    override: [penguinoCompletions],
    icons: false,
    addToOptions: [{ render: completionIcon, position: 0 }],
  };

  const extensions = [
    argumentHints,
    autocompletion({
      ...defaultAutocompletionParams,
      ...(autocompletionParams ?? {}),
    }),
    keymap.of([
      { key: 'Tab', run: acceptCompletion },
      { key: 'Escape', run: simplifySelection },
    ]),
    tabIndentExtension,
    penguinoLinter,
  ];

  const data = defineLanguageFacet({ closeBrackets: ['(', '"', "'"] });
  const parser = penguinoParser(PenguinoHighlighting);
  const language = new Language(data, parser, extensions, 'penguino');

  return new LanguageSupport(language, []);
}
