import { generateUUID } from '@/lib/utils';
import {
  BasePipeline,
  DereferencedPipeline,
  DereferencedPipelineOperation,
  Pipeline,
  PipelineOperation,
  PipelineWithParent,
} from '@/explore/types';

export const generatePipelineId = generateUUID;

export const isBasePipeline = (pipeline: Pipeline): pipeline is BasePipeline =>
  'baseModelId' in pipeline;

export const isPipelineWithParent = (pipeline: Pipeline): pipeline is PipelineWithParent =>
  'parentId' in pipeline;

export const isPipelineChildOf = (pipeline: Pipeline, potentialParent: Pipeline) =>
  isPipelineWithParent(pipeline) && pipeline.parentId === potentialParent.pipelineId;

export const isPipelineParentOf = (pipeline: Pipeline, potentialChild: Pipeline) =>
  isPipelineWithParent(potentialChild) && potentialChild.parentId === pipeline.pipelineId;

export const isCombinePipeline = (pipeline: Pipeline) =>
  pipeline.operations.some(({ operation }) => operation === 'joinPipeline');

export const createPipeline = (pipeline: Pipeline) =>
  isBasePipeline(pipeline) ? createBasePipeline(pipeline) : createPipelineWithParent(pipeline);

export const createBasePipeline = ({
  baseModelId,
  operations,
}: {
  baseModelId: string;
  operations: PipelineOperation[];
}): BasePipeline => ({
  pipelineId: generatePipelineId(),
  baseModelId,
  operations,
});

export const createPipelineWithParent = ({
  parentId,
  operations,
}: {
  parentId: string;
  operations: PipelineOperation[];
}): PipelineWithParent => ({
  pipelineId: generatePipelineId(),
  parentId,
  operations,
});

export const createDereferencedPipeline = ({
  baseModelId,
  operations,
}: {
  baseModelId: string;
  operations: DereferencedPipelineOperation[];
}): DereferencedPipeline => ({
  pipelineId: generatePipelineId(),
  baseModelId,
  operations,
});
