import { first } from 'lodash';

import { Field } from '@/explore/types';
import { flattenNestedList } from '@/explore/grouping';
import { formatNumber } from '@/explore/utils/format';

export const convertRecordsToCsv = (
  records: Record<string, any>[],
  fields: Field[],
  isTruncated = false,
) => {
  records = flattenNestedList(records);

  const firstRecord = first(records);
  if (firstRecord === undefined) {
    return '';
  }

  const headerLine = fields.map((field) => `"${field.name}"`).join(',');

  const valueLines = records.map((record) =>
    fields
      .map(({ key, type }) => {
        const value = record[key];

        switch (type) {
          case 'Object':
          case 'Array':
            return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
          case 'Date':
          case 'Enum':
          case 'String':
            return `"${value}"`;
          default:
            return value;
        }
      })
      .join(','),
  );

  return [
    ...(isTruncated
      ? [
          `"⚠️ Warning: This result set contains the first ${formatNumber(records.length)} records."`,
        ]
      : []),
    headerLine,
    ...valueLines,
  ].join('\n');
};
