import { useState, useCallback, createContext, useContext } from 'react';

import { useKeyPress } from '@/lib/hooks/use-key-press';

import { DropdownMenuItem } from '../dropdown';

import { ContextMenu } from '.';

import styles from '../dropdown/dropdown.module.scss';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ContextMenuContext = createContext({ openContextMenu: (menu: ContextMenu) => {} });

export const useContextMenu = () => useContext(ContextMenuContext);

interface ContextMenu {
  position: { x: number; y: number };
  items: DropdownMenuItem[];
  onOpen?: () => void;
  onClose?: () => void;
}

export function ContextMenuContextProvider({ children }: { children: React.ReactNode }) {
  const [menus, setMenus] = useState<ContextMenu[]>([]);
  const menuOpen: boolean = menus.length > 0;

  const openContextMenu = useCallback(
    (menu: ContextMenu) => {
      menu.onOpen?.();
      setMenus((menus) => [...menus, menu]);
    },
    [setMenus],
  );

  const closeContextMenu = (idx: number) => {
    const menu = menus[idx];
    menu.onClose?.();
    setMenus((menus) => menus.filter((_, i) => i !== idx));
  };

  useKeyPress(
    'Escape',
    (e: KeyboardEvent) => {
      if (menuOpen) {
        e.preventDefault();
        e.stopPropagation();
        closeContextMenu(0);
      }
    },
    { capture: true },
  );

  return (
    <ContextMenuContext.Provider value={{ openContextMenu }}>
      {menus.map(({ items, position: { x, y } }, idx) => (
        <div key={idx} className={styles.backdrop} onClick={() => closeContextMenu(idx)}>
          <ContextMenu items={items} x={x} y={y} />
        </div>
      ))}
      {children}
    </ContextMenuContext.Provider>
  );
}
