import { useEffect } from 'react';
import classNames from 'classnames';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Image from '@tiptap/extension-image';
import Heading from '@tiptap/extension-heading';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Horizontalrule from '@tiptap/extension-horizontal-rule';
import Blockquote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import History from '@tiptap/extension-history';
import Placeholder from '@tiptap/extension-placeholder';
import Gapcursor from '@tiptap/extension-gapcursor';
import { useEditor, EditorContent } from '@tiptap/react';

import { TextCell } from '@/explore/types';
import { Icon } from '@/components/icon';

import { useExplorationCellContext } from '../exploration-cell-context';
import { useExplorationContext } from '../exploration-context';
import { CellControls } from '../cell-controls';
import { PopupMenu } from './popup-menu';

import styles from './text-cell.module.scss';
import explorationStyles from '../exploration.module.scss';

interface TextCellViewProps {
  cell: TextCell;
  selected: boolean;
  onSelectCell?: () => void;
  onSetDraggable: (value: boolean) => void;
}

const extensions = [
  Document,
  Paragraph,
  Text,
  Image,
  Heading.configure({ levels: [1, 2, 3] }),
  Bold,
  Italic,
  Underline,
  Link.configure({ openOnClick: false, protocols: ['http', 'https'], defaultProtocol: 'https' }),
  Horizontalrule,
  Blockquote,
  BulletList,
  OrderedList,
  ListItem,
  Code,
  CodeBlock,
  Placeholder.configure({ placeholder: 'Text...' }),
  Gapcursor,
  History,
];

const convertContent = (cell: { title?: string | null; content?: string | null }) => {
  return (cell.title?.length ?? 0) > 0
    ? `<h1>${cell.title}</h1>${cell.content}`
    : (cell.content ?? '');
};

export const TextCellView = (props: TextCellViewProps) => {
  const { cell } = props;

  const { exploration } = useExplorationContext();
  const { setCell, isSelectedCell, isConversationCell } = useExplorationCellContext();

  const editor = useEditor({ extensions, content: convertContent(cell) });

  useEffect(() => {
    const editorContent = editor?.getHTML();

    if (editor !== null && cell.content !== editorContent) {
      editor.commands.setContent(convertContent(cell));
    }
  }, [cell, editor]);

  useEffect(() => {
    if (isSelectedCell && !(editor?.view.hasFocus() ?? false)) {
      editor?.view.focus();
    }
  }, [isSelectedCell, editor?.view]);

  const handleEditorBlur = () => {
    if (editor === null) {
      return;
    }

    const content = editor.getHTML();

    if (cell.content !== content) {
      setCell({ ...cell, content, title: undefined });
    }
  };

  return (
    <div className={styles.textCell} onClick={props.onSelectCell}>
      {!isConversationCell && (
        <Icon
          name="DragHandle"
          size={10}
          className={classNames(explorationStyles.dragHandle, styles.dragHandle)}
          onMouseOver={() => props.onSetDraggable(true)}
          onMouseOut={() => props.onSetDraggable(false)}
        />
      )}

      {/*
        div here is necessary for PopupMenu keyboard navigation support
        Read more: https://atomiks.github.io/tippyjs/v6/accessibility/#interactivity
      */}
      <div>
        <EditorContent
          editor={editor}
          onFocus={props.onSelectCell}
          onBlur={handleEditorBlur}
          onClick={(e) => {
            const linkElement = (e.target as HTMLElement).closest('a');
            if (linkElement) {
              editor?.chain().extendMarkRange('link').run();
            }
          }}
        />
        <PopupMenu editor={editor} />
      </div>

      <div className={styles.controls}>
        <CellControls exploration={exploration} />
      </div>
    </div>
  );
};
