import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { BubbleMenu, Editor } from '@tiptap/react';

import { useKeyPress } from '@/lib/hooks/use-key-press';
import { Icon } from '@/components/icon';
import { Tooltip } from '@/components/tooltip';
import { ShortcutIndicator } from '@/components/shortcut-indicator';

import styles from './text-cell.module.scss';

interface PopupMenuProps {
  editor: Editor | null;
}

export const PopupMenu = ({ editor }: PopupMenuProps) => {
  const [isLinkMenuOpen, setIsLinkMenuOpen] = useState(false);
  const showLinkMenu = (editor?.isActive('link') ?? false) || isLinkMenuOpen;

  useKeyPress(
    ['k', 'meta'],
    (event) => {
      event.preventDefault();
      setIsLinkMenuOpen(true);
    },
    { capture: true, includeInputs: true },
  );

  if (editor === null) {
    return <></>;
  }

  return (
    <BubbleMenu editor={editor} tippyOptions={{ onHide: () => setIsLinkMenuOpen(false) }}>
      {showLinkMenu ? (
        <LinkMenu editor={editor} hideLinkMenu={() => setIsLinkMenuOpen(false)} />
      ) : (
        <TextMenu editor={editor} showLinkMenu={() => setIsLinkMenuOpen(true)} />
      )}
    </BubbleMenu>
  );
};

interface TextMenuProps {
  editor: Editor;
  showLinkMenu: () => void;
}

const TextMenu = ({ editor, showLinkMenu }: TextMenuProps) => (
  <div className={styles.popupMenu} onMouseDown={(event) => event.preventDefault()}>
    <Tooltip
      content={
        <>
          Bold <ShortcutIndicator shortcut={['cmd', 'B']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={classNames({ [styles.active]: editor.isActive('bold') })}>
        <Icon name="Bold" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Italic <ShortcutIndicator shortcut={['cmd', 'I']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={classNames({ [styles.active]: editor.isActive('italic') })}>
        <Icon name="Italic" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Underline <ShortcutIndicator shortcut={['cmd', 'U']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={classNames({ [styles.active]: editor.isActive('underline') })}>
        <Icon name="Underline" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Link <ShortcutIndicator shortcut={['cmd', 'K']} size="small" />
        </>
      }>
      <button onClick={() => showLinkMenu()}>
        <Icon name="Link" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Quote <ShortcutIndicator shortcut={['cmd', 'shift', 'B']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={classNames({ [styles.active]: editor.isActive('blockquote') })}>
        <Icon name="Quote" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Inline code snippet <ShortcutIndicator shortcut={['cmd', 'E']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={classNames({ [styles.active]: editor.isActive('code') })}>
        <Icon name="CodeInline" size={16} />
      </button>
    </Tooltip>
    <Tooltip
      content={
        <>
          Code block <ShortcutIndicator shortcut={['cmd', 'alt', 'C']} size="small" />
        </>
      }>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={classNames({ [styles.active]: editor.isActive('codeblock') })}>
        <Icon name="Code" size={16} />
      </button>
    </Tooltip>
  </div>
);

interface LinkMenuProps {
  editor: Editor;
  hideLinkMenu: () => void;
}

const LinkMenu = ({ editor, hideLinkMenu }: LinkMenuProps) => {
  const [linkUrl, setLinkUrl] = useState<string>('');
  const urlInputRef = useRef<HTMLInputElement>(null);

  const href = editor?.getAttributes('link').href;

  useEffect(() => setLinkUrl(href ?? ''), [href]);

  useEffect(() => urlInputRef.current?.focus(), []);

  useKeyPress(
    'escape',
    () => {
      setLinkUrl('');
      hideLinkMenu();
    },
    { capture: true, includeInputs: true },
  );

  if (editor === null) {
    return <></>;
  }

  const handleUrlInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    saveLink();
  };

  const saveLink = () => {
    if (linkUrl === '') {
      return;
    }

    const href =
      linkUrl.startsWith('http://') || linkUrl.startsWith('https://')
        ? linkUrl
        : `https://${linkUrl}`;

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href })
      .setTextSelection(editor.view.state.selection.to)
      .run();

    setLinkUrl('');
    hideLinkMenu();
  };

  return (
    <div className={styles.popupMenu}>
      <input
        type="text"
        value={linkUrl}
        onChange={(event) => setLinkUrl(event.target.value)}
        onKeyDown={handleUrlInputKeyDown}
        className={styles.urlInput}
        ref={urlInputRef}
      />
      <Tooltip content="Open in new window">
        <button onClick={() => window.open(editor.getAttributes('link').href, '_blank')}>
          <Icon name="ExternalLink" size={16} />
        </button>
      </Tooltip>
      <Tooltip content="Remove link">
        <button onClick={() => editor.chain().focus().unsetLink().run()}>
          <Icon name="Trash" size={16} />
        </button>
      </Tooltip>
    </div>
  );
};
