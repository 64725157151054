import { Option, SelectOption } from '@/components/form/types';
import { getOperationPropertyKeys } from '@/explore/pipeline/operation';

import { isCellWithPipeline, replaceCell, setCellPipeline } from '@/core/cell';
import { getCell } from '@/explore/exploration/utils';

import {
  AddRelatedColumnOperation,
  Exploration,
  Field,
  PipelineOperation,
  RelationAggregateOperation,
  Pipeline,
  DereferencedPipeline,
  Property,
} from '../../types';
import { dereferencePipeline, FieldGroup, isFieldGroup } from '../../pipeline/utils';
import { getAllPipelineFields, PipelineStateContext } from '../../pipeline/state';
import { getIconForFieldType } from '../../model/utils';
import {
  ensureValidPipeline,
  getCellPipeline,
  restoreInvalidCell,
  restoreInvalidOperation,
} from '../../utils';

export const ensureLegalIdentifier = (name: string) => {
  return name.replace(/[^a-zA-Z0-9_]/g, '_').replace(/^([^a-zA-Z])/, '_$1');
};

/**
 * Make names safe-ish as column keys
 */
export const nameToKey = (name: string) => {
  return ensureLegalIdentifier(name).toLowerCase();
};

/**
 * Retain property key if it is set to avoid breaking references.
 */
export const getPropertyKey = (property: Property, propertyName: string) =>
  property.key !== '' ? property.key : nameToKey(propertyName);

export const fieldToOption = (field: Field): Option => {
  return { label: field.name, value: field.key, icon: getIconForFieldType(field.type) };
};

export const fieldToOptionGrouped = (field: Field | FieldGroup): SelectOption => {
  if (isFieldGroup(field)) {
    return {
      value: field.key,
      label: field.name ?? '',
      options: field.fields.map(fieldToOption),
    };
  }
  return { label: field.name, value: field.key, icon: getIconForFieldType(field.type) };
};

export const isRecursiveOperation = (
  operation: PipelineOperation,
): operation is AddRelatedColumnOperation | RelationAggregateOperation =>
  operation.operation === 'addRelatedColumn' || operation.operation === 'relationAggregate';

export const replacePipeline = (
  exploration: Exploration,
  cellId: string,
  pipeline: Pipeline,
): Exploration => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined) {
    throw new Error('Cell not found');
  }
  const restoredCell = restoreInvalidCell(cell);
  if (!isCellWithPipeline(restoredCell)) {
    throw new Error('Attempting to update an operation on a cell without a pipeline');
  }
  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: replaceCell(exploration.view.cells, setCellPipeline(restoredCell, pipeline)),
    },
  };
};

export const replaceOperations = (
  exploration: Exploration,
  cellId: string,
  operations: PipelineOperation[],
) => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined) {
    throw new Error('Cell not found');
  }
  const pipeline = getCellPipeline(cell);
  if (pipeline === undefined) {
    throw new Error('Attempting to update an operation on a cell without a pipeline');
  }
  return replacePipeline(exploration, cell.id, { ...pipeline, operations });
};

const applyOperationToPipeline = (
  exploration: Exploration,
  cellId: string,
  operationIndex: number,
  operation: PipelineOperation,
  ctx: PipelineStateContext,
  replace?: boolean,
): Exploration => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined) {
    throw new Error('Cell not found');
  }
  const pipeline = getCellPipeline(cell);
  if (pipeline === undefined) {
    throw new Error('Attempting to update an operation on a cell without a pipeline');
  }

  const dereferencedPipeline = dereferencePipeline(
    ensureValidPipeline(pipeline, {
      models: ctx.models,
      metrics: ctx.metrics,
      variables: ctx.variables,
      exploration,
    }),
    exploration,
  );

  operation = ensureUniquePropertyKeysInOperation(
    restoreInvalidOperation(operation),
    dereferencedPipeline,
    ctx,
    replace === true ? pipeline.operations.at(operationIndex) : undefined,
  );

  const operations = pipeline.operations;
  const before = operations.slice(0, operationIndex);
  const after = operations.slice(operationIndex + (replace === true ? 1 : 0));
  return replaceOperations(exploration, cell.id, [...before, operation, ...after]);
};

export const addOperation = (
  exploration: Exploration,
  cellId: string,
  operationIndex: number,
  operation: PipelineOperation,
  ctx: PipelineStateContext,
): Exploration => {
  return applyOperationToPipeline(exploration, cellId, operationIndex, operation, ctx);
};

export const addOperations = (
  exploration: Exploration,
  cellId: string,
  operationIndex: number,
  operations: PipelineOperation[],
  ctx: PipelineStateContext,
): Exploration => {
  return operations.reduce(
    (exploration, operation, idx) =>
      addOperation(exploration, cellId, operationIndex + idx, operation, ctx),
    exploration,
  );
};

export const updateOperation = (
  exploration: Exploration,
  cellId: string,
  operationIndex: number,
  operation: PipelineOperation,
  ctx: PipelineStateContext,
): Exploration => {
  return applyOperationToPipeline(exploration, cellId, operationIndex, operation, ctx, true);
};

export const removeOperation = (
  exploration: Exploration,
  cellId: string,
  operationIndex: number,
): Exploration => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined) {
    throw new Error('Cell not found');
  }
  const pipeline = getCellPipeline(cell);
  if (pipeline === undefined) {
    throw new Error('Attempting to remove an operation from a cell without a pipeline');
  }

  return replaceOperations(exploration, cellId, [
    ...pipeline.operations.slice(0, operationIndex),
    ...pipeline.operations.slice(operationIndex + 1),
  ]);
};

export const moveOperation = (
  exploration: Exploration,
  cellIndex: number,
  fromIndex: number,
  toIndex: number,
): Exploration => {
  const cell = exploration.view.cells[cellIndex];
  if (!('pipeline' in cell)) {
    throw new Error('Attempting to remove an operation from a cell without a pipeline');
  }

  const tempPipeline = [
    ...cell.pipeline.operations.slice(0, fromIndex),
    ...cell.pipeline.operations.slice(fromIndex + 1),
  ];

  return replaceOperations(exploration, cell.id, [
    ...tempPipeline.slice(0, toIndex),
    cell.pipeline.operations[fromIndex],
    ...tempPipeline.slice(toIndex),
  ]);
};

// Change the key argument so it does not conflict with existing keys in the fields.
export const ensureUniqueFieldKey = (fields: { key: string }[], key: string) =>
  ensureUniqueKey(
    fields.map((field) => field.key),
    key,
  );

/**
 * Ensure that the key is unique among the existing keys.
 */
export const ensureUniqueKey = (existingKeys: string[], key: string) => {
  let result = key;
  let iteration = 0;

  while (existingKeys.includes(result) && iteration < 1000) {
    result = `${key}_${iteration}`;
    iteration += 1;
  }

  return result;
};

export const ensureUniquePropertyKeysInOperation = <T extends PipelineOperation>(
  operation: T,
  pipeline: DereferencedPipeline,
  ctx: PipelineStateContext,
  previousOperation?: T,
): T => {
  let keys = getOperationPropertyKeys(operation);
  if (keys.length === 0) {
    return operation;
  }

  const ownKeys =
    previousOperation !== undefined ? getOperationPropertyKeys(previousOperation) : [];

  const existingKeys = getAllPipelineFields(pipeline, ctx).map((field) => field.key);

  let iteration = 0;
  let conflictingKeys = keys.filter((key) => !ownKeys.includes(key) && existingKeys.includes(key));
  while ((conflictingKeys.length > 0 || hasDuplicates(keys)) && ++iteration < 1000) {
    operation = deduplicateOperationPropertyKeys(operation, conflictingKeys);
    keys = getOperationPropertyKeys(operation);
    conflictingKeys = keys.filter((key) => !ownKeys.includes(key) && existingKeys.includes(key));
  }
  return operation;
};

const hasDuplicates = (keys: string[]) => new Set(keys).size !== keys.length;

export const deduplicateOperationPropertyKeys = <T extends PipelineOperation>(
  operation: T,
  fields: string[],
): T => {
  switch (operation.operation) {
    case 'addRelatedColumn':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          columns: ensureUniquePropertyKeys(operation.parameters.columns, fields),
        },
      };
    case 'groupAggregate':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          aggregations: ensureUniquePropertyKeys(operation.parameters.aggregations, fields),
        },
      };
    case 'relationAggregate':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          aggregations: ensureUniquePropertyKeys(operation.parameters.aggregations, fields),
        },
      };
    case 'deriveField':
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          key: ensureUniqueKey(fields, operation.parameters.key),
        },
      };
  }
  return operation;
};

const ensureUniquePropertyKeys = <T extends { property: { key: string } }>(
  aggregations: T[],
  fields: string[],
): T[] =>
  aggregations.reduce<T[]>((aggregations, aggregation) => {
    const keys = fields.concat(aggregations.map((agg) => agg.property.key));
    return [
      ...aggregations,
      {
        ...aggregation,
        property: {
          ...aggregation.property,
          key: ensureUniqueKey(keys, aggregation.property.key),
        },
      },
    ];
  }, []);
