export const Quote = ({
  size = 12,
  color = 'currentColor',
  className,
}: {
  size?: number;
  color?: string;
  className?: string;
}) => (
  <svg
    fill="none"
    stroke={color}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    className={className}>
    <circle cx="7" cy="9.5" r="3" strokeWidth="2" />
    <path strokeLinecap="round" strokeWidth="2" d="m7 17.5 2.781-6.875" />
    <circle cx="17" cy="9.5" r="3" strokeWidth="2" />
    <path strokeLinecap="round" strokeWidth="2" d="m17 17.5 2.781-6.875" />
  </svg>
);
