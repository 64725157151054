import classNames from 'classnames';
import { forwardRef } from 'react';

import { TextArea, TextAreaProps } from '.';
import { FormInputSize } from '../types';

import styles from './textarea.module.scss';

interface AutoResizeTextAreaProps extends TextAreaProps {
  size?: FormInputSize | 'extraLarge';
  color?: 'regular' | 'gray';
  transparent?: boolean;
}

export const AutoResizeTextArea = forwardRef(function AutoResizeTextArea(
  props: AutoResizeTextAreaProps,
  ref?: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const { size = 'small', color = 'regular', transparent = false, ...rest } = props;
  return (
    <div
      className={classNames(styles.autoResizeWrapper, {
        [styles.sizeSmall]: size === 'small',
        [styles.sizeMedium]: size === 'regular',
        [styles.sizeLarge]: size === 'large',
        [styles.sizeExtraLarge]: size === 'extraLarge',
        [styles.gray]: color === 'gray',
        [styles.transparent]: transparent,
      })}
      data-replicated-value={props.value}>
      <TextArea size={size} {...rest} ref={ref} />
    </div>
  );
});
