import { ReferenceBadge } from '@/components/badge';

import type { VariableDefinition } from '@/explore/types';
import { getVariableColor, getVariableKeyFromExpression } from '@/explore/utils';
import { variableAlternateColors, variableColors } from '@/explore/components/variable-color';

interface VariableReferenceBadgeProps {
  value: string;
  variables: VariableDefinition[];
  onRemove: () => void;
}

export const VariableReferenceBadge = (props: VariableReferenceBadgeProps) => {
  const { value, variables, onRemove } = props;
  const key = getVariableKeyFromExpression(value) ?? '';
  const variableExists = variables.some((variable) => variable.key === key);
  return (
    <ReferenceBadge
      label={variableExists ? key : `❗ Missing variable: ${key}`}
      iconName={variableExists ? 'VariableInstance' : undefined}
      textColor={getVariableColor(key, variables, variableColors)}
      bgColor={getVariableColor(key, variables, variableAlternateColors)}
      onRemove={onRemove}
    />
  );
};
