import { findLastIndex } from 'lodash';

import { Cell } from '@/explore/types';
import { isChatCell } from '@/core/cell';

export function discardClearsView(cells: Cell[], index: number) {
  if (index !== 0) {
    return false;
  }

  const firstCell = cells[0];
  if (!isChatCell(firstCell)) {
    return false;
  }

  return findLastIndex(firstCell.messages, (msg) => msg.role === 'user') === 0;
}
