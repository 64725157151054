import { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { isNil } from 'lodash';

import { notNil } from '@/lib/utils';
import { useToastContext } from '@/components/toast';
import { useBuildAccountUrl } from '@/lib/accounts/context';

import { buildExplorationHashUrl } from '../utils/url';
import { buildExplorationFromCells } from '../exploration/utils';
import { Cell, CopiableCell } from '../types';

export const CopiedCellKey = 'supCopiedCell';

export interface CopiedCell {
  cell: CopiableCell;
  dependencies: Cell[];
}

export const usePersistCell = () => {
  const buildAccountUrl = useBuildAccountUrl();
  const [copiedCellValue, setCopiedCell, removeCopiedCell] = useLocalStorage(CopiedCellKey, '');
  const addToast = useToastContext();
  const copiedCell = useMemo(() => {
    try {
      if (copiedCellValue?.length) {
        return JSON.parse(copiedCellValue) as CopiedCell;
      }
      return undefined;
    } catch {
      return undefined;
    }
  }, [copiedCellValue]);

  const hasCopiedCell = useMemo(() => notNil(copiedCell), [copiedCell]);

  const persistCopiedCell = (cell: { cell: Cell; dependencies: Cell[] }) => {
    setCopiedCell(JSON.stringify(cell));

    addToast({
      title: 'Cell copied!',
      content: () =>
        'It is temporarily available for pasting into other explorations when adding a new block.',
      kind: 'success',
    });
  };

  const copiedExploration = useMemo(
    () =>
      copiedCell ? buildExplorationFromCells([...copiedCell.dependencies, copiedCell.cell]) : null,
    [copiedCell],
  );

  const copiedExplorationUrl = useMemo(() => {
    if (copiedExploration) {
      return buildAccountUrl(buildExplorationHashUrl(copiedExploration));
    }

    return '';
  }, [copiedExploration, buildAccountUrl]);

  const copiedExplorationTitle = useMemo(() => {
    if (notNil(copiedCell) && 'title' in copiedCell.cell) {
      return copiedCell.cell.title ?? '';
    }

    return '';
  }, [copiedCell]);

  const copiedExplorationPipeline = useMemo(() => {
    if (isNil(copiedCell?.cell)) {
      return null;
    }

    return 'pipeline' in copiedCell.cell ? copiedCell.cell.pipeline : null;
  }, [copiedCell]);

  return {
    copiedCell,
    hasCopiedCell,
    copiedExploration,
    copiedExplorationUrl,
    copiedExplorationTitle,
    copiedExplorationPipeline,
    persistCopiedCell,
    removeCopiedCell,
  };
};
