import { cloneDeep, nth, omit } from 'lodash';

import {
  AddRelatedColumnOperation,
  BasePipeline,
  Cell,
  Exploration,
  JoinPipelineOperation,
  Metric,
  Model,
  QueryVariables,
  RelationAggregateOperation,
} from '@/explore/types';
import {
  dereferencePipeline,
  flattenPipeline,
  generatePipelineId,
  removeReferenceToPipeline,
} from '@/explore/pipeline/utils';
import { InvalidPipelineReferenceError, generateExplorationId } from '@/explore/utils';

import { getFinalState } from '@/explore/pipeline/state';
import {
  CellWithPipeline,
  generateCellId,
  insertCellAfter,
  isCellWithPipeline,
  isCellWithTitle,
  isReplicableCell,
  isSqlCell,
  ReplicableCell,
  setCellPipeline,
} from '@/core/cell';
import { addCells, addCellsAt, replaceCells } from '@/core/exploration';
import { createPipelineWithParent, isCombinePipeline, isPipelineWithParent } from '@/core/pipeline';
import { unlessNil } from '@/lib/utils';

import { ensureValidLayout } from '../exploration-layout/utils';

export const prepareExplorationCellsForImport = (cells: Cell[]) => {
  const pipelineIdsToReplace = getPipelineIdsInCells(cells);
  const cellsWithNewPipelineIds = pipelineIdsToReplace.reduce(
    (acc, pipelineId) => replacePipelineIdInCells(acc, pipelineId, generatePipelineId()),
    cells,
  );

  return cellsWithNewPipelineIds.map((cell) => ({
    ...cell,
    id: generateCellId(),
    ...(isCellWithPipeline(cell) ? { pipeline: cell.pipeline } : {}),
    viewOptions: omit(cell.viewOptions, 'rowId'),
  }));
};

export const importExplorationCellsToExploration = (
  exploration: Exploration,
  cells: Cell[],
  index = 0,
) => {
  const cellsWithNewPipelineIds = prepareExplorationCellsForImport(cells);
  return {
    exploration: addCellsAt(exploration, cellsWithNewPipelineIds, index),
    cells: cellsWithNewPipelineIds,
  };
};

export const importExplorationCellsAfter = (
  exploration: Exploration,
  cells: Cell[],
  cellId: string,
) => {
  const cellsWithNewPipelineIds = prepareExplorationCellsForImport(cells);
  return {
    exploration: addCells(exploration, prepareExplorationCellsForImport(cells), cellId),
    cells: cellsWithNewPipelineIds,
  };
};

export const duplicateExplorationCell = (
  exploration: Exploration,
  cellId: string,
): { exploration: Exploration; cell?: Cell } => {
  const cells = exploration.view.cells;
  const cell = getCell(cellId, exploration);

  if (cell === undefined) {
    return { exploration };
  }

  if (isCellWithPipeline(cell)) {
    const cellCopy = duplicateCell(cell);

    return {
      exploration: replaceCells(
        exploration,
        ensureValidLayout(insertCellAfter(cells, cellCopy, cell.id)),
      ),
      cell: cellCopy,
    };
  }

  const cellCopy = { ...cloneDeep(cell), id: generateCellId() };

  return {
    exploration: replaceCells(exploration, insertCellAfter(cells, cellCopy, cell.id)),
    cell: cellCopy,
  };
};

export const createExplorationCellInstance = (
  exploration: Exploration,
  cellId: string,
): { exploration: Exploration; cell?: Cell } => {
  const cell = getCell(cellId, exploration);

  if (cell === undefined || !isCellWithPipeline(cell)) {
    return { exploration };
  }

  const copy = {
    ...omit(cloneDeep(cell), 'visualisations'),
    id: generateCellId(),
    kind: isSqlCell(cell) ? 'records' : cell.kind,
    title: `Instance of ${cell.title}`,
    pipeline: createPipelineWithParent({ parentId: cell.pipeline.pipelineId, operations: [] }),
    ...(unlessNil(cell.viewOptions, (opts) => ({
      viewOptions: omit(cloneDeep(opts), 'tableVisibility'),
    })) ?? {}),
  };

  const cells = cloneDeep(exploration.view.cells);

  return {
    exploration: replaceCells(exploration, ensureValidLayout(insertCellAfter(cells, copy, cellId))),
    cell: copy,
  };
};

export const deleteExplorationCellById = (
  exploration: Exploration,
  cellId: string,
): Exploration => {
  const deletedCell = getCell(cellId, exploration);

  if (deletedCell === undefined) {
    throw new Error(`Could not find cell with id ${cellId}`);
  }

  const cells = exploration.view.cells;
  const updatedCells = cells
    .filter((cell) => cell.id !== cellId)
    .map((cell) => {
      if (!isCellWithPipeline(cell) || !isCellWithPipeline(deletedCell)) {
        return cell;
      }

      return setCellPipeline(cell, removeReferenceToPipeline(cell.pipeline, deletedCell.pipeline));
    });

  return replaceCells(exploration, updatedCells);
};

export const getCell = (cellId: string, exploration: Exploration) =>
  exploration.view.cells.find((cell) => cell.id === cellId);

export const getCellByIndex = (index: number, exploration: Exploration) =>
  nth(exploration.view.cells, index);

export const getCellIndex = (cellId: string, exploration: Exploration) =>
  exploration.view.cells.findIndex((cell) => cell.id === cellId);

export const getCellByPipelineId = (
  pipelineId: string,
  exploration: Exploration,
): CellWithPipeline | undefined =>
  exploration.view.cells
    .filter(isCellWithPipeline)
    .find((cell) => cell.pipeline.pipelineId === pipelineId);

export const getCellByPipelineIdOrThrow = (pipelineId: string, exploration: Exploration) => {
  const cell = getCellByPipelineId(pipelineId, exploration);
  if (cell === undefined) {
    throw new InvalidPipelineReferenceError(`Could not find cell with pipeline id ${pipelineId}`);
  }
  return cell;
};

export const getPipelineById = (pipelineId: string, exploration: Exploration) => {
  const cell = getCellByPipelineId(pipelineId, exploration);

  if (cell === undefined) {
    throw new InvalidPipelineReferenceError(`Could not find pipeline with id ${pipelineId}`);
  }

  return cell.pipeline;
};

export const getFieldsByCellId = (
  cellId: string,
  exploration: Exploration,
  models: Model[],
  variables: QueryVariables,
  metrics: Metric[],
) => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined || !('pipeline' in cell)) {
    return [];
  }
  const { baseModelId, operations } = dereferencePipeline(cell.pipeline, exploration);
  return getFinalState(baseModelId, operations, { models, variables, metrics }).fields;
};

const getReplicableExplorationCells = (exploration: Exploration): ReplicableCell[] =>
  exploration.view.cells.filter(isReplicableCell);

export const explorationHasReplicableCells = (exploration: Exploration) =>
  getReplicableExplorationCells(exploration).length > 0;

export const duplicateCell = (cell: Cell): Cell => ({
  ...cloneDeep(cell),
  id: generateCellId(),
  ...(isCellWithTitle(cell) ? { title: `Copy of ${cell.title}` } : {}),
  ...(isCellWithPipeline(cell)
    ? { pipeline: { ...cloneDeep(cell.pipeline), pipelineId: generatePipelineId() } }
    : {}),
});

export const cloneCell = (cell: Cell): Cell => ({
  ...cloneDeep(cell),
  id: generateCellId(),
  ...(isCellWithPipeline(cell)
    ? { pipeline: createPipelineWithParent({ parentId: cell.pipeline.pipelineId, operations: [] }) }
    : {}),
});

const replacePipelineIdInAddRelatedColumnOperation = (
  operation: AddRelatedColumnOperation,
  oldId: string,
  newId: string,
) =>
  'pipelineId' in operation.parameters && operation.parameters.pipelineId === oldId
    ? { ...operation, parameters: { ...operation.parameters, pipelineId: newId } }
    : operation;

const replacePipelineIdInJoinPipelineOperation = (
  operation: JoinPipelineOperation,
  oldId: string,
  newId: string,
) =>
  'parentId' in operation.parameters.pipeline && operation.parameters.pipeline.parentId === oldId
    ? {
        ...operation,
        parameters: {
          ...operation.parameters,
          pipeline: { ...operation.parameters.pipeline, parentId: newId },
        },
      }
    : operation;

const replacePipelineIdInRelationAggregateOperation = (
  operation: RelationAggregateOperation,
  oldId: string,
  newId: string,
) =>
  'pipelineId' in operation.parameters && operation.parameters.pipelineId === oldId
    ? { ...operation, parameters: { ...operation.parameters, pipelineId: newId } }
    : operation;

const replacePipelineIdInCells = (cells: Cell[], oldId: string, newId: string): Cell[] =>
  cells.map((cell) => replacePipelineIdInCell(cell, oldId, newId));

const replacePipelineIdInCell = (cell: Cell, oldId: string, newId: string): Cell => {
  if (!isCellWithPipeline(cell)) {
    return cell;
  }

  return {
    ...cell,
    pipeline: {
      ...cell.pipeline,
      pipelineId: cell.pipeline.pipelineId === oldId ? newId : cell.pipeline.pipelineId,
      ...(isPipelineWithParent(cell.pipeline) && cell.pipeline.parentId === oldId
        ? { parentId: newId }
        : {}),
      operations: cell.pipeline.operations.map((operation) => {
        switch (operation.operation) {
          case 'addRelatedColumn':
            return replacePipelineIdInAddRelatedColumnOperation(operation, oldId, newId);
          case 'joinPipeline':
            return replacePipelineIdInJoinPipelineOperation(operation, oldId, newId);
          case 'relationAggregate':
            return replacePipelineIdInRelationAggregateOperation(operation, oldId, newId);
          default:
            return operation;
        }
      }),
    },
  };
};

const getPipelineReferencesByOperations = (operations: BasePipeline['operations']) => {
  const pipelineIds = new Set<string>();

  operations.forEach((operation) => {
    switch (operation.operation) {
      case 'addRelatedColumn':
        if ('pipelineId' in operation.parameters && operation.parameters.pipelineId !== undefined) {
          pipelineIds.add(operation.parameters.pipelineId);
        }
        break;
      case 'joinPipeline':
        if (
          'parentId' in operation.parameters.pipeline &&
          operation.parameters.pipeline.parentId !== undefined
        ) {
          pipelineIds.add(operation.parameters.pipeline.parentId);
        }
        break;
      case 'relationAggregate':
        if ('pipelineId' in operation.parameters && operation.parameters.pipelineId !== undefined) {
          pipelineIds.add(operation.parameters.pipelineId);
        }
        break;
    }
  });

  return Array.from(pipelineIds);
};

const getPipelineIdsInCells = (cells: Cell[]) =>
  cells.reduce<string[]>(
    (acc, cell) => (isCellWithPipeline(cell) ? [...acc, cell.pipeline.pipelineId] : acc),
    [],
  );

const extractCellWithDependencies = (
  cell: Cell,
  exploration: Exploration,
): { cell: Cell; dependencies: Cell[] } => {
  if (!isCellWithPipeline(cell)) {
    return { cell, dependencies: [] };
  }

  // If the pipeline is a join pipeline, we do not flatten it
  const pipeline = isCombinePipeline(cell.pipeline)
    ? cell.pipeline
    : flattenPipeline(cell.pipeline, exploration);

  const referencedPipelineIds = getPipelineReferencesByOperations(pipeline.operations);
  // If pipeline still references parent pipeline, include it in the dependencies
  if (isPipelineWithParent(pipeline)) {
    referencedPipelineIds.unshift(pipeline.parentId);
  }

  const dependencies = referencedPipelineIds.reduce<Cell[]>((cells, pipelineId) => {
    const { cell, dependencies } = extractCellWithDependencies(
      getCellByPipelineIdOrThrow(pipelineId, exploration),
      exploration,
    );
    return [...cells, cell, ...dependencies];
  }, []);

  return { cell: { ...cell, pipeline }, dependencies: dependencies };
};

export const extractCellFromExploration = (
  cellId: string,
  exploration: Exploration,
): { cell: Cell; dependencies: Cell[] } => {
  const cell = getCell(cellId, exploration);

  if (cell === undefined) {
    throw new Error(`Could not find cell with id ${cellId}`);
  }

  if (isCellWithPipeline(cell)) {
    return cloneDeep(extractCellWithDependencies(cell, exploration));
  }

  return { cell: cloneDeep(cell), dependencies: [] };
};

export const buildExplorationFromCells = (cells: Cell[]): Exploration => {
  const firstCell = cells.at(0);
  const name = firstCell !== undefined && isCellWithTitle(firstCell) ? firstCell.title : undefined;

  return {
    explorationId: generateExplorationId(),
    name: name ?? 'Untitled',
    labels: {},
    parameters: [],
    view: { canvas: undefined, cells },
  };
};
