import { memo, MouseEventHandler } from 'react';
import { BarStack as VisxBarStack, BarRounded } from '@visx/shape';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { Text } from '@visx/text';

import { isGroupedTimeSeriesData, SeriesMetaData, TimeSeriesData } from '../grouped-chart/types';
import {
  generateStackedAndGroupedChartDataByKeys,
  generateStackedChartDataByKeys,
  mergeStackedSeries,
} from './utils';

import commonStyles from '../charts.module.scss';

type BarStackProps = {
  data: TimeSeriesData;
  keys: string[];
  group?: string;
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
  offset: number;
  barWidth: number;
  onMouseMove?: MouseEventHandler<Element>;
  onMouseLeave?: MouseEventHandler<Element>;
};

interface StackedTimeSeriesData {
  minValue: number;
  maxValue: number;
  series: SeriesMetaData[];
  items: {
    date: Date;
    groupLabel?: string;
    stackTotal: number;
  }[];
}

const getStackedData = (
  data: TimeSeriesData,
  keys: string[],
  group?: string,
): StackedTimeSeriesData =>
  isGroupedTimeSeriesData(data)
    ? generateStackedAndGroupedChartDataByKeys(data, keys, group ?? '')
    : generateStackedChartDataByKeys(data, keys);

export const BarStack = memo(function BarStack(props: BarStackProps) {
  const { data, keys, group } = props;
  const clipPathId = undefined; // = `clip-path-${getRandomString(6)}`; // TODO: fix SUP-3580

  const mergedData = mergeStackedSeries(data, keys, 'total');
  const stackedData = getStackedData(data, keys, group);

  return (
    <>
      <defs>
        <clipPath id={clipPathId}>
          <BarStackInner
            {...props}
            data={getStackedData(mergedData, ['total'], group)}
            keys={['total']}
          />
        </clipPath>
      </defs>
      <BarStackInner {...props} data={stackedData} clipPathId={clipPathId} />
      <BarStackInnerAxis {...props} data={stackedData} />
    </>
  );
});

type BarStackInnerProps = Omit<BarStackProps, 'data'> & {
  data: StackedTimeSeriesData;
  clipPathId?: string;
};

export const BarStackInner = (props: BarStackInnerProps) => {
  const { data, keys, dateScale, valueScale, clipPathId, barWidth } = props;

  return (
    <VisxBarStack
      keys={keys}
      data={data.items}
      x={(d) => d.date}
      xScale={dateScale}
      yScale={valueScale}
      color={(bar) => data.series.find(({ key }) => key === bar)?.color ?? ''}>
      {(barStacks) =>
        barStacks.map((barStack, barStackIdx) =>
          barStack.bars.map((bar) => (
            <BarRounded
              key={`barstack-${barStack.index}-${bar.index}`}
              radius={5}
              x={dateScale(bar.bar.data.date) - barWidth / 2}
              y={bar.y}
              width={barWidth}
              height={bar.height}
              fill={bar.color}
              top={barStackIdx === barStacks.length - 1}
              clipPath={clipPathId !== undefined ? `url(#${clipPathId})` : undefined}
              onMouseMove={props.onMouseMove}
              onMouseLeave={props.onMouseLeave}
            />
          )),
        )
      }
    </VisxBarStack>
  );
};

type BarStackInnerAxisProps = Omit<BarStackProps, 'data'> & {
  data: StackedTimeSeriesData;
};

export const BarStackInnerAxis = (props: BarStackInnerAxisProps) => {
  const { data, keys, dateScale, valueScale } = props;

  return (
    <VisxBarStack
      keys={keys}
      data={data.items}
      x={(d) => d.date}
      xScale={dateScale}
      yScale={valueScale}
      color={(bar) => data.series.find(({ key }) => key === bar)?.color ?? ''}>
      {(barStacks) =>
        barStacks.map((barStack) =>
          barStack.bars.map((bar) => (
            <Text
              key={`barstackaxis-${barStack.index}-${bar.index}`}
              x={dateScale(bar.bar.data.date)}
              y={valueScale(0) + 5}
              angle={-90}
              fontSize={12}
              className={commonStyles.tickLabel}
              textAnchor="end"
              verticalAnchor="middle"
              width={100}
              scaleToFit="shrink-only">
              {props.group}
            </Text>
          )),
        )
      }
    </VisxBarStack>
  );
};
