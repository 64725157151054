import { useState } from 'react';

import { AlertConfiguration, useDeleteAlertConfigurationMutation } from '@/graphql';
import { getMessage } from '@/lib/error';
import { Metric, Model } from '@/explore/types';

import { Section } from '../common';
import { AlertConfigurationList } from './list';
import { AlertFormModal } from './edit-alert';
import { useToastContext } from '../../components/toast';

import commonStyles from '../common/common.module.scss';

interface AlertsSectionProps {
  accountId: string;
  alertConfigurations: AlertConfiguration[];
  models: Model[];
  metrics: Metric[];
  canEdit: boolean;
  refetch: () => void;
}

export const AlertsSection = (props: AlertsSectionProps) => {
  const { accountId, alertConfigurations, models, metrics, refetch, canEdit } = props;
  const [editableAlert, setEditableAlert] = useState<AlertConfiguration | null>(null);

  const [deleteMutation] = useDeleteAlertConfigurationMutation();

  const addToast = useToastContext();

  const handleDelete = async (id: string, name: string) => {
    if (confirm(`Are you sure you want to delete the Alert "${name}"?`)) {
      try {
        await deleteMutation({
          variables: {
            accountId,
            alertConfigurationId: id,
          },
        });
      } catch (e) {
        addToast({
          title: 'Error',
          content: () => `Deleting Alert failed: ${getMessage(e)}`,
          kind: 'error',
        });
        return;
      }

      addToast({
        title: 'Success',
        content: () => `Alert "${name}" deleted.`,
        kind: 'success',
      });
      refetch();
    }
  };

  const handleEdit = (alerConfiguration: AlertConfiguration) => {
    setEditableAlert(alerConfiguration);
  };

  if (alertConfigurations.length === 0 && !canEdit) {
    return null;
  }

  return (
    <>
      {editableAlert && (
        <AlertFormModal
          ctx={{ models, metrics, variables: [] }}
          alertConfiguration={editableAlert}
          onClose={() => setEditableAlert(null)}
          onAlertSaved={() => {
            setEditableAlert(null);
            refetch();
          }}
          onAlertDeleted={() => {
            setEditableAlert(null);
            refetch();
          }}
        />
      )}
      <Section title="Alerts">
        {alertConfigurations.length === 0 ? (
          <div className={commonStyles.emptyView}>
            No Alerts yet. You can set up new Alerts from the options menu of any exploration block.
          </div>
        ) : (
          <AlertConfigurationList
            alertConfigurations={alertConfigurations}
            onDelete={handleDelete}
            onEdit={handleEdit}
            canEdit={canEdit}
          />
        )}
      </Section>
    </>
  );
};
