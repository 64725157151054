import { entries, isArray, isObject, snakeCase } from 'lodash';

export const convertKeysToSnakeCase = (obj: Record<string, any>): Record<string, any> => {
  if (isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item));
  } else if (isObject(obj)) {
    return Object.keys(obj).reduce<Record<string, any>>((acc, key) => {
      const newKey = snakeCase(key);
      acc[newKey] = convertKeysToSnakeCase(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

export const convertToYaml = (obj: unknown, indent: number = 0): string => {
  const indentSpace = '  '.repeat(indent);

  if (isArray(obj)) {
    return convertArrayToYaml(obj, indent);
  } else if (isObject(obj)) {
    return convertObjectToYaml(obj as Record<string, unknown>, indent);
  }
  return `${indentSpace}${convertValueToYaml(obj)}\n`;
};

const convertObjectToYaml = (obj: Record<string, unknown>, indent: number): string => {
  const indentSpace = '  '.repeat(indent);
  let yamlStr = '';

  for (const [key, value] of entries(obj)) {
    if (isArray(value) && value.length === 0) {
      yamlStr += `${indentSpace}${key}: []\n`;
    } else {
      yamlStr += `${indentSpace}${key}: `;
      if (isObject(value)) {
        yamlStr += `\n${convertToYaml(value, indent + 1)}`;
      } else {
        yamlStr += `${convertValueToYaml(value)}\n`;
      }
    }
  }

  return yamlStr;
};

const convertArrayToYaml = (arr: unknown[], indent: number): string => {
  const indentSpace = '  '.repeat(indent);
  let yamlStr = '';

  if (arr.length === 0) {
    return yamlStr;
  }
  for (const item of arr) {
    yamlStr += `${indentSpace}- ${convertToYaml(item, indent + 1).trimStart()}`;
  }

  return yamlStr;
};

const indicatorRegex = /^[-?:,[\]{}#&*!|>'"%@`]/;

const convertValueToYaml = (value: unknown): string => {
  const valueStr = String(value);
  // Wrap in single quotes if the value is a numeric string or starts with an indicator character
  if ((typeof value === 'string' && !isNaN(Number(value))) || indicatorRegex.test(valueStr)) {
    return `'${value}'`;
  }
  return valueStr;
};
