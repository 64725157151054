import { useState } from 'react';
import classNames from 'classnames';
import { pipeline } from '@gosupersimple/types';

import { Form } from '@/components/form';
import { PenguinoInput, PenguinoVersion } from '@/components/form/penguino-input';
import { Input } from '@/components/form/input';
import { Button, IconButton } from '@/components/button';

import { Tooltip } from '@/components/tooltip';

import { useExplorationContext } from '../exploration/exploration-context';
import type { DeriveFieldOperation, Fields } from '../types';
import { nameToKey } from './utils';

import { useDirtyContext } from '../dirty-context';

import form from '@/components/form/form.module.scss';

interface DeriveFieldFormProps {
  fields: Fields;
  operation?: pipeline.DeriveFieldOperation;
  setOperation(operation: pipeline.DeriveFieldOperation): void;
  onClose(): void;
  onDuplicate?: (operation: DeriveFieldOperation) => void;
}

export const DeriveFieldForm = (props: DeriveFieldFormProps) => {
  const { getVariables } = useExplorationContext();

  const [expression, setExpression] = useState(props.operation?.parameters.value.expression ?? '');
  const [fieldName, setFieldName] = useState(props.operation?.parameters.fieldName ?? '');

  const { setDirty } = useDirtyContext();

  const initialKey = props.operation?.parameters.key ?? '';
  const parameters = {
    fieldName,
    key: initialKey !== '' ? initialKey : nameToKey(fieldName),
    value: { expression, version: props.operation?.parameters.value.version ?? PenguinoVersion },
  };

  const handleFieldNameChange = (fieldName: string) => {
    setDirty(true);

    setFieldName(fieldName);
  };

  const handleExpressionChange = (expression: string) => {
    setDirty(true);

    setExpression(expression);
  };

  const handleSubmit = () => {
    setDirty(false);
    props.setOperation({ operation: 'deriveField', parameters });
  };

  const handleCancel = () => {
    setDirty(false);
    props.onClose();
  };

  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <div className={classNames(form.formRow, form.alignTop)}>
        <div className={form.formRowInner}>
          <PenguinoInput
            value={expression}
            onChange={handleExpressionChange}
            placeholder="Enter custom expression..."
            fields={props.fields}
            variables={getVariables()}
          />
        </div>
      </div>
      <div className={form.formRow}>
        <label className={form.formLabel}>Column name</label>
        <Input
          required
          type="text"
          placeholder="Enter value..."
          value={fieldName}
          onChange={(e) => handleFieldNameChange(e.target.value)}
        />
      </div>

      <div className={form.formControls}>
        <Button size="small" type="submit">
          {props.operation ? 'Save' : 'Add'}
        </Button>
        <Button size="small" variant="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
        {props.onDuplicate !== undefined ? (
          <>
            <div className={form.filler} />
            <Tooltip content="Duplicate step">
              <IconButton
                icon="Copy"
                onClick={() =>
                  props.onDuplicate?.({
                    operation: 'deriveField',
                    parameters,
                  })
                }
              />
            </Tooltip>
          </>
        ) : null}
      </div>
    </Form>
  );
};
