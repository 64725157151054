import styles from './pipeline-model.module.scss';

export const pipelineColors = [
  styles.color1,
  styles.color2,
  styles.color3,
  styles.color4,
  styles.color5,
  styles.color6,
];
