import { first } from 'lodash';
import { ApolloError } from '@apollo/client';

export class TimeoutError extends Error {
  constructor(message = 'Request timed out') {
    super(message);
    this.name = 'TimeoutError';
  }
}

export const isTimeoutError = (error: ApolloError) => {
  if (error.networkError) {
    if ('statusCode' in error.networkError) {
      // Load balancer timeout reached
      return error.networkError.statusCode === 502;
    }
    if (error.networkError.name === 'TimeoutError') {
      return true;
    }
  }

  return first(error.graphQLErrors)?.extensions.code === 'QUERY_TIMEOUT';
};
