import { FirebaseError } from 'firebase/app';
import { signInWithPopup, GoogleAuthProvider, AuthErrorCodes } from 'firebase/auth';

import { auth } from '@/lib/firebase-init';

import { Card } from '../../components/gradient-layout/cards';
import { GoogleButton } from '../../components/google-button';
import { useToastContext } from '../../components/toast';

export const GoogleLogin = () => {
  const provider = new GoogleAuthProvider();
  const addToast = useToastContext();

  provider.setCustomParameters({ prompt: 'select_account' });

  const handleClick = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (e) {
      // Long delay in resolving the error promise is caused by
      // https://github.com/firebase/firebase-js-sdk/issues/8061#issuecomment-2047370693
      if (
        e instanceof FirebaseError &&
        ([AuthErrorCodes.POPUP_CLOSED_BY_USER, AuthErrorCodes.USER_CANCELLED] as string[]).includes(
          e.code,
        )
      ) {
        return;
      }

      addToast({
        title: 'Error',
        content: () => `Something went wrong. Please contact us if the issue persists.`,
        kind: 'error',
      });
    }
  };

  return (
    <Card>
      <GoogleButton title="Continue with Google" onClick={handleClick} />
    </Card>
  );
};
