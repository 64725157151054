import { createContext, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '@/auth/auth-context';
import { useWorkspaceQuery } from '@/graphql';
import { FullPageLoader } from '@/components/loader';

export interface Account {
  accountId: string;
  slug: string;
  name: string;
  timezone?: string | null;
  isOnboarded: boolean;
  features?: Record<string, boolean>;
  navigation: { kind: string; label: string; explorationId: string }[];
  me: { role: { permissions: { permissionId: string }[] } };
}

interface WorkspaceContext {
  accounts: Account[];
  user: { email: string; emailVerified: boolean } | null;
}

const defaultContextValue = Symbol();

const WorkspaceContext = createContext<WorkspaceContext | typeof defaultContextValue>(
  defaultContextValue,
);

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);
  if (context === defaultContextValue) {
    throw new Error('useWorkspaceContext must be used within a WorkspaceContextProvider');
  }
  return context;
};

interface WorkspaceContextProviderProps {
  children: React.ReactNode;
}

export const WorkspaceContextProvider = ({ children }: WorkspaceContextProviderProps) => {
  const { user, loading: authLoading } = useAuth();

  const { data, loading, error } = useWorkspaceQuery({
    skip: user === null || !user.emailVerified,
  });

  if (authLoading || loading) {
    return <FullPageLoader />;
  }

  if (error !== undefined) {
    return <Navigate to="/login" />;
  }

  const workspaceData = {
    accounts: data?.accounts ?? [],
    user:
      data?.me !== undefined
        ? { email: data.me?.email ?? '', emailVerified: user?.emailVerified ?? false }
        : user,
  };

  return <WorkspaceContext.Provider value={workspaceData}>{children}</WorkspaceContext.Provider>;
};
