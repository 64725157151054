import classNames from 'classnames';
import { first } from 'lodash';
import { Fragment } from 'react';

import { InlineButton } from '@/components/button';
import { Icon } from '@/components/icon';
import { getCompositeConditionKeys, isSingleFilterCondition } from '@/explore/pipeline/operation';
import type { CompositeFilterCondition, Field, Fields, VariableDefinition } from '@/explore/types';
import { FieldGroup } from '@/explore/pipeline/utils';

import { useEnsureFieldsExist } from '../hooks/use-ensure-fields-exist';
import { createEmptyFilterCondition } from '../utils/filter';
import { FilterConditionForm } from './filter-condition-form';

import form from '@/components/form/form.module.scss';
import styles from '../pipeline.module.scss';

/**
 * If there is an and-filter on top level, nest it in an empty or-filter.
 * This is purely to display all and-filters as if they are 2nd level filters even if
 * there is no parent filter. This is removed upon submit.
 */
const ensureTopLevelOrFilter = (condition: CompositeFilterCondition): CompositeFilterCondition => {
  if (condition.operator === 'and') {
    return {
      operator: 'or',
      operands: [condition],
    };
  }
  return condition;
};

/**
 * Recursively unwrap filters with a single operand so no unnecessary nesting is created.
 */
const unwrapEmptyFilters = (condition: CompositeFilterCondition): CompositeFilterCondition => {
  if (condition.operator === 'and' || condition.operator === 'or') {
    const operands = condition.operands.map(unwrapEmptyFilters);
    if (operands.length === 1) {
      return operands[0];
    }
    return {
      ...condition,
      operands,
    };
  }
  return condition;
};

interface CompositeFilterConditionFormInnerProps {
  fields: Fields;
  fieldsForExpression: (Field | FieldGroup)[];
  condition: CompositeFilterCondition;
  depth: number;
  canAddOr?: boolean;
  canAddAnd?: boolean;
  canRemove?: boolean;
  setCondition: (condition: CompositeFilterCondition) => void;
  onRemove?: () => void;
  variables: VariableDefinition[];
}

export const CompositeFilterConditionFormInner = (
  props: CompositeFilterConditionFormInnerProps,
) => {
  const {
    fields,
    fieldsForExpression,
    condition,
    setCondition,
    onRemove,
    depth,
    canAddAnd,
    canAddOr,
    canRemove,
    variables,
  } = props;

  const handleAddOperand = (operator: 'and' | 'or') => {
    if (operator === condition.operator) {
      // Extend current condition operands by one
      setCondition({
        ...condition,
        operands: [...condition.operands, createEmptyFilterCondition(first(fields))],
      });
    } else {
      // Nest current condition in new one
      setCondition({
        operator,
        operands: [condition, createEmptyFilterCondition(first(fields))],
      });
    }
  };

  return (
    <div className={classNames(form.formHorizontal, styles.compositeFilter)} data-depth={depth}>
      {isSingleFilterCondition(condition) ? (
        <FilterConditionForm
          fields={fields}
          fieldsForExpression={fieldsForExpression}
          condition={condition}
          setCondition={setCondition}
          onRemove={(canRemove ?? false) ? onRemove : undefined}
          variables={variables}
        />
      ) : (
        condition.operands.map((operand, index) => (
          <Fragment key={index}>
            <CompositeFilterConditionFormInner
              fields={fields}
              fieldsForExpression={fieldsForExpression}
              condition={operand}
              depth={depth + 1}
              canAddAnd={depth < 1}
              canAddOr={false}
              canRemove={canRemove}
              setCondition={(newCondition) => {
                const newOperands = [
                  ...condition.operands.slice(0, index),
                  newCondition,
                  ...condition.operands.slice(index + 1),
                ];
                setCondition({ ...condition, operands: newOperands });
              }}
              onRemove={() => {
                if (condition.operands.length === 1 && onRemove) {
                  return onRemove();
                }
                setCondition({
                  ...condition,
                  operands: [
                    ...condition.operands.slice(0, index),
                    ...condition.operands.slice(index + 1),
                  ],
                });
              }}
              variables={variables}
            />
            {index < condition.operands.length - 1 && (
              <div className={styles.operandSeparator}>
                <span>{condition.operator.toUpperCase()}</span>
              </div>
            )}
          </Fragment>
        ))
      )}
      <div className={styles.addOperand}>
        {(canAddAnd ?? false) && (
          <InlineButton
            onClick={() => {
              handleAddOperand('and');
            }}
            className={styles.addOperandButton}>
            <Icon name="Plus" size={15} /> And
          </InlineButton>
        )}
        {(canAddOr ?? false) && (
          <InlineButton
            onClick={() => {
              handleAddOperand('or');
            }}
            className={styles.addOperandButton}>
            <Icon name="Plus" size={15} /> Or
          </InlineButton>
        )}
      </div>
    </div>
  );
};

interface CompositeFilterConditionFormProps {
  fields: Fields;
  fieldsForExpression: (Field | FieldGroup)[];
  condition: CompositeFilterCondition;
  setCondition: (condition: CompositeFilterCondition) => void;
  variables: VariableDefinition[];
}

export const CompositeFilterConditionForm = (props: CompositeFilterConditionFormProps) => {
  const { condition } = props;
  const fields = useEnsureFieldsExist(props.fields, getCompositeConditionKeys(condition));
  return (
    <CompositeFilterConditionFormInner
      fields={fields}
      fieldsForExpression={props.fieldsForExpression}
      condition={ensureTopLevelOrFilter(condition)}
      setCondition={(updatedCondition) => props.setCondition(unwrapEmptyFilters(updatedCondition))}
      depth={0}
      canRemove={!isSingleFilterCondition(condition)}
      canAddAnd={isSingleFilterCondition(condition)}
      canAddOr
      variables={props.variables}
    />
  );
};
