import classnames from 'classnames';
import { useState } from 'react';

import { useSelectedAccount } from '@/lib/accounts/context';
import { TextArea } from '@/components/form/text-area';
import { Button } from '@/components/button';
import { useToastContext } from '@/components/toast';
import { useUpdateAiSystemInstructionsMutation } from '@/graphql';

import { Section } from '../common';

import formStyles from '../../components/form/form.module.scss';

interface AiSystemInstructionsSettingsProps {
  aiSystemInstructions: string;
}

export const AiSystemInstructionsSettings = ({
  aiSystemInstructions,
}: AiSystemInstructionsSettingsProps) => {
  const account = useSelectedAccount();
  const [instructions, setInstructions] = useState(aiSystemInstructions);
  const [updateAiSystemInstructions, { loading }] = useUpdateAiSystemInstructionsMutation();
  const toast = useToastContext();

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    updateAiSystemInstructions({
      variables: {
        accountId: account.accountId,
        aiSystemInstructions: instructions,
      },
      refetchQueries: ['AccountSettings'],
    });

    toast({
      kind: 'success',
      title: 'Update complete!',
      content: () => 'Account system instructions has been updated.',
    });
  };

  const placeholder = [
    'If possible, provide a list of short, concise instructions. For example:',
    '',
    '- Use only EUR currency unless asked to do otherwise',
    '- All country codes are in ISO 3166-1 alpha-2 like: AT, DE, FI, FR, GB',
  ].join('\n');

  return (
    <Section title="AI System Instructions">
      <form onSubmit={handleFormSubmit} className={classnames(formStyles.formHorizontal)}>
        <div>Pass custom instructions to the AI</div>
        <div className={formStyles.formRow}>
          <TextArea
            placeholder={placeholder}
            value={instructions}
            rows={10}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </div>

        <div className={formStyles.formControls}>
          <Button type="submit" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </Section>
  );
};
