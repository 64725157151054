import { MouseEventHandler } from 'react';

import { ErrorBoundary } from '@/lib/error';

import {
  DeriveFieldOperation,
  Exploration,
  FilterOperation,
  Metric,
  Model,
  PipelineOperation,
  PipelineState,
  RelationAggregateOperation,
  VariableDefinition,
} from '../types';
import { FilterForm } from './edit-filter';
import { GroupAggregateForm } from './edit-group-aggregate';
import { SwitchToRelationForm } from './edit-switch-relation';
import { DeriveFieldForm } from './edit-derive-field';
import { RelationColumnForm } from './edit-relation-columns';
import { Panel } from '../../components/panel';
import { ErrorBanner } from '../../components/banner';
import { PipelineEditorError } from './errors';
import { metricsByModelId } from '../utils/metrics';

interface EditOperationProps {
  operation: PipelineOperation;
  exploration: Exploration;
  state: PipelineState;
  models: Model[];
  metrics: Metric[];
  setOperation: (operation: PipelineOperation) => void;
  onClose: () => void;
  onDuplicate?: (
    operation: FilterOperation | RelationAggregateOperation | DeriveFieldOperation,
  ) => void;
  onRemove: MouseEventHandler;
  className?: string;
  variables: VariableDefinition[];
}

export const EditOperation = (props: EditOperationProps) => (
  <ErrorBoundary
    fallback={(error) => (
      <Panel className={props.className}>
        <ErrorBanner
          description={error.error instanceof PipelineEditorError ? error.error.message : undefined}
          actions={[
            {
              label: 'Remove operation',
              onClick: props.onRemove,
            },
          ]}
        />
      </Panel>
    )}>
    {props.operation.operation === 'filter' && (
      <Panel title="Edit Filter" className={props.className}>
        <FilterForm
          fields={props.state.fields}
          operation={props.operation}
          setOperation={props.setOperation}
          onClose={props.onClose}
          variables={props.variables}
          onDuplicate={props.onDuplicate}
        />
      </Panel>
    )}
    {props.operation.operation === 'deriveField' && (
      <Panel title="Edit Custom Formula" className={props.className}>
        <DeriveFieldForm
          fields={props.state.fields}
          operation={props.operation}
          setOperation={props.setOperation}
          onClose={props.onClose}
          onDuplicate={props.onDuplicate}
        />
      </Panel>
    )}
    {props.operation.operation === 'groupAggregate' && (
      <Panel title="Edit Summarization" className={props.className}>
        <GroupAggregateForm
          fields={props.state.fields}
          metrics={metricsByModelId(props.metrics, props.state.model.modelId)}
          operation={props.operation}
          setOperation={props.setOperation}
          onClose={props.onClose}
        />
      </Panel>
    )}
    {(props.operation.operation === 'relationAggregate' ||
      props.operation.operation === 'addRelatedColumn') && (
      <Panel title="Edit Columns" className={props.className}>
        <RelationColumnForm
          fields={props.state.fields}
          model={props.state.model}
          exploration={props.exploration}
          models={props.models}
          operation={props.operation}
          onSubmit={props.setOperation}
          onDuplicate={props.onDuplicate}
          onClose={props.onClose}
          variables={props.variables}
          relations={props.state.relations}
        />
      </Panel>
    )}
    {props.operation.operation === 'switchToRelation' && (
      <Panel title="Edit Jump to Related Data" className={props.className}>
        <SwitchToRelationForm
          operation={props.operation}
          setOperation={props.setOperation}
          model={props.state.model}
          models={props.models}
          onClose={props.onClose}
          relations={props.state.relations}
        />
      </Panel>
    )}
  </ErrorBoundary>
);
