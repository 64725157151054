import { first, isEqual } from 'lodash';
import { useMemo, useState } from 'react';

import { Tooltip } from '@/components/tooltip';
import { Button, IconButton } from '@/components/button';
import { Form } from '@/components/form';
import { useDirtyContext } from '@/explore/dirty-context';
import type {
  CompositeFilterCondition,
  Fields,
  FilterOperation,
  VariableDefinition,
} from '@/explore/types';
import { useDeepCompareEffect } from '@/lib/hooks/use-deep-compare-hook';

import { createEmptyFilterCondition, isValidCondition } from '../utils/filter';
import { CompositeFilterConditionForm } from '.';

import form from '@/components/form/form.module.scss';

interface FilterFormProps {
  fields: Fields;
  operation?: FilterOperation;
  setOperation: (operation: FilterOperation) => void;
  onClose: () => void;
  onDuplicate?: (operation: FilterOperation) => void;
  variables: VariableDefinition[];
}

export const FilterForm = (props: FilterFormProps) => {
  const { operation } = props;
  const defaultCondition = useMemo(
    () => createEmptyFilterCondition(first(props.fields)),
    [props.fields],
  );
  const initialCondition = operation?.parameters ?? defaultCondition;
  const [condition, setCondition] = useState<CompositeFilterCondition>(initialCondition);

  const { setDirty } = useDirtyContext();

  useDeepCompareEffect(() => {
    if (operation?.parameters !== undefined) {
      setCondition(operation?.parameters);
    }
  }, [operation?.parameters]);

  const handleChange = (condition: CompositeFilterCondition) => {
    setCondition(condition);
    const isDirty = !isEqual(initialCondition, condition);
    setDirty(isDirty);
  };

  const handleSubmit = () => {
    setDirty(false);
    props.setOperation({
      operation: 'filter',
      parameters: condition,
    });
  };

  const handleCancel = () => {
    setDirty(false);
    props.onClose();
  };
  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <CompositeFilterConditionForm
        fields={props.fields}
        fieldsForExpression={props.fields}
        condition={condition}
        setCondition={handleChange}
        variables={props.variables}
      />
      <div className={form.formControls}>
        <Button size="small" type="submit" disabled={!isValidCondition(condition, props.fields)}>
          {props.operation ? 'Save' : 'Filter'}
        </Button>
        <Button size="small" variant="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
        {props.onDuplicate !== undefined && (
          <>
            <div className={form.filler} />
            <Tooltip content="Duplicate step">
              <IconButton
                icon="Copy"
                onClick={() =>
                  props.onDuplicate?.({
                    operation: 'filter',
                    parameters: condition,
                  })
                }
              />
            </Tooltip>
          </>
        )}
      </div>
    </Form>
  );
};
