import { Fragment, Ref, forwardRef } from 'react';
import classNames from 'classnames';
import { compact, first, isArray, isObject } from 'lodash';

import { Icon } from '@/components/icon';
import { Json } from '@/lib/types';
import { Select } from '@/components/form/select';
import { NoMatchBanner } from '@/components/banner';
import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';
import { Loader } from '@/components/loader';
import { Tooltip } from '@/components/tooltip';

import { PaginatedRecordsComponentProps } from '../paginated-records';
import { flattenNestedList } from '../../grouping';
import { formatPropertyValue } from '../../utils/format';
import { DataTableProperty, DataTableRow } from '../datatable';
import { getDataTypeFromValue, getModelKindIconName } from '../../utils';
import { CellValue } from '../datatable/cell';
import { ModelKind } from '../../types';
import { getModelKind } from '../../model/utils';
import { useCellContextMenu, useColumnContextMenu } from '../datatable/context-menu';

import formStyles from '@/components/form/form.module.scss';
import tableStyles from '@/components/table/table.module.scss';
import styles from './cards-list.module.scss';

const CardTitlePropertyKeys = ['name', 'fullname', 'email', 'event_name', 'event'];

/**
 * Flatten 1st level of Json properties onto the record and properties array
 */
const expandJsonProperties = (
  record: Record<string, Json>,
  properties: DataTableProperty[],
): [Record<string, Json>, DataTableProperty[]] => {
  const expandedProperties = properties.reduce((acc, property): DataTableProperty[] => {
    if (property.type === 'Object' && !isArray(record[property.key])) {
      Object.entries(record[property.key] ?? {}).forEach(([key, value]) => {
        acc.push({
          key: `${property.key}_${key}`,
          name: `${property.name}: ${key}`,
          type: getDataTypeFromValue(value),
          buildLink: property.buildLink,
        });
      });
      return acc;
    }
    acc.push(property);
    return acc;
  }, [] as DataTableProperty[]);

  const expandedRecord = Object.entries(record).reduce(
    (acc, [key, value]) => {
      if (
        isObject(value) &&
        !isArray(value) &&
        properties.find((p) => p.key === key)?.type === 'Object'
      ) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          acc[`${key}_${subKey}`] = subValue;
        });
        return acc;
      }
      acc[key] = value;
      return acc;
    },
    {} as Record<string, Json>,
  );

  return [expandedRecord, expandedProperties];
};

export const CardsList = forwardRef(function CardsList(
  props: PaginatedRecordsComponentProps,
  ref: Ref<HTMLDivElement>,
) {
  const sortKey = first(props.sort)?.key;
  const sortDirection = first(props.sort)?.direction;
  const records = flattenNestedList(props.records);
  const titleProperty = props.properties.find(({ key }) => CardTitlePropertyKeys.includes(key));
  const primaryKey = props.properties.filter(({ pk }) => pk === true);
  const properties = props.properties.filter(
    ({ key, pk }) => key !== titleProperty?.key && pk !== true,
  );
  const { loading = false } = props;

  if (!loading && records.length === 0) {
    return <NoMatchBanner />;
  }

  const handleSortKeyChange = (key: string) => {
    props.setSort({ key, direction: sortDirection ?? 'ASC' });
  };

  const handleSortDirectionChange = (direction: 'ASC' | 'DESC') => {
    props.setSort({ key: sortKey ?? properties[0].key, direction });
  };

  const content = loading ? (
    <Loader fullSize />
  ) : (
    <>
      {records.length > 1 && (
        <div className={styles.sort}>
          <div className={formStyles.formRow}>
            <label className={formStyles.formLabel}>Sort by</label>
            <Select
              options={props.properties.map(({ key, name }) => ({ value: key, label: name }))}
              value={sortKey}
              onChange={handleSortKeyChange}
            />
            <Select
              options={[
                { value: 'ASC', label: 'Ascending' },
                { value: 'DESC', label: 'Descending' },
              ]}
              value={sortDirection}
              onChange={handleSortDirectionChange}
            />
          </div>
        </div>
      )}
      <div className={styles.listContainer} ref={ref}>
        <ol>
          {records.map((record, index) => (
            <li key={index}>
              <Card
                record={record}
                properties={properties}
                modelKind={getModelKind(props.model)}
                primaryKey={primaryKey}
                title={titleProperty ? String(record[titleProperty.key]) : undefined}
                canEditPipeline={props.canEditPipeline}
              />
            </li>
          ))}
        </ol>
      </div>
    </>
  );

  return (
    <div
      className={classNames(styles.cards, {
        [styles.singleCard]: records.length === 1,
      })}>
      {content}
    </div>
  );
});

interface CardProps {
  primaryKey: DataTableProperty[];
  modelKind: ModelKind | null;
  properties: DataTableProperty[];
  record: DataTableRow;
  canEditPipeline?: boolean;
  title?: string;
}

const Card = (props: CardProps) => {
  const { record, properties, primaryKey, title, canEditPipeline = true } = props;
  const [expandedRecord, expandedProperties] = expandJsonProperties(record, properties);
  const propertyCount = expandedProperties.length;
  const openCellContextMenu = useCellContextMenu();
  const openColumnContextMenu = useColumnContextMenu();
  const screenSize = useScreenSize();
  const [expandedPropertiesCol1, expandedPropertiesCol2] =
    propertyCount > 3 && screenSize.breakpoint > Breakpoint.md
      ? [
          expandedProperties.slice(0, Math.ceil(propertyCount / 2)),
          expandedProperties.slice(Math.ceil(propertyCount / 2)),
        ]
      : [expandedProperties, []];

  const isExpandedProperty = (property: DataTableProperty) =>
    !props.properties.some(({ key }) => key === property.key);

  const handleHeaderClick = (event: React.MouseEvent, property: DataTableProperty) => {
    openColumnContextMenu({
      position: {
        x: event.clientX - 10,
        y: event.clientY + 10,
      },
      property,
      canEditPipeline: canEditPipeline && !isExpandedProperty(property),
    });
  };

  const handleCellClick = (
    event: React.MouseEvent,
    property: DataTableProperty,
    value: unknown,
  ) => {
    openCellContextMenu({
      position: {
        x: event.clientX - 10,
        y: event.clientY + 10,
      },
      row: record,
      property,
      value,
      canEditPipeline: canEditPipeline && !isExpandedProperty(property),
    });
  };

  return (
    <div className={styles.card}>
      {(title !== undefined || primaryKey.length > 0) && (
        <div className={classNames(styles.header, { [styles.withTitle]: title !== undefined })}>
          <div className={styles.icon}>
            <Icon name={getModelKindIconName(props.modelKind)} size={20} />
          </div>
          {title === undefined ? (
            <div className={styles.title}>
              {primaryKey.map((property, i) => {
                return (
                  <CellValue
                    key={i}
                    value={expandedRecord[property.key]}
                    property={property}
                    onClick={(event) =>
                      handleCellClick(event, property, expandedRecord[property.key])
                    }
                  />
                );
              })}
            </div>
          ) : (
            <>
              <div className={styles.title}>{formatPropertyValue(title, { type: 'String' })}</div>
              <div className={styles.id}>
                {primaryKey.map((property, i) => {
                  return (
                    <CellValue
                      key={i}
                      value={expandedRecord[property.key]}
                      property={property}
                      onClick={(event) =>
                        handleCellClick(event, property, expandedRecord[property.key])
                      }
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
      <table className={tableStyles.cardDataTable}>
        <tbody>
          {expandedPropertiesCol1.map((_, index) => (
            <tr key={index}>
              {compact([expandedPropertiesCol1[index], expandedPropertiesCol2[index]]).map(
                (property, colIndex) => (
                  <Fragment key={index + colIndex * expandedPropertiesCol1.length}>
                    <td className={styles.label}>
                      <div className={tableStyles.content}>
                        <Tooltip
                          delay={150}
                          content={
                            <dl>
                              <dt>{property.name}</dt>
                              <dd>{property.description}</dd>
                            </dl>
                          }>
                          <button
                            disabled={!canEditPipeline}
                            onClick={(event) => handleHeaderClick(event, property)}>
                            {property.name}
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                    <td
                      className={classNames({
                        [tableStyles.jsonData]:
                          property.type === 'Object' || property.type === 'Array',
                      })}>
                      <div className={tableStyles.content}>
                        <CellValue
                          value={expandedRecord[property.key]}
                          property={property}
                          onClick={(event) =>
                            handleCellClick(event, property, expandedRecord[property.key])
                          }
                        />
                      </div>
                    </td>
                  </Fragment>
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
