import { first } from 'lodash';

import { pluralize } from '@/lib/utils/string';
import { isRecordsLikeCell } from '@/core/cell';
import { isBasePipeline, isPipelineWithParent } from '@/core/pipeline';
import { getModelOrThrow } from '@/explore/model/utils';
import { getCellByPipelineIdOrThrow } from '@/explore/exploration/utils';
import { pipelineColor } from '@/explore/pipeline/format';

import { Cell, Exploration, JoinPipelineOperation, Model, Pipeline } from '../../types';

import styles from '../pipeline.module.scss';

export const isJoinedPipeline = (pipeline: Pipeline) =>
  first(pipeline.operations)?.operation === 'joinPipeline';

export const getPipelineTitle = (models: Model[], exploration: Exploration, pipeline: Pipeline) => {
  if (isBasePipeline(pipeline)) {
    return getModelOrThrow(models, pipeline.baseModelId).name;
  }

  if (isPipelineWithParent(pipeline)) {
    const parentCell = getCellByPipelineIdOrThrow(pipeline.parentId, exploration);
    if (isRecordsLikeCell(parentCell)) {
      return parentCell.title ?? '';
    }
  }

  return '';
};

export const getJoinedPipelineTitle = (
  pipeline: Pipeline,
  operation: JoinPipelineOperation,
  models: Model[],
  exploration: Exploration,
) => {
  return `${getPipelineTitle(models, exploration, pipeline)} + ${getPipelineTitle(
    models,
    exploration,
    operation.parameters.pipeline,
  )}`;
};

export const toTitleCase = (str: string) => {
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatCellInstances = (instances: Cell[]) => {
  return `Pipeline used in ${instances.length} ${pluralize(
    instances.length,
    'instance',
    'instances',
  )}:\n${instances
    .map((instance) => ('title' in instance ? `- ${instance.title}` : '- Untitled Cell'))
    .join('\n')}`;
};

export const getRelatedFieldName = (name: string, relationOrModelName: string) => {
  return `${name} (on ${relationOrModelName})`;
};

const pipelineColors = [
  styles.headerColor1,
  styles.headerColor2,
  styles.headerColor3,
  styles.headerColor4,
  styles.headerColor5,
  styles.headerColor6,
];

export const pipelineHeaderColor = (index: number | undefined) =>
  pipelineColor(index, pipelineColors);
