import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from 'react-router-dom';

import { useWorkspaceContext, WorkspaceContextProvider } from './workspace/context';
import { AccountContextProvider, RedirectToAccount } from './lib/accounts/context';
import { LoginPage } from './public/login';
import { SignupPage } from './public/signup';
import { LogoutPage } from './public/logout';
import { VerifyEmailPage } from './public/verify-email';
import { ToastContextProvider } from './components/toast';
import { Explore } from './explore';
import { AccountSettingsPage } from './settings';
import { GenericFallback, ErrorBoundary } from './lib/error';
import { OnboardingDatabaseStep } from './onboarding';
import { OnboardingComplete } from './onboarding/step-complete';
import { OnboardingStart } from './onboarding/step-start';
import { OnboardingLegal } from './onboarding/step-legal';
import { WelcomeSurveyStep } from './onboarding/step-welcome-survey';
import { OnboardingSetupCallStep } from './onboarding/step-setup-call';
import { ConfirmEmailPage } from './public/confirm-email';
import { LayoutContextProvider } from './components/layout/layout-context';
import { SidebarLayout } from './components/layout';
import { GradientLayout } from './components/gradient-layout';
import { RedirectToAlertExploration } from './alerts/redirect-to-alert-exploration';
import { addNextUrlParam, getNextUrl } from './public/utils/url';
import { isNil, notNil } from './lib/utils';

import './styles/app.scss';

const App = () => (
  <ErrorBoundary fallback={<GenericFallback fullscreen />}>
    <WorkspaceContextProvider>
      <ToastContextProvider>
        <RouterProvider
          router={createBrowserRouter(
            createRoutesFromElements(
              <>
                <Route element={<GradientLayout />}>
                  <Route element={<Unauthenticated />}>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignupPage />} />
                  </Route>
                  <Route path="/verify-email" element={<VerifyEmailPage />} />
                  <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                  <Route path="/logout" element={<LogoutPage />} />
                </Route>
                <Route element={<Authenticated />}>
                  <Route
                    path=":account/*"
                    element={
                      <AccountContextProvider>
                        <Routes>
                          <Route
                            element={
                              <LayoutContextProvider>
                                <SidebarLayout />
                              </LayoutContextProvider>
                            }>
                            <Route
                              path="/alerts/:alertConfigurationId/exploration"
                              element={<RedirectToAlertExploration />}
                            />
                            <Route path="explore/*" element={<Explore />} />
                            <Route path="settings" element={<AccountSettingsPage />} />
                          </Route>
                          <Route path="/onboarding" element={<GradientLayout showLogout />}>
                            <Route index element={<OnboardingStart />} />
                            <Route path="legal" element={<OnboardingLegal />} />
                            <Route path="welcome-survey" element={<WelcomeSurveyStep />} />
                            <Route path="setup-call" element={<OnboardingSetupCallStep />} />
                            <Route path="connection" element={<OnboardingDatabaseStep />} />
                            <Route path="complete" element={<OnboardingComplete />} />
                          </Route>
                          <Route path="*" element={<Navigate to="/explore" replace />} />
                        </Routes>
                      </AccountContextProvider>
                    }
                  />
                  <Route index element={<RedirectToAccount />} />
                  <Route path="explore/*" element={<RedirectToAccount />} />
                  <Route path="settings/*" element={<RedirectToAccount />} />
                </Route>
              </>,
            ),
          )}
        />
      </ToastContextProvider>
    </WorkspaceContextProvider>
  </ErrorBoundary>
);

const Authenticated = () => {
  const { user } = useWorkspaceContext();
  const location = useLocation();

  if (isNil(user)) {
    return <Navigate to={addNextUrlParam('/login', location)} />;
  }

  if (location.pathname !== '/verify-email' && !user.emailVerified) {
    return <Navigate to={'/verify-email'} />;
  }

  return <Outlet />;
};

export const Unauthenticated = () => {
  const { user } = useWorkspaceContext();
  const location = useLocation();

  if (notNil(user) && !user.emailVerified && location.pathname !== '/verify-email') {
    return <Navigate to={'/verify-email'} />;
  }

  if (notNil(user)) {
    return <Navigate to={getNextUrl(location)} />;
  }

  return <Outlet />;
};

export default App;
