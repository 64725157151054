import { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { isNil, first, omit, isEqual } from 'lodash';

import { common } from '@gosupersimple/types';

import { Form } from '@/components/form';
import { Button, IconButton } from '@/components/button';
import { TagSelect } from '@/components/form/tag-select';
import { Input } from '@/components/form/input';
import { Toggle } from '@/components/form/toggle';
import { SearchInput } from '@/components/form/search-input';
import { Option } from '@/components/form/types';

import { isRecordsLikeCell } from '@/core/cell';

import { Tooltip } from '@/components/tooltip';

import { createBasePipeline } from '@/core/pipeline';

import type {
  Model,
  RelationAggregateOperation,
  AddRelatedColumnOperation,
  Exploration,
  Fields,
  VariableDefinition,
  Aggregation,
  RecordsLikeCell,
  Metric,
  PipelineStateRelation,
} from '../types';
import { dereferencePipeline } from '../pipeline/utils';
import {
  getCellByPipelineId,
  getCellByPipelineIdOrThrow,
  getPipelineById,
} from '../exploration/utils';
import { useExplorationContext } from '../exploration/exploration-context';

import { Operation } from './operation';
import { ensureLegalIdentifier, fieldToOption } from './utils';
import { getCompositeConditionKeys, isKeyedAggregation } from '../pipeline/operation';
import { findValidRelation, getPipelineFields } from '../pipeline/state';
import { createDefaultFilterCondition } from './utils/filter';
import { AggregationsEditor } from './aggregations-editor';
import { useMetadataContext } from '../metadata-context';
import { getModelOrThrow } from '../model/utils';
import { getExplorationVariables } from '../utils';
import { SlicingEditor } from './slicing-editor';
import { createDefaultSlice } from '../utils/slicing';
import { getRelatedFieldName } from './utils/format';
import { generateAggregationProperty } from '../utils/aggregation';
import { useEnsureFieldsExist } from './hooks/use-ensure-fields-exist';
import { EditJoinStrategyFields } from './edit-join-strategy-fields';
import {
  constructRelationOptionValue,
  parseRelationOptionValue,
  getCustomRelationOptions,
  getOnThisPageRelationOptions,
  getValidRelationTypesForOperation,
  createRelationOptions,
  getRelation,
} from './utils/relation';
import { useDirtyContext } from '../dirty-context';
import { CompositeFilterConditionForm } from './edit-filter';

import panelStyles from '@/components/panel/panel.module.scss';
import form from '@/components/form/form.module.scss';
import styles from './pipeline.module.scss';

const CustomRelationKey = '__custom';

interface RelatedColumnProps {
  model: Model;
  models: Model[];
}

type Operation = AddRelatedColumnOperation | RelationAggregateOperation;

const getCellTitle = (pipelineId: string, exploration: Exploration) => {
  const cell = getCellByPipelineIdOrThrow(pipelineId, exploration);
  return 'title' in cell ? cell.title : undefined;
};

const validateOperation = (operation: Operation) => {
  if (operation.operation === 'relationAggregate') {
    const isAggregationsValid = operation.parameters.aggregations.every((aggregation) => {
      if (isKeyedAggregation(aggregation)) {
        return !isNil(aggregation.property.name) && !isNil(aggregation.key);
      }
      return !isNil(aggregation.property.name);
    });

    const slice = 'slice' in operation.parameters ? operation.parameters.slice : undefined;

    const isSliceValid =
      slice === undefined ||
      (slice.limit !== undefined &&
        slice.limit >= 1 &&
        (slice.offset === undefined || slice.offset >= 0));

    return isAggregationsValid && isSliceValid;
  }

  return true;
};

const createDefaultOperation = (
  models: Model[],
  exploration: Exploration,
  currentCell: RecordsLikeCell,
  fields: Fields,
  metrics: Metric[],
  relations: PipelineStateRelation[],
) => {
  const relationOptions = createRelationOptions(relations, models);
  const onThisPageRelationOptions = getOnThisPageRelationOptions(
    relations,
    models,
    metrics,
    exploration,
    currentCell,
  );
  const customRelationOptions = getCustomRelationOptions(exploration, currentCell);

  const firstOption =
    first(relationOptions) ?? first(onThisPageRelationOptions) ?? first(customRelationOptions);

  if (firstOption === undefined) {
    throw new Error(
      'No relations available and no linkable blocks found in the current exploration',
    );
  }

  const { relation: firstRelation, pipelineId: firstPipelineId } = parseRelationOptionValue(
    relations,
    firstOption.value,
  );

  if (firstRelation === undefined && firstPipelineId === undefined) {
    throw new Error(
      'No relations available and no linkable blocks found in the current exploration',
    );
  }

  if (
    firstRelation !== undefined &&
    getValidRelationTypesForOperation('addRelatedColumn').includes(firstRelation.type)
  ) {
    return {
      operation: 'addRelatedColumn',
      parameters: {
        relation: { key: firstRelation.key, modelId: firstRelation.baseModelId },
        columns: [],
      },
    } as AddRelatedColumnOperation;
  }

  return {
    operation: 'relationAggregate',
    parameters: {
      ...(firstRelation !== undefined
        ? { relation: { key: firstRelation.key, modelId: firstRelation.baseModelId } }
        : {}),
      ...(firstPipelineId !== undefined ? { pipelineId: firstPipelineId } : {}),
      aggregations: [],
      ...(firstRelation === undefined && firstPipelineId !== undefined
        ? {
            joinStrategy: createJoinStrategy(firstPipelineId, exploration, models, fields, metrics),
          }
        : {}),
    },
  } as RelationAggregateOperation;
};

const createJoinStrategy = (
  pipelineId: string,
  exploration: Exploration,
  models: Model[],
  fields: Fields,
  metrics: Metric[],
) => {
  const pipeline = getPipelineById(pipelineId, exploration);
  if (pipeline.pipelineId === undefined) {
    throw new Error('Pipeline does not have a pipelineId');
  }

  const relatedFields = getPipelineFields(pipeline, exploration, {
    models,
    variables: getExplorationVariables(exploration),
    metrics,
  });

  return {
    joinKeyOnBase: fields[0].key,
    joinKeyOnRelated: relatedFields[0].key,
  };
};

interface RelationInputProps {
  fields: Fields;
  models: Model[];
  relations: PipelineStateRelation[];
  options: Option[];
  operation: Operation;
  exploration: Exploration;
  setOperation(operation: Operation): void;
  resetOperationOnChange?: boolean;
}

const RelationInput = (props: RelationInputProps) => {
  const {
    operation,
    exploration,
    models,
    relations,
    options,
    fields,
    setOperation,
    resetOperationOnChange = false,
  } = props;
  const { selectedCell } = useExplorationContext();
  const { metrics: metrics } = useMetadataContext();

  if (selectedCell === null || !isRecordsLikeCell(selectedCell)) {
    throw new Error(`RelationSelectInput can only be rendered in a RecordsCell`);
  }

  const onThisPageRelationOptions = getOnThisPageRelationOptions(
    relations,
    models,
    metrics,
    exploration,
    selectedCell,
  );
  const customRelationOptions = getCustomRelationOptions(exploration, selectedCell);

  const { parameters } = operation;
  const pipelineId = parameters.pipelineId;
  const relation =
    'relation' in parameters
      ? getRelation(relations, parameters.relation.key, parameters.relation.modelId)
      : undefined;

  const value =
    relation === undefined
      ? CustomRelationKey
      : constructRelationOptionValue({
          ...relation,
          ...(pipelineId !== undefined ? { pipelineId } : {}),
        });

  const handleRelationSelect = (value: string) => {
    if (value === CustomRelationKey) {
      const { pipelineId: firstRelatablePipelineId } = parseRelationOptionValue(
        relations,
        first(customRelationOptions)?.value ?? '',
      );

      if (firstRelatablePipelineId === undefined) {
        throw new Error('Unable to find another pipeline to relate to');
      }

      setOperation({
        operation: 'relationAggregate',
        parameters: {
          pipelineId: firstRelatablePipelineId,
          joinStrategy: createJoinStrategy(
            firstRelatablePipelineId,
            exploration,
            models,
            fields,
            metrics,
          ),
          aggregations: [],
        },
      });
      return;
    }
    const { relation: selectedRelation, pipelineId } = parseRelationOptionValue(relations, value);
    if (selectedRelation === undefined) {
      throw new Error(`Invalid relation key ${value}`);
    }
    const isAddRelatedColumn = getValidRelationTypesForOperation('addRelatedColumn').includes(
      selectedRelation.type,
    );
    const wasAddRelatedColumn = operation?.operation === 'addRelatedColumn';
    const columns = wasAddRelatedColumn ? (operation?.parameters.columns ?? []) : [];
    const aggregations = wasAddRelatedColumn ? [] : (operation?.parameters.aggregations ?? []);
    const filter = wasAddRelatedColumn ? [] : operation?.parameters.filter;
    const slice = wasAddRelatedColumn ? undefined : operation?.parameters.slice;
    setOperation({
      ...operation,
      operation: isAddRelatedColumn ? 'addRelatedColumn' : 'relationAggregate',
      parameters: {
        ...omit(
          operation?.parameters,
          'joinStrategy',
          'slice',
          'columns',
          'aggregations',
          'filter',
        ),
        relation: { key: selectedRelation.key, modelId: selectedRelation.baseModelId },
        pipelineId,
        ...(isAddRelatedColumn
          ? { columns: resetOperationOnChange ? [] : columns }
          : {
              ...(resetOperationOnChange ? {} : { slice }),
              aggregations: resetOperationOnChange ? [] : aggregations,
              filter: resetOperationOnChange ? undefined : filter,
            }),
      },
    } as Operation);
  };

  const combinedOptions = [
    ...(onThisPageRelationOptions.length > 0
      ? [
          {
            label: 'Relations',
            options,
          },
          {
            label: 'On This Page',
            options: onThisPageRelationOptions,
          },
        ]
      : options),
    ...(customRelationOptions.length > 0
      ? [
          {
            value: CustomRelationKey,
            label: 'Custom',
          },
        ]
      : []),
  ];

  const isValueValid = combinedOptions
    .flatMap((optionOrGroup) =>
      'options' in optionOrGroup ? optionOrGroup.options : [optionOrGroup],
    )
    .some((option) => option.value === value);

  return (
    <SearchInput
      value={isValueValid ? value : ''}
      onChange={(value) => handleRelationSelect(value)}
      options={[
        ...(isValueValid ? [] : [{ value: '', label: 'Choose a new relation...' }]),
        ...combinedOptions,
      ]}
      autoFocus
    />
  );
};

interface RelatedColumnFormProps extends RelatedColumnProps {
  fields: Fields;
  operation?: Operation;
  exploration: Exploration;
  onSubmit(operation: Operation): void;
  onDuplicate?: (operation: RelationAggregateOperation) => void;
  onClose: () => void;
  variables: VariableDefinition[];
  relations: PipelineStateRelation[];
}

export const RelationColumnForm = (props: RelatedColumnFormProps) => {
  const { operation, exploration, models, fields, relations } = props;
  const { selectedCell } = useExplorationContext();

  if (selectedCell === null) {
    throw new Error(`RelationColumnForm can only be rendered with selected cell`);
  }

  // Used to override the operation with a new one if the relation or cell is missing
  const [newOperation, setNewOperation] = useState<Operation | undefined>(undefined);

  const parameters = operation?.parameters;
  const relation =
    parameters !== undefined && 'relation' in parameters
      ? getRelation(relations, parameters.relation.key, parameters.relation.modelId)
      : undefined;

  const isRelationMissing =
    parameters !== undefined && 'relation' in parameters && relation === undefined;

  const isCellMissing =
    operation !== undefined &&
    operation.parameters.pipelineId !== undefined &&
    getCellByPipelineId(operation.parameters.pipelineId, exploration) === undefined;

  const relationOptions = createRelationOptions(relations, models);
  const hasAvailableRelations = relationOptions.length > 0;
  const hasAvailableCustomRelations = exploration.view.cells.some(
    (cell) => isRecordsLikeCell(cell) && cell.id !== selectedCell.id,
  );

  if (!hasAvailableRelations && !hasAvailableCustomRelations) {
    return (
      <div className={form.formHorizontal}>
        <p className={form.helpText}>
          No relations available and no linkable blocks found in the current exploration
        </p>
        <div>
          <Button size="small" onClick={props.onClose}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  if (
    operation !== undefined &&
    newOperation === undefined &&
    (isRelationMissing || isCellMissing)
  ) {
    return (
      <div className={form.formHorizontal}>
        <div className={form.formRow}>
          <p className={form.helpText}>
            {isRelationMissing
              ? `Relation missing. Please restore relation '${parameters.relation.key}' or select a new relation.`
              : `The referenced block has been removed from the exploration. Please select a new relation.`}
          </p>
        </div>
        <div className={form.formRow}>
          <RelationInput
            fields={fields}
            models={models}
            relations={relations}
            options={relationOptions}
            operation={operation}
            exploration={exploration}
            setOperation={setNewOperation}
          />
          <Button size="small" variant="outlined" onClick={props.onClose}>
            {props.operation ? 'Cancel' : 'Back'}
          </Button>
        </div>
      </div>
    );
  }

  return <RelationColumnFormInner {...props} operation={newOperation ?? props.operation} />;
};

export const RelationColumnFormInner = (props: RelatedColumnFormProps) => {
  const {
    fields,
    models,
    model,
    relations,
    exploration,
    onSubmit,
    onDuplicate,
    onClose,
    variables,
  } = props;
  const { selectedCell } = useExplorationContext();
  const { metrics: metrics } = useMetadataContext();

  if (selectedCell === null || !isRecordsLikeCell(selectedCell)) {
    throw new Error(`RelationColumnForm can only be rendered with selected cell`);
  }

  const initialOperation =
    props.operation ??
    createDefaultOperation(models, exploration, selectedCell, fields, metrics, relations);
  const [operation, setOperation] = useState<Operation>(initialOperation);
  const { setDirty } = useDirtyContext();

  const { parameters } = operation;
  const { pipelineId } = parameters;
  const isOnThisPageRelation = pipelineId !== undefined;
  const isCustomRelation = 'joinStrategy' in parameters;
  const relation = isCustomRelation
    ? {
        key: CustomRelationKey,
        name: 'Custom',
        modelId: props.model.modelId,
      }
    : findValidRelation(relations, models, parameters.relation.key, parameters.relation.modelId);

  const explorationVariables = getExplorationVariables(exploration);
  const relatedPipeline = isOnThisPageRelation
    ? getPipelineById(pipelineId, exploration)
    : createBasePipeline({
        baseModelId: relation.modelId,
        operations: [],
      });

  const relatedModel = getModelOrThrow(
    models,
    dereferencePipeline(relatedPipeline, exploration).baseModelId,
  );

  const relatedFields = getPipelineFields(relatedPipeline, exploration, {
    models,
    variables: explorationVariables,
    metrics,
  });

  const slice = 'slice' in parameters ? parameters.slice : undefined;
  const filter = 'filter' in parameters ? parameters.filter : undefined;
  // Store previous state so values don't get lost when toggling sub-form on/off
  const [previousSlice, setPreviousSlice] = useState<common.Slice | undefined>(slice);
  const [previousFilter, setPreviousFilter] = useState<common.CompositeFilterCondition | undefined>(
    filter,
  );

  const selectedRelationOption = isOnThisPageRelation
    ? isCustomRelation
      ? CustomRelationKey
      : constructRelationOptionValue({ ...relation, pipelineId })
    : relation.key;

  const relationName = isOnThisPageRelation
    ? (getCellTitle(pipelineId, exploration) ?? relatedModel.name)
    : operation.operation === 'relationAggregate'
      ? relation.name
      : relatedModel.name;

  const setOperationDefaults = (operation: Operation) => {
    if (operation.operation === 'relationAggregate') {
      return {
        ...operation,
        parameters: {
          ...operation.parameters,
          aggregations: operation.parameters.aggregations.map((aggregation) => {
            if (aggregation.property.name.length === 0) {
              const { key, name } = generateAggregationProperty(
                aggregation,
                relatedFields,
                metrics,
                relationName,
              );
              return {
                ...aggregation,
                property: {
                  key,
                  name,
                },
              };
            }
            return {
              ...aggregation,
              property: {
                key: aggregation.property.key,
                name: aggregation.property.name,
              },
            };
          }),
        },
      };
    }
    return operation;
  };

  const handleChange = (operation: Operation) => {
    setOperation(operation);
    const isDirty = !isEqual(initialOperation, operation);
    setDirty(isDirty);
  };

  const handleSetAggregations = (aggregations: Aggregation[]) => {
    if (operation.operation !== 'relationAggregate') {
      throw new Error('Attempt to set aggregations on addRelatedColumn');
    }

    handleChange({
      ...operation,
      parameters: { ...operation.parameters, aggregations },
    });
  };

  const handleToggleSlice = (isEnabled: boolean) => {
    if (operation.operation !== 'relationAggregate') {
      throw new Error('Attempt to set slice on addRelatedColumn');
    }
    handleChange({
      ...operation,
      parameters: {
        ...operation.parameters,
        slice: isEnabled ? createDefaultSlice(relatedFields, previousSlice) : undefined,
      },
    });
  };

  const handleSetSlice = (slice: common.Slice) => {
    if (operation.operation !== 'relationAggregate') {
      throw new Error('Attempt to set slice on addRelatedColumn');
    }
    setPreviousSlice(slice);
    handleChange({
      ...operation,
      parameters: {
        ...operation.parameters,
        slice,
      },
    });
  };

  const handleToggleFilter = (isEnabled: boolean) => {
    if (operation.operation !== 'relationAggregate') {
      throw new Error('Attempt to set filter on addRelatedColumn');
    }
    handleChange({
      ...operation,
      parameters: {
        ...operation.parameters,
        filter: isEnabled ? createDefaultFilterCondition(relatedFields, previousFilter) : undefined,
      },
    });
  };

  const handleSetFilter = (condition: common.CompositeFilterCondition) => {
    if (operation.operation !== 'relationAggregate') {
      throw new Error('Attempt to set filter on addRelatedColumn');
    }
    setPreviousFilter(condition);
    handleChange({
      ...operation,
      parameters: {
        ...operation.parameters,
        filter: condition,
      },
    });
  };

  const handleSubmit = () => {
    setDirty(false);
    const sanitizedOperation = setOperationDefaults(operation);

    if (!validateOperation(sanitizedOperation)) {
      return false;
    }

    onSubmit(sanitizedOperation);
  };

  const handleCancel = () => {
    setDirty(false);
    onClose();
  };

  const relationOptions = createRelationOptions(relations, models);

  return (
    <Form className={form.formHorizontal} onSubmit={handleSubmit}>
      <div className={form.formRow}>
        <label className={form.formLabel}>Based on</label>
        <RelationInput
          fields={fields}
          models={models}
          relations={relations}
          options={relationOptions}
          operation={operation}
          exploration={exploration}
          setOperation={setOperation}
          resetOperationOnChange
        />
      </div>
      {operation.operation === 'relationAggregate' && 'joinStrategy' in parameters ? (
        <>
          <hr className={panelStyles.fullWidth} />
          <EditJoinStrategyFields
            data={{
              pipelineId: parameters.pipelineId,
              joinStrategy: parameters.joinStrategy,
            }}
            fields={fields}
            exploration={exploration}
            models={models}
            metrics={metrics}
            onChange={(data) =>
              handleChange({
                ...operation,
                parameters: {
                  ...parameters,
                  pipelineId: data.pipelineId,
                  joinStrategy: data.joinStrategy,
                  aggregations: [],
                  filter: undefined,
                },
              })
            }
          />
        </>
      ) : null}
      <hr className={panelStyles.fullWidth} />

      {operation.operation === 'addRelatedColumn' && (
        <ColumnsEditor
          key={`columns_${selectedRelationOption}`}
          fields={relatedFields}
          relationKey={isOnThisPageRelation ? pipelineId.substring(0, 5) : relation.key}
          relationName={relationName}
          operation={operation}
          setOperation={handleChange}
        />
      )}

      {operation.operation === 'relationAggregate' && (
        <>
          <AggregationsEditor
            key={`aggregations_${selectedRelationOption}`}
            aggregations={operation.parameters.aggregations}
            fields={relatedFields}
            metrics={metrics.filter((metric) => metric.definition.modelId === relatedModel.modelId)}
            relationName={relationName}
            setAggregations={handleSetAggregations}
            showNameInput
            excludeAggregationTypes={['last']}
          />
          <hr className={panelStyles.fullWidth} />
          <div className={form.formHorizontal}>
            <Toggle checked={slice !== undefined} onChange={handleToggleSlice} size="small">
              Slice
            </Toggle>
            {slice !== undefined && (
              <SlicingEditor fields={relatedFields} slice={slice} setSlice={handleSetSlice} />
            )}
          </div>
          <hr className={panelStyles.fullWidth} />
          <div className={form.formHorizontal}>
            <Toggle checked={filter !== undefined} onChange={handleToggleFilter} size="small">
              Filter
            </Toggle>
            {filter !== undefined && (
              <RelationAggregateFilterEditor
                key={`filter_${selectedRelationOption}`}
                {...props}
                fields={fields}
                relatedFields={relatedFields}
                model={model}
                relationKey={relation.key}
                relationName={relationName}
                condition={filter}
                onFilterChange={handleSetFilter}
                variables={variables}
              />
            )}
          </div>
        </>
      )}

      <div className={form.formControls}>
        <Button size="small" type="submit" disabled={!validateOperation(operation)}>
          {props.operation ? 'Save' : 'Create column'}
        </Button>
        <Button size="small" variant="outlined" onClick={handleCancel}>
          {props.operation ? 'Cancel' : 'Back'}
        </Button>
        {onDuplicate !== undefined && operation.operation === 'relationAggregate' ? (
          <>
            <div className={form.filler} />
            <Tooltip content="Duplicate step">
              <IconButton icon="Copy" onClick={() => onDuplicate(operation)} />
            </Tooltip>
          </>
        ) : null}
      </div>
    </Form>
  );
};

type RelationAggregateFilterEditorProps = RelatedColumnFormProps & {
  fields: Fields;
  relatedFields: Fields;
  model: Model;
  relationKey: string;
  relationName: string;
  onFilterChange: (condition: common.CompositeFilterCondition) => void;
  condition: common.CompositeFilterCondition;
  variables: VariableDefinition[];
};

const RelationAggregateFilterEditor = (props: RelationAggregateFilterEditorProps) => {
  const { condition, fields, model, relationKey, relationName } = props;

  const relatedFields = useEnsureFieldsExist(
    props.relatedFields,
    getCompositeConditionKeys(condition),
  );

  return (
    <CompositeFilterConditionForm
      fields={relatedFields}
      fieldsForExpression={[
        {
          name: model.name,
          key: model.modelId,
          fields: fields,
        },
        {
          name: relationName,
          key: relationKey,
          fields: relatedFields,
        },
      ]}
      condition={condition}
      setCondition={props.onFilterChange}
      variables={props.variables}
    />
  );
};

interface ColumnsEditorProps {
  fields: Fields;
  relationKey: string;
  relationName: string;
  operation: AddRelatedColumnOperation;
  setOperation: (operation: AddRelatedColumnOperation) => void;
}

const ColumnsEditor = (props: ColumnsEditorProps) => {
  const { relationKey, relationName, operation, setOperation } = props;
  const { parameters } = operation;

  const [searchTerm, setSearchTerm] = useState('');
  const [displayColumnsFilter, setDisplayColumnsFilter] = useState(false);
  const fields = useEnsureFieldsExist(
    props.fields,
    operation.parameters.columns.map((column) => column.key),
  );

  useEffect(() => {
    if (!displayColumnsFilter && searchTerm.length > 0) {
      setSearchTerm('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayColumnsFilter]);

  const handleChange = (selectedColumns: string[]) => {
    // If user has filtered, then also keep non-filtered selected options
    const existingSelectedColumns = parameters.columns.filter(
      ({ key }) => !filteredOptions.some((option) => option.value === key),
    );
    const newColumns = [
      ...existingSelectedColumns,
      ...selectedColumns.map((column) => {
        const field = fields.find(({ key }) => key === column);
        if (field === undefined) {
          throw new Error(`Selected unknown field ${column}`);
        }
        return {
          key: column,
          property: parameters.columns.find(({ key }) => key === column)?.property ?? {
            key: ensureLegalIdentifier(`${relationKey}_${field.key}`),
            name: getRelatedFieldName(field.name, relationName),
          },
        };
      }),
    ];

    setOperation({
      operation: 'addRelatedColumn',
      parameters: {
        ...parameters,
        columns: newColumns,
      },
    });
  };

  const options = useMemo(() => fields.map(fieldToOption), [fields]);

  const filteredOptions = useMemo(
    () =>
      options.filter(({ label }) =>
        label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
      ),
    [options, searchTerm],
  );

  return (
    <div>
      <label className={classNames(form.formLabel, form.fullWidth)}>
        Columns
        <IconButton
          icon="Search"
          title="Filter columns"
          className={form.formLabelAction}
          onClick={() => setDisplayColumnsFilter(!displayColumnsFilter)}
        />
      </label>
      {displayColumnsFilter && (
        <Input
          type="text"
          value={searchTerm}
          autoFocus
          placeholder="Filter columns"
          className={styles.searchColumnsField}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
      )}
      <TagSelect
        options={filteredOptions}
        value={parameters.columns.map(({ key }) => key)}
        onChange={handleChange}
        isMultiSelect
      />
    </div>
  );
};
