import { Link } from 'react-router-dom';

import { useTitle } from '@/lib/hooks/use-title';
import { useGAPageview } from '@/lib/ga/use-ga';

import { Cards } from '../../components/gradient-layout/cards';
import { GoogleLogin } from './google-login';
import { PasswordLogin } from './password-login';

import commonStyles from '../public.module.scss';

export const LoginPage = () => {
  useTitle('Login');
  useGAPageview();

  return (
    <div className={commonStyles.layout}>
      <h1>Log In</h1>
      <Cards>
        <GoogleLogin />
        <div className={commonStyles.separator}>or</div>
        <PasswordLogin />
      </Cards>
      <p>
        Don&apos;t have an account yet? <Link to="/signup">Sign up</Link>
      </p>
    </div>
  );
};
