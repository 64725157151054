import { useState } from 'react';

import { InlineButton } from '@/components/button';
import { Icon } from '@/components/icon';

import { Cell, Exploration } from '../../types';
import { SortableItem } from '../../components/sortable-item';

import styles from './exploration-outline.module.scss';

type ExplorationOutlineProps = {
  exploration: Exploration;
  onClickCell: (cellId: string) => void;
  onClickAddSection: () => void;
  onReorder: (fromIndex: number, toIndex: number) => void;
};

export const getCellTitle = (cell: Cell) =>
  cell.kind === 'text'
    ? 'Text block'
    : 'title' in cell
      ? cell.title
      : cell.kind === 'variable'
        ? cell.definition.key
        : '';

export const ExplorationOutline = ({
  exploration,
  onClickCell,
  onClickAddSection,
  onReorder,
}: ExplorationOutlineProps) => {
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  return (
    <div className={styles.explorationOutline}>
      {exploration.view.cells.map((cell, i) =>
        'title' in cell || cell.kind === 'text' ? (
          <SortableItem
            key={i}
            scope={'exploration-outline'}
            index={i}
            draggedIndex={draggedIndex}
            setDraggedIndex={setDraggedIndex}
            onReorder={onReorder}>
            <a onClick={() => onClickCell(cell.id)}>{getCellTitle(cell)}</a>
          </SortableItem>
        ) : null,
      )}
      <InlineButton onClick={onClickAddSection} size="small">
        <Icon name="Plus" size={15} /> New block
      </InlineButton>
    </div>
  );
};
