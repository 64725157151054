import { useEffect, useMemo, useRef } from 'react';
import { Variables } from '@gosupersimple/penguino';
import { Completion, startCompletion } from '@codemirror/autocomplete';

import { CodeMirror, CodeMirrorRef } from '@/components/codemirror';
import { Field, Model } from '@/explore/types';
import { FieldGroup, isField, isFieldGroup } from '@/explore/pipeline/utils';
import {
  convertFieldsForPenguinoContext,
  dehumanizeExpression,
  humanizeExpression,
} from '@/explore/utils/penguino';

import { penguino } from './penguino';
import { penguinoStateProvider, updatePenguinoState } from './penguino/state';

import styles from './penguino-input.module.scss';

export const PenguinoVersion = '1';

interface PenguinoInputProps {
  value: string;
  placeholder?: string;
  fields: (Field | FieldGroup)[];
  variables: Variables;
  model?: Model;
  requiredType?: string;
  onChange: (value: string) => void;
  autocompleteSort?: (a: Completion, b: Completion) => number;
}

export const PenguinoInput = (props: PenguinoInputProps) => {
  const { variables, requiredType, autocompleteSort } = props;

  const codeEditorRef = useRef<CodeMirrorRef>(null);

  // Add default fields to context in a keyless group so both `prop(myField)` and `prop(myTable.myField)`
  // become `prop("My Field (on My Table)")`
  const defaultFieldGroup = props.fields
    .filter(isFieldGroup)
    .find((group) => group.key === props.model?.modelId);

  const fields = useMemo(
    () =>
      convertFieldsForPenguinoContext([
        ...props.fields.filter(isFieldGroup),
        { fields: props.fields.filter(isField) },
        ...(defaultFieldGroup !== undefined ? [{ ...defaultFieldGroup, key: undefined }] : []),
      ]),
    [props.fields, defaultFieldGroup],
  );

  const namespacedFields = useMemo(
    () =>
      convertFieldsForPenguinoContext([
        ...props.fields.filter(isFieldGroup),
        { fields: props.fields.filter(isField) },
      ]),
    [props.fields],
  );

  const humanizedValue = useMemo(
    () => humanizeExpression(props.value, fields),
    [props.value, fields],
  );

  const autocompleteOptions =
    autocompleteSort !== undefined ? { compareCompletions: autocompleteSort } : undefined;

  const handleHintClick = () => {
    const view = codeEditorRef.current?.view;
    if (view !== undefined) {
      view.focus();
      startCompletion(view);
    }
  };

  useEffect(() => {
    const view = codeEditorRef.current?.view;
    if (view === undefined) {
      return;
    }

    updatePenguinoState(view, { fields: namespacedFields, variables, requiredType });
  }, [fields, namespacedFields, variables, requiredType]);

  return (
    <>
      <CodeMirror
        ref={codeEditorRef}
        value={humanizedValue}
        placeholder={props.placeholder}
        onChange={(value) => props.onChange(dehumanizeExpression(value, fields))}
        className={styles.penguinoInput}
        autoFocus
        extensions={[penguinoStateProvider(), penguino(autocompleteOptions)]}
        onInitialize={(view) =>
          updatePenguinoState(view, { fields: namespacedFields, variables, requiredType })
        }
      />
      <div
        className={styles.hint}
        onMouseDown={(e) => e.preventDefault()}
        onClick={handleHintClick}>
        Formula reference (<b>Ctrl + Space</b>)
      </div>
    </>
  );
};
