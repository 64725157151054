import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useTitle } from '@/lib/hooks/use-title';
import { useGAPageview } from '@/lib/ga/use-ga';
import { showChatWidget } from '@/lib/utils/support';

import { getNextUrl } from '../utils/url';
import { Button } from '../../components/button';
import { useToastContext } from '../../components/toast';
import { useAuth } from '../../auth/auth-context';

import styles from './verify-email.module.scss';

export const VerifyEmailPage = () => {
  useTitle('Verify Email');
  useGAPageview();

  const { user, sendEmailVerification } = useAuth();
  const [isSending, setIsSending] = useState(false);
  const addToast = useToastContext();
  const location = useLocation();

  if (user === null || user.emailVerified) {
    return <Navigate to={getNextUrl(location)} replace />;
  }

  const handleResend = async () => {
    try {
      setIsSending(true);

      await sendEmailVerification();
      addToast({
        title: `Verification email resent`,
        content: () => `Please check your inbox and spam folder.`,
        kind: 'success',
      });
    } catch (error) {
      addToast({
        title: 'Error',
        content: () => `Failed to resend verification email.`,
        kind: 'error',
      });
      throw error;
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <h1>
        A verification email has been sent to
        <br />
        <b>{user?.email}</b>
      </h1>
      <p>
        Please check your inbox and click the link in the email to verify your account. If you
        don&apos;t see the email, please check your spam folder.
      </p>
      <p>
        <Button onClick={handleResend} loading={isSending}>
          Resend verification email
        </Button>
      </p>
      <p>
        Need help?{' '}
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            showChatWidget();
          }}>
          Contact us
        </a>
      </p>
    </div>
  );
};
