import { useState } from 'react';

import { Icon } from '@/components/icon';

import { ChatUserMessage } from '@/explore/types';

import { UserMessageInput } from './user-message-input';

import commonStyles from './chat-cell.module.scss';

interface UserMessageProps {
  message: ChatUserMessage;
  onChange: (message: ChatUserMessage) => void;
}

export const UserMessage = (props: UserMessageProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = (value: string) => {
    props.onChange({ ...props.message, message: value });
    setIsEditing(false);
  };

  return (
    <div className={commonStyles.responseContainer}>
      <div className={commonStyles.avatar}>
        <Icon name="User" size={24} />
      </div>
      <div className={commonStyles.content}>
        <div className={commonStyles.clarificationHeader}>User</div>
        {isEditing ? (
          <UserMessageInput
            initialValue={props.message.message}
            placeholder="Edit answer"
            actions={(value) => [
              {
                label: 'Send',
                disabled: value === props.message.message,
                onClick: () => handleSubmit(value),
              },
              {
                label: 'Cancel (Esc)',
                onClick: () => setIsEditing(false),
                variant: 'secondary',
                type: 'cancel',
              },
            ]}
            autoFocus
          />
        ) : (
          <div className={commonStyles.response} onClick={() => setIsEditing(true)}>
            {props.message.message}
          </div>
        )}
      </div>
    </div>
  );
};
