import { createBasePipeline, createPipelineWithParent } from '@/core/pipeline';
import { PipelineSearchModal } from '@/explore/exploration-search/pipeline-search';
import { Cell, Exploration, Model, Pipeline } from '@/explore/types';
import { AnalyticsContextProvider } from '@/lib/analytics';

type BasePipelineSelectorProps = {
  exploration: Exploration;
  ignoredPipelineIds?: string[];
  onSelect: (pipeline: Pipeline) => void;
  onClose: () => void;
};

export const BasePipelineSearchModal = (props: BasePipelineSelectorProps) => {
  const handleSelectModel = (model: Model) => {
    props.onSelect(createBasePipeline({ baseModelId: model.modelId, operations: [] }));
  };

  const handleSelectCell = (selectedCell: Cell) => {
    if (!('pipeline' in selectedCell) || selectedCell.pipeline.pipelineId === undefined) {
      throw new Error('Selected cell does not have a pipeline');
    }

    return props.onSelect(
      createPipelineWithParent({
        parentId: selectedCell.pipeline.pipelineId,
        operations: [],
      }),
    );
  };

  return (
    <AnalyticsContextProvider
      properties={{
        intent: 'replace base model',
        exploration: props.exploration,
      }}>
      <PipelineSearchModal
        explorations={[]}
        exploration={props.exploration}
        onSelectModel={handleSelectModel}
        onSelectCell={handleSelectCell}
        onClose={() => props.onClose()}
        title="Replace base model"
        ignoredPipelineIds={props.ignoredPipelineIds}
      />
    </AnalyticsContextProvider>
  );
};
