import { formatDistance } from 'date-fns';
import { Link } from 'react-router-dom';

import { AlertConfiguration } from '@/graphql';
import { useBuildAccountUrl } from '@/lib/accounts/context';
import { isNil } from '@/lib/utils';
import { formatLocalDateTime } from '@/lib/date';

import { IconButton, InlineButton } from '../../components/button';
import { List, ListItem } from '../common';
import { buildExplorationHashUrl, getModelExploration } from '../../explore/utils';

import styles from './list.module.scss';

const getCreatedAtLabel = (createdAt: string) => {
  return `Created at ${formatDistance(new Date(createdAt), new Date(), {
    addSuffix: true,
  })}`;
};

interface AlertConfigurationListProps {
  alertConfigurations: AlertConfiguration[];
  canEdit: boolean;
  onDelete: (id: string, name: string) => void;
  onEdit: (alertConfiguration: AlertConfiguration) => void;
}

export const AlertConfigurationList = (props: AlertConfigurationListProps) => {
  const { alertConfigurations, canEdit, onDelete, onEdit } = props;
  const buildAccountUrl = useBuildAccountUrl();

  return (
    <List
      numColumns={5}
      columnWidths={{ [-1]: 'max-content', [-2]: 'max-content', [-3]: 'max-content' }}>
      {alertConfigurations.map((alertConfiguration) => {
        const { alertConfigurationId, name, createdAt, pipeline, excludedFields } =
          alertConfiguration;
        const model = { modelId: pipeline.baseModelId, name, description: undefined, labels: {} };
        return (
          <ListItem key={alertConfigurationId}>
            <div className={styles.name}>{name}</div>
            <div
              className={styles.createdAt}
              title={isNil(createdAt) ? '' : formatLocalDateTime(createdAt)}>
              {getCreatedAtLabel(createdAt)}
            </div>
            <div className={styles.link}>
              <Link
                to={buildAccountUrl(
                  buildExplorationHashUrl(
                    getModelExploration(model, pipeline.operations, excludedFields ?? []),
                  ),
                )}>
                Exploration
              </Link>
            </div>
            <div className={styles.link}>
              {canEdit && (
                <InlineButton onClick={() => onEdit(alertConfiguration)}>Edit</InlineButton>
              )}
            </div>
            <div>
              {canEdit && (
                <IconButton
                  icon="Trash2"
                  title="Delete Alert"
                  iconSize="regular"
                  onClick={() => onDelete(alertConfigurationId, name)}
                />
              )}
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};
