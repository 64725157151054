import { Cell, ChatCell, ChatMessage } from '@/explore/types';
import { generateUUID } from '@/lib/utils';

import { generateCellId } from '.';

export const isChatCell = (cell: Cell): cell is ChatCell => cell.kind === 'chat';

export const buildChatCell = (searchQuery: string): ChatCell => {
  return {
    id: generateCellId(),
    kind: 'chat',
    conversationId: generateUUID(),
    messages: [
      {
        id: generateUUID(),
        role: 'user',
        type: 'initial_user_prompt',
        message: searchQuery,
      },
    ],
  };
};

export const buildEmptyChatCell = (conversationId: string): ChatCell => {
  return {
    id: generateCellId(),
    kind: 'chat',
    conversationId,
    messages: [],
  };
};

export const updateChatCellMessage = (cell: ChatCell, updatedMessage: ChatMessage): ChatCell => {
  return {
    ...cell,
    messages: cell.messages.map((message) =>
      message.id === updatedMessage.id ? updatedMessage : message,
    ),
  };
};
