import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { Button, CloseButton, IconButton } from '@/components/button';
import { Icon } from '@/components/icon';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { Exploration } from '@/explore/types';
import { generateVisualisation } from '@/explore/components/visualisation/utils';

import { isCellWithVisualisations, isRecordsCell, isRecordsLikeCell } from '@/core/cell';

import { SidebarSection } from '../../sidebar-section';
import { getCellTitle } from '../../exploration-outline';
import { EditCohort } from '../../../edit-cohort';
import { EditPipeline } from '../../../edit-pipeline';
import { useExplorationContext } from '../../exploration-context';
import { useMetadataContext } from '../../../metadata-context';
import { EditFunnel } from '../../../edit-funnel';
import {
  ensureValidPipeline,
  getUnparameterizedExplorations,
  restoreInvalidCell,
} from '../../../utils';
import { CombineEditor } from '../../combine';
import { EditVariableCell } from '../../../edit-variable';
import {
  TableModeKey,
  PipelinePreviewKey,
  useExplorationCellContext,
} from '../../exploration-cell-context';
import { EditVisualisation } from '../../../edit-visualisation';
import { dereferencePipeline } from '../../../pipeline/utils';
import { regenerateVisualisationIfNeeded } from './utils';

import styles from '../sidebar.module.scss'; // TODO: move to ./cell-sidebar.module.scss

export const CellSidebar = () => {
  const { models, metrics: metrics, explorations } = useMetadataContext();

  const { exploration, selectedCell, closeEditor, setExploration, getQueryMeta, getVariables } =
    useExplorationContext();

  const {
    cell,
    tableMode,
    addDefaultVisualization,
    removeVisualisation,
    setVisualisation,
    setCellViewOption,
    isPipelinePreviewVisible,
    isTableVisible,
    hideTable,
    showTable,
  } = useExplorationCellContext();

  const stateContext = {
    models,
    variables: getVariables(),
    metrics,
  };

  const [showCombine, setShowCombine] = useState(false);
  const [openLastVisualisation, setOpenLastVisualisation] = useState(false);

  // When selection changes, dismiss the add join panel
  useEffect(() => {
    setShowCombine(false);
  }, [selectedCell]);

  // Reset the state immediately after the section's state has been initialized
  useEffect(() => {
    if (openLastVisualisation) {
      setOpenLastVisualisation(false);
    }
  }, [openLastVisualisation]);

  const selectedQueryMeta = getQueryMeta(cell.id);
  const fields = selectedQueryMeta?.recordType ?? [];
  const grouping = selectedQueryMeta?.grouping?.map(({ key }) => key) ?? [];

  const showCombineEditor = () => {
    if (selectedCell === null) {
      return;
    }

    setShowCombine(true);
  };

  const handleAddVisualisation = () => {
    addDefaultVisualization();
    setOpenLastVisualisation(true);
  };

  /**
   * Update the exploration with the new pipeline and in certain scenarios regenerate the visualisation
   */
  const handleExplorationUpdate = (updatedExploration: Exploration) => {
    if (
      !isRecordsCell(cell) ||
      cell.pipeline.pipelineId === undefined ||
      cell.visualisations?.length === 0
    ) {
      return setExploration(updatedExploration);
    }

    const updatedCell = updatedExploration.view.cells.find(({ id }) => id === cell.id);
    if (updatedCell === undefined || !isRecordsCell(updatedCell)) {
      throw new Error('Could not find updated cell');
    }
    const savedExploration = exploration;
    if (savedExploration === undefined) {
      throw new Error('Could not find saved exploration');
    }
    const savedCell = savedExploration.view.cells.find(({ id }) => id === cell.id);
    if (savedCell === undefined || !isRecordsCell(savedCell)) {
      throw new Error('Could not find saved cell');
    }

    if (isEqual(updatedCell.pipeline, savedCell.pipeline)) {
      return setExploration(updatedExploration);
    }

    const defaultVisualisation = generateVisualisation(
      dereferencePipeline(
        ensureValidPipeline(savedCell.pipeline, {
          ...stateContext,
          exploration: savedExploration,
        }),
        savedExploration,
      ),
      stateContext,
    );

    setExploration({
      ...updatedExploration,
      view: {
        ...updatedExploration.view,
        cells: updatedExploration.view.cells.map((updatedCell) => {
          if (
            cell.id === updatedCell.id &&
            isRecordsCell(updatedCell) &&
            updatedCell.visualisations !== undefined
          ) {
            return {
              ...updatedCell,
              visualisations: updatedCell.visualisations.map((visualisation) =>
                regenerateVisualisationIfNeeded({
                  visualisation,
                  defaultVisualisation,
                  nextDefaultVisualisation: generateVisualisation(
                    dereferencePipeline(
                      ensureValidPipeline(updatedCell.pipeline, {
                        ...stateContext,
                        exploration: updatedExploration,
                      }),
                      updatedExploration,
                    ),
                    stateContext,
                  ),
                }),
              ),
            };
          }

          return updatedCell;
        }),
      },
    });
  };

  const visualisations = isCellWithVisualisations(cell) ? (cell.visualisations ?? []) : [];
  const unwrappedCell = restoreInvalidCell(cell);

  return (
    <>
      <div className={styles.sidebarHeader}>
        <h1>{getCellTitle(cell)}</h1>
        <CloseButton onClick={closeEditor} iconSize="regular" className={styles.closeButton} />
      </div>
      <div className={styles.scrollContainer} key={cell.id}>
        {showCombine && (
          <SidebarSection title="Pipeline">
            <CombineEditor
              setExploration={handleExplorationUpdate}
              onSubmit={() => setShowCombine(false)}
              onCancel={() => setShowCombine(false)}
            />
          </SidebarSection>
        )}

        {!showCombine && isRecordsLikeCell(cell) && (
          <>
            <SidebarSection title="Pipeline" collapsible={false}>
              <EditPipeline
                title={cell.title ?? exploration.name}
                pipeline={cell.pipeline}
                setExploration={handleExplorationUpdate}
                exploration={exploration}
                recordType={fields}
                grouping={grouping}
                onCombine={showCombineEditor}
              />
            </SidebarSection>
            <SidebarSection iconName="Table" title="Display" collapsible collapsed>
              <CheckboxWithLabel
                checked={isPipelinePreviewVisible}
                onChange={() =>
                  setCellViewOption(
                    PipelinePreviewKey,
                    isPipelinePreviewVisible ? 'hidden' : 'visible',
                  )
                }>
                Show steps
              </CheckboxWithLabel>
              <CheckboxWithLabel
                checked={isTableVisible}
                disabled={visualisations.length === 0}
                onChange={({ checked }) => (checked ? showTable() : hideTable())}>
                Show table
              </CheckboxWithLabel>
              {isTableVisible ? (
                <CheckboxWithLabel
                  checked={tableMode === 'cards'}
                  onChange={() =>
                    setCellViewOption(TableModeKey, tableMode === 'cards' ? 'table' : 'cards')
                  }>
                  Show rows as cards
                </CheckboxWithLabel>
              ) : null}
            </SidebarSection>
            {visualisations.map((visualisation: any, idx: any) => (
              <SidebarSection
                key={idx}
                iconName="Graphic"
                title={`Chart ${idx + 1}`}
                titleActions={
                  <IconButton
                    icon="Trash2"
                    title="Remove chart"
                    size="small"
                    onClick={() => removeVisualisation(idx)}
                  />
                }
                collapsible
                collapsed={!openLastVisualisation || idx !== visualisations.length - 1}>
                <EditVisualisation
                  visualisation={visualisation}
                  pipeline={dereferencePipeline(cell.pipeline, exploration)}
                  models={models}
                  metrics={metrics}
                  variables={getVariables()}
                  onChange={(updatedVisualisation) => setVisualisation(idx, updatedVisualisation)}
                />
              </SidebarSection>
            ))}
            <SidebarSection>
              <Button
                icon={<Icon name="Graphic" />}
                size="compact"
                variant="gray"
                onClick={handleAddVisualisation}>
                Add chart
              </Button>
            </SidebarSection>
          </>
        )}
        {unwrappedCell.kind === 'funnel' && (
          <EditFunnel
            title={exploration.name}
            explorations={getUnparameterizedExplorations(explorations)}
            pipeline={unwrappedCell.pipeline}
            setExploration={setExploration}
            exploration={exploration}
          />
        )}
        {unwrappedCell.kind === 'cohort' && (
          <EditCohort
            explorations={getUnparameterizedExplorations(explorations)}
            setExploration={setExploration}
            pipeline={unwrappedCell.pipeline}
          />
        )}
        {cell.kind === 'variable' && (
          <EditVariableCell cell={cell} setExploration={setExploration} />
        )}
      </div>
    </>
  );
};
