import { uniq } from 'lodash';

import { Cell, Exploration } from '@/explore/types';
import { notNil } from '@/lib/utils';
// TODO: Move getExplorationType to core
import { getExplorationType } from '@/explore/utils';

import { insertCellsAfter, insertCellsAtIdx, isConversationCell } from '../cell';
export * from './conversation';

export const isModelExploration = (exploration: Exploration) =>
  getModelIdFromModelExploration(exploration) !== undefined ||
  getExplorationType(exploration) === 'model';

const getModelIdFromModelExploration = (exploration: Exploration) =>
  exploration.options?.explorationForModelId;

const getExplorationSection = (exploration: Exploration) => exploration.labels.section;

export const hasExplorationSection = (exploration: Exploration) =>
  notNil(getExplorationSection(exploration));

export const getExplorationSections = (explorations: Exploration[]) =>
  uniq(explorations.map(getExplorationSection).filter(notNil)).sort();

export const addCells = (exploration: Exploration, cells: Cell[], afterCellId: string) =>
  replaceCells(exploration, insertCellsAfter(exploration.view.cells, cells, afterCellId));

export const addCellsAt = (exploration: Exploration, cells: Cell[], index = 0) =>
  replaceCells(exploration, insertCellsAtIdx(exploration.view.cells, cells, index));

export const replaceCells = (exploration: Exploration, cells: Cell[]) => ({
  ...exploration,
  view: {
    ...exploration.view,
    cells,
  },
});

export const isEmptyExploration = (exploration: Exploration) =>
  exploration === undefined || exploration.view.cells.length === 0;

export const removeExplorationConversation = (
  exploration: Exploration,
  conversationId: string,
) => ({
  ...exploration,
  view: {
    ...exploration.view,
    cells: exploration.view.cells.filter(
      (c) => !isConversationCell(c) || c.conversationId !== conversationId,
    ),
  },
});
