import { isFunction } from 'lodash';
import { useMemo, useState } from 'react';

import classNames from 'classnames';

import { AutoResizeTextArea } from '@/components/form/text-area/auto-resize-text-area';
import { Button } from '@/components/button';

import styles from './chat-cell.module.scss';

type Hint = { label: string; hidden?: boolean };
type Action = {
  label: string;
  onClick(): void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
  type?: 'submit' | 'cancel';
};

interface UserMessageInputProps {
  initialValue?: string;
  placeholder?: string;
  autoFocus?: boolean;
  actions?: Action[] | ((value: string) => Action[]); // First action will be used as submit action
  hints?: Hint[] | ((value: string) => Hint[]);
}

export const UserMessageInput = (props: UserMessageInputProps) => {
  const { initialValue = '', placeholder, autoFocus, actions = [], hints = [] } = props;

  const [value, setValue] = useState(initialValue);

  const handlers = useMemo(() => {
    const allActions = isFunction(actions) ? actions(value) : actions;
    const submitAction = allActions.find((action) => action.type !== 'submit') ?? allActions[0];
    const cancelAction = allActions.find((action) => action.type === 'cancel');
    return { submitAction, cancelAction };
  }, [actions, value]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handlers.submitAction?.onClick?.();
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      handlers.cancelAction?.onClick?.();
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handlers.submitAction?.onClick?.();
  };

  return (
    <form onSubmit={handleSubmit}>
      <AutoResizeTextArea
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        autoFocus={autoFocus}
        rows={1}
        onFocus={(e) => e.currentTarget.select()}
      />
      <div className={classNames([styles.inputActions])}>
        {(isFunction(actions) ? actions(value) : actions).map((action, idx) =>
          action.variant === 'secondary' ? (
            <button key={idx} type="button" className={styles.hint} onClick={action.onClick}>
              {action.label}
            </button>
          ) : (
            <Button
              key={idx}
              type="submit"
              variant="primary"
              size="compact"
              disabled={action.disabled}>
              {action.label}
            </Button>
          ),
        )}
        {(isFunction(hints) ? hints(value) : hints).map((hint, idx) => (
          <span key={idx} className={classNames(styles.hint, { [styles.hide]: hint.hidden })}>
            {hint.label}
          </span>
        ))}
      </div>
    </form>
  );
};
