export const CodeInline = ({
  size = 12,
  color = 'currentColor',
  className,
}: {
  size?: number;
  color?: string;
  className?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    className={className}>
    <path
      stroke={color}
      strokeLinecap="round"
      d="M2 13h12M11 8h3M2 3h12M4.428 10.944l1.578-5.909"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M9.664 7.645a.5.5 0 0 1 0 .707l-2 1.997a.5.5 0 1 1-.706-.708l1.645-1.643-1.646-1.646a.5.5 0 1 1 .707-.707zM.836 8.309a.5.5 0 0 1 0-.708l2-1.996a.5.5 0 1 1 .706.708L1.897 7.956l1.646 1.646a.5.5 0 0 1-.707.707z"
      clipRule="evenodd"
    />
  </svg>
);
